import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import agent from '~/api/agent';
import { useApi, useSession, useWorkspace } from '~/contexts';

function useAuthCheck() {
  const api = useApi();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { isLoggedIn, setSession } = useSession();
  const { clearWorkspace } = useWorkspace();
  const checkAuthRef = useRef(false);

  const location = `${pathname}${search}`;

  const logout = useCallback(() => {
    history.push(`/login?redirect=${encodeURIComponent(location)}`);
    setSession(null);
    clearWorkspace();
  }, [clearWorkspace, history, location, setSession]);

  const checkAuth = useCallback(async () => {
    if (checkAuthRef.current) {
      return;
    }
    checkAuthRef.current = true;

    try {
      const { data } = await api.www.session();
      if (!data || !data.session) {
        logout();
      } else {
        console.warn('WARNING: Unauthorized API error, but session is still alive.');
      }
    } catch (error) {
      if (error.status === 401) {
        logout();
      } else {
        throw error;
      }
    } finally {
      checkAuthRef.current = false;
    }
  }, [api, logout]);

  useEffect(() => {
    const interceptor = agent.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401 && isLoggedIn) {
          checkAuth();
        }
        return Promise.reject(error);
      },
    );

    return () => {
      agent.interceptors.response.eject(interceptor);
    };
  }, [checkAuth, isLoggedIn]);
}

export default useAuthCheck;
