import { Formik } from 'formik';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, Icon, ModalCard, Radio, Tooltip } from '~/components';
import { colors } from '~/styles';
import { mergeValues } from '~/utils';
import ProjectTagSelect from './ProjectTagSelect';

const Description = styled.p`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const HelpBlock = styled(Description)`
  display: flex;
  padding: 1rem;
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;
`;

const HelpIcon = styled(Icon)`
  color: ${colors.grey25};
  font-size: 1.5rem;
`;

const HelpText = styled.span`
  margin-left: 1rem;
`;

const InputType = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
`;

const ExpensifyTooltip = styled(Tooltip)`
  margin-right: 0.5rem;
  color: ${colors.warning};
  font-size: 1.25rem;
`;

const TagList = styled(Form.Control)`
  flex-direction: column;

  > * {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Tag = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 0.75rem;
  }

  > :first-child {
    flex: 1;
  }
`;

const TagTooltip = styled(Tooltip)`
  margin-left: 0.5rem;
  color: ${colors.warning};
  font-size: 1.25rem;
`;

// Normally the RegExp /(?<!\\):/ would work fine to split on `:`, but not `\:`,
// but negative look behinds do not work in Safari.
function splitTags(value) {
  const results = [];
  let startIndex = 0;
  let subIndex = 0;
  let index = value.indexOf(':');
  while (index > -1) {
    const skipSelector = index > 0 && value[index - 1] === '\\';
    if (!skipSelector) {
      results.push(value.substring(subIndex, index));
    }
    startIndex = index + 1;
    if (!skipSelector) {
      subIndex = startIndex;
    }
    index = value.indexOf(':', startIndex);
  }
  if (subIndex < value.length) {
    results.push(value.substring(subIndex));
  } else if (subIndex === value.length) {
    results.push('');
  }
  return results;
}

export default function ProjectMappingModal({ mapping, expensifyTags, onClose, onSave }) {
  const [errorMessage, setErrorMessage] = useState();

  const expensifyTagCount = useMemo(() => expensifyTags?.length ?? 0, [expensifyTags]);
  const hasExpensifyTags = useMemo(() => expensifyTagCount > 0, [expensifyTagCount]);

  const initialValues = mergeValues(
    {
      project: null,
      rawInput: false,
      tagInput: mapping ? mapping.tags.map((tag) => tag.replace(':', '\\:')).join(':') : '',
      ...(mapping ? _.reduce(mapping.tags, (acc, tag, index) => ({ ...acc, [`tag_${index}`]: tag }), {}) : {}),
    },
    mapping,
  );

  if (!hasExpensifyTags && initialValues.rawInput === false) {
    initialValues.rawInput = true;
  }

  function getTags(values) {
    let tagValues;
    if (values.rawInput) {
      const tagInputs = splitTags(values.tagInput);
      tagValues = _.times(Math.max(expensifyTagCount, tagInputs.length), (index) => {
        const value = index < tagInputs.length ? tagInputs[index] : '';
        return value ? value.replace('\\:', ':').trim() : '';
      });
    } else {
      tagValues = _.times(expensifyTagCount, (index) => {
        const value = values[`tag_${index}`];
        return value ? value.trim() : '';
      });
    }

    const lastValueIndex = _.findLastIndex(tagValues, (tag) => !!tag);
    return lastValueIndex >= 0 ? tagValues.slice(0, lastValueIndex + 1) : [];
  }

  async function handleSubmit(values) {
    setErrorMessage();

    const tags = getTags(values);
    if (tags.length === 0) {
      setErrorMessage('No tag(s) identified, please select or type in at least one tag.');
      return;
    }

    const updates = _.pick(values, ['project', 'rawInput']);
    updates.projectId = updates.project.id;
    updates.tags = tags;

    const updatedMapping = _.assign({}, mapping, updates);
    if (!updatedMapping.id) {
      updatedMapping.id = _.uniqueId('temp_');
    }

    if (_.isFunction(onSave)) {
      onSave(updatedMapping);
    }
  }

  return (
    <ModalCard title="Expensify Project Mapping" onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          project: Yup.object().label('Ruddr Project').nullable().required(),
          rawInput: Yup.bool().label('Tag Matching Type').required(),
          tagInput: Yup.string()
            .label('Expensify Tag')
            .when('rawInput', (rawInput, schema) => (rawInput ? schema.required() : schema)),
        })}>
        {(formik) => {
          const handleInputTypeChange = (event) => {
            const { name, value } = event.target;
            const boolValue = value === 'true';
            formik.setFieldValue(name, boolValue);

            // Convert values.tag_# to tagInput
            if (boolValue) {
              const selectValues = _.times(expensifyTagCount, (index) => {
                const value = formik.values[`tag_${index}`];
                return value ? value.replace(':', '\\:').trim() : '';
              });
              const hasValue = selectValues.length > 0 && _.some(selectValues);
              const tagInput = hasValue ? selectValues.join(':') : '';

              formik.setFieldValue('tagInput', tagInput);
            }
            // Convert tagInput to values.tag_#
            else {
              const tagInputs = splitTags(formik.values.tagInput);
              _.times(expensifyTagCount, (index) => {
                const value = index < tagInputs.length ? tagInputs[index] : '';
                formik.setFieldValue(`tag_${index}`, value ? value.replace('\\:', ':').trim() : '');
              });
            }
          };

          return (
            <Form>
              <ModalCard.Body>
                {errorMessage && <FormMessage.Error>{errorMessage}</FormMessage.Error>}
                <Description>Choose a project and select the desired matching tag(s).</Description>
                <Form.Control>
                  <Field.ClientProjectSelect name="project" placeholder="Ruddr Project" clearable={false} />
                </Form.Control>
                <InputType>
                  {!hasExpensifyTags && (
                    <ExpensifyTooltip message="No tags available from Expensify.">
                      <Icon icon="exclamation-triangle" />
                    </ExpensifyTooltip>
                  )}
                  <Field.RadioGroup name="rawInput" onChange={handleInputTypeChange}>
                    <Radio value={false} label="Select Expensify Tag(s)" disabled={!hasExpensifyTags} />
                    <Radio value={true} label="Text Matching" />
                  </Field.RadioGroup>
                </InputType>
                {formik.values.rawInput === false && hasExpensifyTags && (
                  <TagList>
                    {expensifyTags.map((group, groupIndex) => (
                      <Tag key={`${groupIndex}_${group.name}`}>
                        <ProjectTagSelect group={group} groupIndex={groupIndex} />
                        {!!formik.values[`tag_${groupIndex}`] &&
                          !_.find(group.tags, (tag) => tag.name.trim() === formik.values[`tag_${groupIndex}`]) && (
                            <TagTooltip message="Selected tag does not exist in Expensify.">
                              <Icon icon="exclamation-triangle" />
                            </TagTooltip>
                          )}
                      </Tag>
                    ))}
                  </TagList>
                )}
                {formik.values.rawInput === true && (
                  <>
                    <Form.Control>
                      <Field.Text name="tagInput" placeholder="Expensify Tag" />
                    </Form.Control>
                    <HelpBlock>
                      <HelpIcon type="far" icon="question-circle" />
                      <HelpText>
                        Multiple tags can be separated using the <code>:</code> character. Only specify values used for
                        matching. For example, if your policy uses 3 tags, but only want to match on project, which is
                        the last one, it would look like: <code>::My Project</code>. If your tag has a <code>:</code>{' '}
                        character in it, escape it with a backslash, like <code>\:</code>.
                      </HelpText>
                    </HelpBlock>
                  </>
                )}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                    Close
                  </CancelButton>
                  <Button type="submit">Save Mapping</Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
