import { Button, Field, Form, FormMessage } from '~/components';
import { useApi, useSession, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useForm } from '~/hooks';
import _ from 'lodash';
import locales from '~/lookups/locales';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

const Title = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
  margin-bottom: 2rem;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto;
  }
`;

export default function PasswordForm({ member }) {
  const api = useApi();
  const { identify } = useSession();
  const { selectWorkspace } = useWorkspace();
  const [{ message, isSubmitting }, form] = useForm();
  const toast = useToast();
  const { token } = useParams();

  const initialValues = mergeValues(
    {
      email: member.email,
      locale: locales.find((l) => l === navigator.language) ?? member.workspace.locale ?? 'en-US',
      name: member.name,
      password: '',
      passwordVerification: '',
      termsAgree: false,
    },
    member,
  );

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({ ..._.omit(values, ['email', 'name', 'termsAgree']), token });
      const { data } = await api.www.joinWorkspace(body);

      await identify();

      const workspace = await selectWorkspace(data.workspaceId, true);

      toast.success(`You have joined ${workspace.name}.`);
    } catch ({ message }) {
      form.error({ message });
    }
  }

  return (
    <>
      <Title>
        Join <strong>{member.workspace.name}</strong>
      </Title>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .label('Password')
            .min(8, 'Password must be at least 8 characters long')
            .max(255, 'Password must be at most 255 characters long')
            .required(),
          passwordVerification: Yup.string()
            .label('Confirm Password')
            .required()
            .oneOf([Yup.ref('password')], 'Passwords do not match'),
          termsAgree: Yup.bool().oneOf([true], "You must agree to Ruddr's terms"),
        })}>
        {() => (
          <Form autoComplete="off">
            <Form.Control>
              <Field.Text autoFocus name="name" placeholder="Name" autoComplete="username" maxLength={255} disabled />
            </Form.Control>
            <Form.Control>
              <Field.Text name="email" placeholder="Email" disabled />
            </Form.Control>
            <Form.Control>
              <Field.Text
                name="password"
                placeholder="Password"
                type="password"
                autoComplete="new-password"
                maxLength={255}
              />
            </Form.Control>
            <Form.Control>
              <Field.Text
                name="passwordVerification"
                placeholder="Confirm Password"
                type="password"
                autoComplete="new-password"
                maxLength={255}
              />
            </Form.Control>
            <Form.Control>
              <Field.RegionalFormatSelect name="locale" clearable={false} />
            </Form.Control>
            <Form.Control>
              <Field.Checkbox
                name="termsAgree"
                label={
                  <>
                    I agree to Ruddr's{' '}
                    <a href="/master-subscription-agreement" target="_blank">
                      terms
                    </a>
                  </>
                }
              />
            </Form.Control>

            {message && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}

            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Join Workspace
              </Button>
            </FormAction>
          </Form>
        )}
      </Formik>
    </>
  );
}
