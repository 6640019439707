import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useApi, useSession } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors, weights } from '~/styles';
import { Footer, Header, ResponsiveContainer } from '../components';
import { PageLoader } from '../pages';

const Container = styled(ResponsiveContainer)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
`;

const Heading = styled.div`
  text-align: center;
  max-width: 39rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const ErrorMessage = styled.div`
  max-width: 49.5rem;
  margin-top: 2rem;
  padding: 2rem;
  color: ${colors.white};
  background-color: ${colors.danger};
  border-radius: 0.625rem;

  a {
    color: ${colors.white};
    text-decoration: underline;

    &:hover {
      color: ${colors.grey10};
    }
  }
`;

export default function VerifyEmail() {
  useDocumentTitle('Verify Email');

  const api = useApi();
  const [error, setError] = useState(false);
  const { isReady, identify } = useSession();
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!isReady) {
      return;
    }
    (async () => {
      try {
        await api.www.verifyEmail({ token });
        await identify();
        history.replace('/email-verified');
      } catch (error) {
        const errorCode = error?.response?.data?.code;
        if (errorCode === 'token_used') {
          history.replace('/email-verified');
        } else {
          setError(errorCode);
        }
      }
    })();
  }, [token, isReady, identify, api, history]);

  return (
    <>
      <Header />
      <Container>
        {!error ? (
          <PageLoader />
        ) : (
          <>
            <Heading>
              <Title>Email Verification</Title>
              <Byline>Your Ruddr account may not be ready yet.</Byline>
            </Heading>
            <ErrorMessage>
              {error === 'token_expired' ? (
                <>
                  The email verification link has expired. Links are only available for 72 hours. Try{' '}
                  <Link to="/login">logging in</Link> to see if your account has already been activated. If not, you
                  will be presented with the option to send a new verification email.
                </>
              ) : error === 'invalid_token' ? (
                <>
                  The email verification link is invalid. Try <Link to="/login">logging in</Link> to see if your account
                  has already been activated.
                </>
              ) : (
                <>
                  There was a problem with your email verification link. Try <Link to="/login">logging in</Link> to see
                  if your account has already been activated.
                </>
              )}{' '}
              Send an email to <a href="mailto:help@ruddr.io">help@ruddr.io</a> if you need further assistance.
            </ErrorMessage>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
}
