import {
  Avatar,
  BackLink,
  ClientLink,
  Icon,
  InternalClientTooltip,
  Level,
  MemberContactPopover,
  ProjectLink,
  RouteLink,
  Stack,
  Table,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import GroupRow from '../components/GroupRow';
import Header from '../components/Header';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useClientFilters from '~/hooks/useClientFilters';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import useMemberFilters from '~/hooks/useMemberFilters';
import MemberTagsGroup from '../components/MemberTagsGroup';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import useProjectFilters from '~/hooks/useProjectFilters';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import ExportDialogAsync from '../components/ExportDialogAsync';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Member = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

const FirstRole = ({ member }) => {
  const role = member.roles[0];
  if (!role) return null;

  return role.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Roles = ({ member }) => {
  let rolesCount = member.roles.length - 1;
  if (rolesCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Roles</Title>

          {member.roles.map((role) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={role.id}>
              <small>{role.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{rolesCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default function ProjectMembers() {
  useDocumentTitle('Project Team Members');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    sort: new QuerySort('project.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().projectMembers(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .projectMembers(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/workspace`} />

        <Stack>
          <h1>Project Team Members</h1>

          <Tags>
            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const auth = useAuth();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const report = query.data;

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <TotalResults>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Project" value={report.records.length} />
        {report.partialResults && <PartialResultsTooltip />}
      </TotalResults>

      <Table>
        <Table.Header sticky>
          <Table.Column name="project.name" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="6rem">Status</Table.Column>
          <Table.Column width="7rem" isVisible={auth.workspace.manage}>
            Type
          </Table.Column>
          <Table.Column>Project Role(s)</Table.Column>
          <Table.Column isVisible={features.practices}>Practice</Table.Column>
          <Table.Column isVisible={features.disciplines}>Discipline</Table.Column>
        </Table.Header>

        <TableBody fade={query.status === 'sorting'}>
          {report.records.map((project) => {
            return (
              <React.Fragment key={project.id}>
                <GroupRow top="2.95rem">
                  <Table.Cell>
                    <ProjectInfo>
                      <p>
                        <ProjectLink project={project} />
                      </p>
                      <small>
                        <ClientLink client={project.client} />
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>
                </GroupRow>

                {project.members.map((pm) => {
                  return (
                    <Table.Row key={pm.id}>
                      <Table.Cell>
                        <Member style={{ marginLeft: '1rem' }}>
                          <MemberContactPopover member={pm.member} placement="right">
                            <div style={{ position: 'relative' }}>
                              <Avatar value={pm.member} isCircle hasBackground initialsFontSize=".9rem" />
                            </div>
                          </MemberContactPopover>
                          <MemberInfo>
                            {auth.workspace.manage ? (
                              <RouteLink to={`/app/${workspace.key}/settings/members/details/${pm.member.id}`}>
                                {pm.member.name}
                              </RouteLink>
                            ) : (
                              pm.member.name
                            )}
                          </MemberInfo>

                          {pm.typeId === 'administrator' && (
                            <Tooltip placement="right" message="Project Admin">
                              <Icon icon="user-gear" spaceLeft color={colors.primary} />
                            </Tooltip>
                          )}
                        </Member>
                      </Table.Cell>
                      <Table.Cell>{pm.member.isActive && pm.isActive ? 'Active' : 'Inactive'}</Table.Cell>
                      <Table.Cell>{pm.member.employmentType?.name}</Table.Cell>
                      <Table.Cell>
                        <FirstRole member={pm} />
                        <Roles member={pm} />
                      </Table.Cell>
                      <Table.Cell>{pm.member.practice?.name}</Table.Cell>
                      <Table.Cell>{pm.member.discipline?.name}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}
