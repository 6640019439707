import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, Checkbox, Icon, ModalCard, Table, Tooltip } from '~/components';
import { useToast } from '~/contexts';
import { colors } from '~/styles';

const CopyIcon = styled(Icon)`
  color: ${colors.grey25};
  font-size: 1.15rem;
  cursor: pointer;

  &:hover {
    color: ${colors.grey40};
  }
`;

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const invoiceVariablesArray = [
  { name: 'Client Name', variable: '{{invoice_client_name}}' },
  { name: 'Due Date', variable: '{{invoice_due_date}}' },
  { name: 'Invoice Number', variable: '{{invoice_number}}' },
  { name: 'Invoice Total', variable: '{{invoice_total}}' },
  { name: 'Issue Date', variable: '{{invoice_issue_date}}' },
  { name: 'Issue Date Month', variable: '{{invoice_issue_date_month}}' },
  { name: 'Issue Date Year', variable: '{{invoice_issue_date_year}}' },
  { name: 'Projects', variable: '{{invoice_projects}}' },
  { name: 'Workspace Company Name', variable: '{{invoice_company_name}}' },
];

export default function InvoiceVariablesDialog({ onClose, onSaved }) {
  const [variables, setVariables] = useState([]);
  const toast = useToast();

  const handleCopyVariable = (variable, name) => {
    navigator.clipboard.writeText(variable);
    toast.success(`${name} variable copied to clipboard.`);
  };

  const handleSave = () => {
    onSaved(variables);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalCard title="Insert Invoice Variables" onClose={onClose}>
      <ModalCard.Body>
        <Table>
          <Table.BoxHeader>
            <Table.Column align="center" width="3rem" />
            <Table.Column width="14rem">Name</Table.Column>
            <Table.Column>Variable</Table.Column>
            <Table.Column width="5rem" align="right" />
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {invoiceVariablesArray.map(({ name, variable }) => {
              const checked = variables.includes(variable);

              const handleSelectionChange = () => {
                setVariables(checked ? variables.filter((v) => v !== variable) : [...variables, variable]);
              };

              return (
                <Table.Row key={variable}>
                  <Table.Cell>
                    <StyledCheckbox>
                      <Checkbox checked={checked} onChange={handleSelectionChange} />
                    </StyledCheckbox>
                  </Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{variable}</Table.Cell>
                  <Table.Cell>
                    <Tooltip message="Copy variable to clipboard.">
                      <CopyIcon icon="clipboard" onClick={() => handleCopyVariable(variable, name)} />
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={handleClose}>Close</CancelButton>
          <Button onClick={handleSave}>Insert</Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
