import Big from 'big.js';
import {
  ActionButton,
  BillableIcon,
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  CurrencyInput,
  DeleteConfirmation,
  Dropdown,
  Field,
  Form,
  HelpTooltip,
  Icon,
  Level,
  ModalCard,
  SingleSelect,
  SplitButton,
  Table,
  Tooltip,
} from '~/components';
import { Menu } from '~/components/Dropdown';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useAuth } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import WorkspaceMemberSelect from './WorkspaceMemberSelect';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const AddMemberDropdown = styled(Dropdown)`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  > ${Dropdown.Trigger} {
    display: flex;
    align-items: center;
  }
`;

const TableBoxRowActionsDropdown = styled(TableBoxRowActions.Dropdown)`
  && > ${Menu} {
    min-width: 13rem;
  }
`;

const AddMemberButton = styled(ActionButton)`
  margin-left: 0.5rem;
  border-radius: 999rem 0rem 0rem 999rem;
`;

const initialState = {
  showQuickAdd: false,
  isSubmitting: false,
  saved: false,
  query: { isActive: 'true' },
  hasRemainingMembers: null,
  hasRemainingActiveMembers: null,
};
const handlers = {
  filter: (query) => ({ query }),
  submit: () => ({ isSubmitting: true }),
  save: (value, state, actions) => {
    setTimeout(() => {
      actions.done();
    }, 1000);
    return { isSubmitting: false, saved: true };
  },
  done: () => ({ isSubmitting: false, saved: false }),
  setHasRemainingMembers: (hasRemainingMembers) => ({ hasRemainingMembers }),
  setHasRemainingActiveMembers: (hasRemainingActiveMembers) => ({ hasRemainingActiveMembers }),
};

function TeamTab({ project, projectModel, onChange }) {
  const [formEntry, setEditForm] = useState(null);
  const handleEdit = (item) => setEditForm(item);
  const handleCancel = () => setEditForm(null);

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, query, hasRemainingMembers, hasRemainingActiveMembers, saved }, actions] = useActions(
    handlers,
    initialState,
  );
  const confirmation = useConfirmation();

  let memberIds = useMemo(() => projectModel.members.map((pm) => pm.memberId), [projectModel.members]);
  const memberIdsRef = useRef(memberIds);
  if (JSON.stringify(memberIds) === JSON.stringify(memberIdsRef.current)) {
    memberIds = memberIdsRef.current;
  } else {
    memberIdsRef.current = memberIds;
  }

  useEffect(() => {
    (async () => {
      let { data } = await api.www.workspaces(workspace.id).projects(project.id).workspaceMembers({
        size: 1000,
      });

      _.remove(data, (member) => memberIds.includes(member.id));

      actions.setHasRemainingMembers(data.length > 0);

      _.remove(data, (member) => !member.isActive);

      actions.setHasRemainingActiveMembers(data.length > 0);
    })();
  }, [workspace.id, api, actions, project.id, memberIds]);

  const handleActiveStatusChange = (item, flag) => {
    onChange(
      projectModel.members.map((pm) =>
        pm.id === item.id ? { ...pm, isActive: flag, isActuallyActive: pm.member.isActive && flag } : pm,
      ),
    );
  };

  const handleSetProjectAdmin = (item) => {
    onChange(
      projectModel.members.map((pm) =>
        pm.id === item.id ? { ...pm, typeId: 'administrator' } : { ...pm, typeId: 'collaborator' },
      ),
    );
  };

  const handleRemove = async (projectMember) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation title="Remove Member" deleteLabel="Remove" resolve={resolve}>
        This will remove {projectMember.member.name} from this project. Are you sure?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    onChange(projectModel.members.filter((m) => m.id !== projectMember.id));
  };

  const handleAddMember = async (members) => {
    let currentNewIndex = _.reduce(
      projectModel.members,
      (index, pm) => {
        if (pm.newIndex >= 0 && pm.newIndex > index) {
          return pm.newIndex;
        }
        return index;
      },
      0,
    );

    let projectMembers = [...projectModel.members];

    for (const member of [members].flat()) {
      if (projectModel.members.some((pm) => pm.member.id === member.id)) continue;

      let projectMember = {
        id: _.uniqueId('pm_'),
        newIndex: ++currentNewIndex,
        memberId: member.id,
        member,
        isActive: true,
        isActuallyActive: member.isActive,
        roles: [],
        rate: projectModel.currency === member.defaultRateCurrency ? member.defaultRate : null,
        isBillable: projectModel.isBillable,
        typeId: 'collaborator',
        costCurrency: currency,
      };

      projectMembers = [projectMember, ...projectMembers];
    }

    onChange(projectMembers);
  };

  const handleAddAllMembers = async (type) => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .workspaceMembers({
        size: 1000,
        isActive: true,
        employmentTypeId: type ? type : undefined,
      });

    handleAddMember(data.reverse());
  };

  const handleFilter = ({ target: { name, value } }) => {
    actions.filter({ ...query, [name]: value });
  };

  const handleSubmit = (value) => {
    value = emptyStringToNull(value);

    const members = projectModel.members.map((pm) =>
      pm.id === value.id
        ? {
            ...pm,
            isBillable: value.isBillable,
            rate: value.rate,
            typeId: value.typeId,
            overrideCostRate: value.overrideCostRate,
            costCurrency: value.costCurrency,
            costPerHour: value.costPerHour,
            overheadCostPerHour: value.overheadCostPerHour,
          }
        : pm,
    );

    onChange(members);
    handleCancel();
  };

  const membersList = useMemo(() => {
    let result = projectModel.members;
    if (query.isActive) {
      result = result.filter((pm) => pm.isActuallyActive === (query.isActive === 'true'));
    }
    return _.orderBy(
      result,
      [(pm) => (pm.newIndex >= 0 ? pm.newIndex : -1), (pm) => pm.member.name.toLowerCase()],
      ['desc', 'asc'],
    );
  }, [projectModel.members, query.isActive]);

  const currency = projectModel.currency;

  return (
    <>
      <Section>
        <Level>
          <Level.Item width="20rem">
            <SingleSelect
              name="isActive"
              value={query.isActive}
              placeholder="All"
              materialPlaceholder="Status"
              materialAlwaysVisible
              onChange={handleFilter}>
              <option value=""></option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </SingleSelect>
          </Level.Item>

          <Level.Item>
            <AddMemberDropdown>
              {({ setIsOpen, isOpen }) => (
                <>
                  <Dropdown.Trigger>
                    <SplitButton style={{ alignItems: 'center' }}>
                      {hasRemainingMembers === false && (
                        <HelpTooltip message="Every workspace member has been assigned to this project." />
                      )}
                      <AddMemberButton
                        disabled={hasRemainingMembers === false}
                        isLoading={isSubmitting}
                        ok={saved}
                        onClick={() => setIsOpen(!isOpen)}>
                        Add Member
                      </AddMemberButton>

                      <SplitButton.Menu
                        disabled={hasRemainingActiveMembers === false}
                        data-testid="add_member_dropdown">
                        {({ setIsOpen }) => (
                          <>
                            <SplitButton.Item
                              onClick={() => setIsOpen(false) || handleAddAllMembers()}
                              data-testid="add_all_workspace_members_button">
                              Add all workspace members
                            </SplitButton.Item>
                            <SplitButton.Item onClick={() => setIsOpen(false) || handleAddAllMembers('employee')}>
                              Add all employee members
                            </SplitButton.Item>
                          </>
                        )}
                      </SplitButton.Menu>
                    </SplitButton>
                  </Dropdown.Trigger>
                  <Dropdown.Menu>
                    <Dropdown.Panel>
                      <WorkspaceMemberSelect
                        projectId={project.id}
                        filterMembers={memberIds}
                        onChange={async ({ target: { value } }) => {
                          handleAddMember(value);
                          setIsOpen(false);
                        }}
                      />
                    </Dropdown.Panel>
                  </Dropdown.Menu>
                </>
              )}
            </AddMemberDropdown>
          </Level.Item>
        </Level>
      </Section>
      <Section>
        <Table small>
          <Table.Total
            value={membersList.length > 0 ? membersList.length : 'No'}
            label={membersList.length !== 1 ? ' Members' : ' Member'}
          />
          <Table.BoxHeader>
            <Table.Column>Member</Table.Column>
            <Table.Column align="center" width="6rem"></Table.Column>
            <Table.Column align="center" width="6rem">
              Project Admin
            </Table.Column>
            <Table.Column isVisible={!projectModel.useRoles && projectModel.isBillable} align="center" width="6rem">
              Billable
            </Table.Column>
            <Table.Column isVisible={!projectModel.useRoles && projectModel.isBillable} align="right" width="8.5rem">
              Bill Rate
            </Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {membersList.map((item) => (
              <MemberRow
                key={item.id}
                project={project}
                projectModel={projectModel}
                projectMember={item}
                currency={currency}
                onEdit={() => handleEdit(item)}
                onActiveStatusChange={handleActiveStatusChange}
                onSetProjectAdmin={handleSetProjectAdmin}
                onRemove={handleRemove}
              />
            ))}
          </Table.Body>
        </Table>
      </Section>

      {formEntry && (
        <ProjectMemberForm
          projectMember={formEntry}
          project={project}
          projectModel={projectModel}
          currency={currency}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

const MemberInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

function MemberRow({
  currency,
  project,
  projectModel,
  projectMember,
  disableActions,
  onEdit,
  onActiveStatusChange,
  onSetProjectAdmin,
  onRemove,
}) {
  const { member, isBillable, rate, isActive, id, overrideCostRate } = projectMember;
  const isActuallyBillable = projectModel.isBillable && isBillable;
  const isActuallyActive = member.isActive && isActive;
  const actualRate = isActuallyBillable ? rate : null;
  const isAdmin = projectMember.typeId === 'administrator';

  const warnings = useMemo(() => {
    let warnings = [];

    if (projectModel.isBillable && !member.isBillable)
      warnings.push(`This is a non-billable workspace member that can't track time to billable projects.`);

    if (project.permissions.viewMargin && overrideCostRate)
      warnings.push('This member has an overridden cost rate on this project.');

    return warnings;
  }, [project.permissions.viewMargin, member.isBillable, projectModel.isBillable, overrideCostRate]);

  return (
    <Table.BoxRow data-testid="row" isDisabled={!isActuallyActive}>
      <Table.Cell>
        <MemberInfo>
          {member.name}
          {member.jobTitle && <small>{member.jobTitle?.name}</small>}
        </MemberInfo>
      </Table.Cell>
      <Table.Cell>
        {warnings.length > 0 && (
          <Tooltip
            style={{ display: 'inline', marginLeft: '0.5rem' }}
            maxWidth="22rem"
            placement="right"
            data-testid="non_billable_tooltip"
            message={
              <>
                <p>The following exceptions exist for this member:</p>
                <ul style={{ listStyle: 'disc inside', marginTop: '0.5rem' }}>
                  {warnings.map((warning) => (
                    <li key={warning}>{warning}</li>
                  ))}
                </ul>
              </>
            }>
            <Icon icon="exclamation-triangle" color={colors.warning} />
          </Tooltip>
        )}
      </Table.Cell>
      <Table.Cell>
        {isAdmin && (
          <Tooltip style={{ display: 'inline', marginLeft: '0.5rem' }} message="Project Admin">
            <Icon icon="user-gear" color={colors.primary} />
          </Tooltip>
        )}
      </Table.Cell>
      <Table.Cell>
        <BillableIcon value={isActuallyBillable} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={actualRate} maximumFractionDigits={7} currency={currency} />
      </Table.Cell>

      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />
        <hr />

        <TableBoxRowActionsDropdown disabled={disableActions}>
          {({ setIsOpen }) => (
            <>
              <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
              <Dropdown.Item
                disabled={!isActuallyActive || isAdmin}
                tooltip={
                  !isActuallyActive
                    ? 'An inactive project member cannot be set as a Project Admin.'
                    : isAdmin
                      ? 'This member is already a Project Admin.'
                      : undefined
                }
                onClick={() => {
                  onSetProjectAdmin(projectMember);
                  setIsOpen(false);
                }}>
                Set as Project Admin
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!member.isActive || isAdmin}
                tooltip={
                  !member.isActive
                    ? 'This workspace member is inactive.'
                    : isAdmin
                      ? 'A Project Admin cannot be deactivated.'
                      : undefined
                }
                onClick={() => {
                  onActiveStatusChange(projectMember, !isActive);
                  setIsOpen(false);
                }}>
                {isActive ? 'Deactivate' : 'Activate'}
              </Dropdown.Item>
              <Dropdown.DeleteItem
                tooltip={
                  isAdmin ? 'A Project Admin cannot be removed.' : 'This member cannot be removed from the project.'
                }
                disabled={isAdmin}
                onCheckDependencies={async (workspace) =>
                  // Disable if the member has role assignments or if it has dependencies in the database
                  isAdmin ||
                  projectModel.members.some((pm) => pm.roles.some((pmr) => pmr.projectMemberId === id)) ||
                  (!id.startsWith('pm_') &&
                    (await workspace.projects(projectModel.id).members(id).hasDependencies()).data)
                }
                onClick={() => {
                  onRemove(projectMember);
                  setIsOpen(false);
                }}>
                Remove
              </Dropdown.DeleteItem>
            </>
          )}
        </TableBoxRowActionsDropdown>
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function ProjectMemberForm({ currency, project, projectMember, projectModel, onSubmit, onCancel }) {
  const initialValues = mergeValues(
    {
      id: projectMember.id,
      isBillable: projectModel.isBillable,
      rate: '',
      typeId: 'collaborator',
      overrideCostRate: false,
      costCurrency: currency,
      costPerHour: '',
      overheadCostPerHour: '',
    },
    projectMember,
  );

  const isOnlyAdmin =
    projectMember.typeId === 'administrator' &&
    projectModel.members.filter((m) => m.typeId === 'administrator').length === 1;

  const isActuallyActive = projectMember.member.isActive && projectMember.isActive;

  const auth = useAuth();

  const permissions = {
    cost: {
      view: project.permissions.viewMargin,
      edit: auth.workspace.manage,
    },
  };

  return (
    <ModalCard title="Edit Project Member" onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          isBillable: Yup.bool(),
          rate: Yup.number().label('Bill Rate').min(0).max(99999999999).nullable(),
          overrideCostRate: Yup.bool(),
          costCurrency: Yup.string().label('Cost Currency').nullable(),
          costPerHour: Yup.number().label('Labor Cost Per Hour').min(0).max(99999999999).nullable(),
          overheadCostPerHour: Yup.number().label('Overhead Cost Per Hour').min(0).max(99999999999).nullable(),
        })}>
        {({ values, setFieldValue }) => {
          const isAdmin = values.typeId === 'administrator';
          const totalCostPerHour =
            _.isNumber(values.costPerHour) || _.isNumber(values.overheadCostPerHour)
              ? Big(values.costPerHour || 0)
                  .plus(values.overheadCostPerHour || 0)
                  .toNumber()
              : null;

          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <ReadTextbox label="Member" value={projectMember.member.name} />

                  {isOnlyAdmin ? (
                    <Tooltip message="Projects must have at least one Project Admin.">
                      <Checkbox label="Project Admin" checked={isAdmin} disabled />
                    </Tooltip>
                  ) : !isActuallyActive ? (
                    <Tooltip message="An inactive project member cannot be set as a Project Admin.">
                      <Checkbox label="Project Admin" checked={isAdmin} disabled />
                    </Tooltip>
                  ) : (
                    <Checkbox
                      label="Project Admin"
                      checked={isAdmin}
                      onChange={({ target: { checked } }) =>
                        setFieldValue('typeId', checked ? 'administrator' : 'collaborator')
                      }
                    />
                  )}
                </Form.Control>

                {projectModel.isBillable && !projectModel.useRoles && (
                  <>
                    <Form.Control>
                      <Field.Checkbox label="Billable" name="isBillable" />

                      {values.isBillable && (
                        <Field.Currency name="rate" placeholder="Bill Rate" precision={7} currency={currency} />
                      )}
                    </Form.Control>
                  </>
                )}

                {permissions.cost.view && (
                  <>
                    <Form.Control>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Field.Checkbox
                          label={
                            <div style={{ display: 'flex' }}>
                              Override Cost Rate
                              {projectMember.member.costMethodId === 'fixed' && (
                                <Tooltip message="This member has a fixed labor cost.">
                                  <Icon icon="exclamation-triangle" color={colors.warning} spaceLeft />
                                </Tooltip>
                              )}
                            </div>
                          }
                          name="overrideCostRate"
                          disabled={!permissions.cost.edit}
                        />
                      </div>

                      <div>
                        {values.overrideCostRate && (
                          <Field.WorkspaceCurrencySelect
                            name="costCurrency"
                            placeholder="Cost Currency"
                            clearable={false}
                            disabled={!permissions.cost.edit}
                          />
                        )}
                      </div>
                    </Form.Control>

                    {values.overrideCostRate && (
                      <Form.Control>
                        <Field.Currency
                          name="costPerHour"
                          placeholder="Labor Cost Per Hour"
                          precision={7}
                          currency={values.costCurrency}
                          disabled={!permissions.cost.edit}
                        />
                        <Field.Currency
                          name="overheadCostPerHour"
                          placeholder="Overhead Cost Per Hour"
                          precision={7}
                          currency={values.costCurrency}
                          disabled={!permissions.cost.edit}
                        />
                        <CurrencyInput
                          placeholder="Total Cost Per Hour"
                          value={totalCostPerHour}
                          currency={values.costCurrency}
                          precision={7}
                          disabled
                          onChange={() => {}}
                        />
                      </Form.Control>
                    )}
                  </>
                )}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Close</CancelButton>

                  <Button type="submit">Save &amp; Close</Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default TeamTab;
