const timeLockedReasons = {
  invoice_published: 'Time entry is associated with a published invoice.',
  max_age: `Time entry is older than the workspace limit.`,
  project_archived: 'Time entry is associated with an archived project.',
  project_locked: 'Time entries for this project have been locked.',
  task_locked: 'Time entries for this task have been locked.',
  approved: 'Time entry has been manually approved.',
  step_approved: 'Time entry has an approved workflow step.',
  client_approved: 'Time entry has been approved by the client.',
  not_assigned: 'Time entry is associated with a project to which you are no longer assigned.',
  not_assigned_to_role: 'Time entry is associated with a role to which you are no longer assigned.',
  not_assigned_to_task: 'Time entry is associated with a task to which you are no longer assigned.',
  insufficient_rights: 'Time entry is not yours and you do not have administrative privileges to it.',
  timesheet_submitted: 'Timesheet has been submitted.',
  task_archived: 'Time entry is associated with an archived task.',
  externally_managed: 'Time entry is managed by an external application.',
};

export default timeLockedReasons;
