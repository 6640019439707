import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input } from '~/components';
import { useApi } from '~/contexts';
import { colors } from '~/styles';
import ChangeStatus from './components/ChangeStatus';

const ActionList = styled.div`
  display: flex;
  padding: 1rem 1.25rem;
  border-top: solid 1px ${colors.grey10};

  > :not(:first-child) {
    margin-left: 1rem;
  }
`;

const CloneForm = styled.form`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export default function Actions({ workspace, onChange }) {
  const api = useApi();
  const [changeStatus, setChangeStatus] = useState();
  const [isLoadingDemoData, setIsLoadingDemoData] = useState(false);
  const [isCloneFormVisible, setIsCloneFormVisible] = useState(false);
  const [newWorkspaceName, setNewWorkspaceName] = useState('');
  const [newWorkspaceKey, setNewWorkspaceKey] = useState('');

  const handleChange = () => {
    if (onChange) {
      onChange(workspace);
    }
  };

  const handleDemoDataRequest = async () => {
    setIsLoadingDemoData(true);
    try {
      const { data } = await api.admin.workspaces.createDemoData(workspace.id);
      console.log(data);
    } finally {
      setIsLoadingDemoData(false);
    }
  };

  const handleCloneWorkspace = async (event) => {
    event.preventDefault();
    try {
      const { data } = await api.admin.workspaces.clone({
        workspaceId: workspace.id,
        workspaceName: newWorkspaceName,
        workspaceKey: newWorkspaceKey,
      });
      console.log(data, newWorkspaceKey, newWorkspaceName);

      // Reset form after successful clone
      setNewWorkspaceName('');
      setNewWorkspaceKey('');
      setIsCloneFormVisible(false);
      handleChange();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ActionList>
        <Button
          isOutline={true}
          onClick={(event) => {
            event.stopPropagation();
            setChangeStatus(workspace);
          }}>
          Change Status
        </Button>
        <Button isOutline={true} isLoading={isLoadingDemoData} onClick={handleDemoDataRequest} disabled>
          Load Demo Data
        </Button>
        <Button isOutline={true} onClick={() => setIsCloneFormVisible(!isCloneFormVisible)}>
          Clone Workspace
        </Button>
      </ActionList>
      {isCloneFormVisible && (
        <CloneForm onSubmit={handleCloneWorkspace}>
          Clone this workspace by providing the name and key. The new workspace will copy all custom data and members.
          <Input
            type="text"
            placeholder="New Workspace Name"
            value={newWorkspaceName}
            onChange={(e) => setNewWorkspaceName(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="New Workspace Key"
            value={newWorkspaceKey}
            onChange={(e) => setNewWorkspaceKey(e.target.value)}
            required
          />
          <FormActions>
            <Button type="submit">Submit</Button>
            <Button type="button" onClick={() => setIsCloneFormVisible(false)}>
              Cancel
            </Button>
          </FormActions>
        </CloneForm>
      )}
      {changeStatus && (
        <ChangeStatus workspace={changeStatus} onChange={handleChange} onClose={() => setChangeStatus()} />
      )}
    </>
  );
}
