import {
  Avatar,
  BackLink,
  Checkbox,
  ClientLink,
  DateTime,
  Icon,
  InfiniteScrollingObserver,
  InternalClientTooltip,
  Level,
  MemberContactPopover,
  ProjectLink,
  RouteLink,
  Stack,
  Table,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useIsMounted, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort, intervalOptions } from '~/utils';
import { colors } from '~/styles';
import mimeTypes from '~/utils/mimeTypes';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import Report from '../components/Report';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import PeriodNavTag from '../components/PeriodNavTag';
import ExportDialogAsync from '../components/ExportDialogAsync.jsx';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import useMemberFilters from '~/hooks/useMemberFilters.js';
import MemberFiltersGroup from '../components/MemberFiltersGroup.jsx';
import ClientFiltersGroup from '../components/ClientFiltersGroup.jsx';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup.jsx';
import MemberTagsGroup from '../components/MemberTagsGroup.jsx';
import ClientTagsGroup from '../components/ClientTagsGroup.jsx';
import ProjectTagsGroup from '../components/ProjectTagsGroup.jsx';
import styled from 'styled-components';
import HistoryEntityTag from '../components/HistoryEntityTag';
import HistoryEntityFilter from '../components/HistoryEntityFilter';
import HistoryEventTag from '../components/HistoryEventTag';
import HistoryEventFilter from '../components/HistoryEventFilter';
import HistorySourceTag from '../components/HistorySourceTag';
import HistorySourceFilter from '../components/HistorySourceFilter';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import ViewTimeEntry from '~/routes/app/time/view-time-entry';
import ExpenseItemDrawer from '~/routes/app/expenses/item/ExpenseItemDrawer';
import AllocationForm from '../../resources/allocations/dialogs/AllocationForm';
import { sources } from '../lookups';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import ProjectTaskForm from '../../project-tasks/ProjectTaskForm';
import SecurityRoleForm from '../../settings/security-roles/SecurityRoleForm';

const DetailsInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const HistoryNotes = styled.p`
  flex-basis: 100%;
  padding: 0 4rem 1rem 13rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

function History() {
  const documentTitle = useDocumentTitle('History');
  const { workspace } = useWorkspace();
  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  const route = useRouteMatch();

  const isMounted = useIsMounted();

  const memberFilters = useMemberFilters();
  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [query, setQuery] = useState({
    report: null,
    params: {
      period: null,
      entity: [],
      event: [],
      source: [],
      sort: new QuerySort('eventTime', 'asc'),
      page: 0,
      size: 50,
      ...memberFilters.filters,
      ...clientFilters.filters,
      ...projectFilters.filters,
    },
    action: 'load',
    status: 'loading',
    loading: {
      table: false,
    },
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.report ||
        state.report.records.total <= state.report.records.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.past_30_days },
        entity: reportsSearchParamsConfig.history.entities,
        event: reportsSearchParamsConfig.history.events,
        source: reportsSearchParamsConfig.history.sources,
        sort: { default: new QuerySort('eventTime', 'desc'), ...searchParamsConfig.sort },
        ...memberFilters.searchParamsConfig,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [memberFilters, clientFilters, projectFilters, searchParamsConfig, reportsSearchParamsConfig],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      entity: params.entity?.map((v) => v.id),
      event: params.event?.map((v) => v.id),
      source: params.source?.map((v) => v.id),
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      sort: params.sort,
      page: params.page,
      size: params.size,
      ...memberFilters.mapUrlSearchParams(params),
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, memberFilters, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().history(urlSearchParams);

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      action: null,
      status: 'ready',
      loading: { table: false },
      report: {
        ...data,
        records: {
          ...data.records,
          results:
            state.action === 'load-more'
              ? [...state.report.records.results, ...data.records.results]
              : data.records.results,
        },
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [showNotes, setShowNotes] = useState(false);
  const [drawer, setDrawer] = useState(null);

  const handleRowClick = async (item) => {
    switch (item.entity) {
      case 'allocation':
        history.push({
          pathname: `${route.url}/allocation/${item.entityId}`,
          search: location.search,
          state: { scrollToTop: false },
        });
        break;
      case 'client':
        history.push(`/app/${workspace.key}/clients/${item.entityId}/overview`);
        break;
      case 'client_approval':
        history.push(
          `/app/${workspace.key}/projects/${item.project.client.key}/${item.project.key}/client-approvals/${item.entityId}`,
        );
        break;
      case 'credit_note':
        history.push(`/app/${workspace.key}/billing/credit-notes/${item.entityId}`);
        break;
      case 'expense_item':
        history.push({
          pathname: `${route.url}/expense-item/${item.entityId}`,
          search: location.search,
          state: { scrollToTop: false },
        });
        break;
      case 'invoice':
        history.push(`/app/${workspace.key}/billing/invoices/${item.entityId}`);
        break;
      case 'member':
        history.push(`/app/${workspace.key}/settings/members/details/${item.entityId}`);
        break;
      case 'project':
        history.push(`/app/${workspace.key}/projects/${item.project.client.key}/${item.project.key}/overview`);
        break;
      case 'project_task':
        try {
          const { data } = await api.www.workspaces(workspace.id).projects(item.project.id).get();

          setDrawer({
            item,
            project: data,
            mode: 'editProjectTask',
          });
        } catch {
          // Do nothing
        }
        break;
      case 'security_role':
        history.push({
          pathname: `${route.url}/security-role/${item.entityId}`,
          search: location.search,
          state: { scrollToTop: false },
        });
        break;
      case 'time_entry':
        setDrawer({ item, mode: item.timeReport.isLocked ? 'viewTimeEntry' : 'editTimeEntry' });
        break;
      default:
        return null;
    }
  };

  const handleCloseDrawer = () => {
    if (drawer) {
      setDrawer(null);
    } else {
      history.push({ pathname: route.url, search: location.search, state: { scrollToTop: false } });
    }
    documentTitle.set('History');
  };

  const handleRowChanged = async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .history({
        ...urlSearchParams,
        page: 0,
        // Refetch every loaded page
        size: (query.params.page + 1) * query.params.size,
      });

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      report: data,
    }));

    handleCloseDrawer();
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, page: 0, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .history({ ...urlSearchParams, page: undefined, size: null }, { headers: { accept: mimeType } })}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Report>
        <Header>
          <BackLink defaultPath={`/app/${workspace.key}/reports/workspace`} />

          <Stack>
            <h1>History</h1>

            <Tags>
              <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />
              <HistoryEntityTag value={params.entity} onRemove={() => handleApplyFilters({ entity: [] })} />
              <HistoryEventTag value={params.event} onRemove={() => handleApplyFilters({ event: [] })} />
              <HistorySourceTag value={params.source} onRemove={() => handleApplyFilters({ source: [] })} />
              <MemberTagsGroup filters={params} onChange={handleApplyFilters} />
              <ClientTagsGroup filters={params} onChange={handleApplyFilters} />
              <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />
            </Tags>
          </Stack>

          <Level>
            <Level.Item narrow right>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>
            </Level.Item>

            <Level.Item narrow right>
              <FilterButton isOutline onClick={showFilters} />
            </Level.Item>
          </Level>
        </Header>

        {(() => {
          switch (query.status) {
            case 'loading':
            case 'filtering':
              return <PageLoader />;

            default:
              return (
                <>
                  <Data
                    query={query}
                    params={params}
                    onLoadMore={loadMore}
                    onSort={handleSort}
                    onRowClick={handleRowClick}
                    showNotes={showNotes}
                    setShowNotes={setShowNotes}
                  />
                  <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
                </>
              );
          }
        })()}
      </Report>

      <Switch>
        <Route path={route.path.concat('/allocation/:allocationId')}>
          <AllocationForm onSaved={handleRowChanged} onDeleted={handleRowChanged} onClose={handleCloseDrawer} />
        </Route>

        <Route path={route.path.concat('/expense-item/:expenseItemId')}>
          <ExpenseItemDrawer onSaved={handleRowChanged} onDeleted={handleRowChanged} onClose={handleCloseDrawer} />
        </Route>

        <Route path={route.path.concat('/security-role/:securityRoleId')}>
          <SecurityRoleForm onSaved={handleRowChanged} onClose={handleCloseDrawer} />
        </Route>
      </Switch>

      {drawer &&
        {
          editProjectTask: () => (
            <ProjectTaskForm
              taskId={drawer.item.entityId}
              project={drawer.project}
              onSaved={handleRowChanged}
              onClose={handleCloseDrawer}
            />
          ),
          editTimeEntry: () => (
            <EditTimeEntry
              id={drawer.item.entityId}
              onSaved={handleRowChanged}
              onDeleted={handleRowChanged}
              onClose={handleCloseDrawer}
            />
          ),
          viewTimeEntry: () => <ViewTimeEntry id={drawer.item.entityId} onClose={handleCloseDrawer} />,
        }[drawer.mode]()}
    </>
  );
}

function Data({ query, onLoadMore, onSort, onRowClick, showNotes, setShowNotes }) {
  const { report, params } = query;
  const features = useFeatures();
  const { workspace } = useWorkspace();

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level.Item width="8rem">
        <div style={{ display: 'flex', position: 'relative', fontSize: '.875rem' }}>
          <Checkbox
            label="Show notes"
            checked={showNotes}
            onChange={(e) => {
              setShowNotes(e.target.checked);
            }}
          />
        </div>
      </Level.Item>
      <Level.Item>
        <TotalResults>
          {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
          <Table.Total label="Item" value={report.records.total} />
        </TotalResults>
      </Level.Item>

      <Table>
        <Table.Header sticky>
          <Table.Column name="eventTime" width="12rem" onSort={onSort} sort={params.sort}>
            Event Time
          </Table.Column>
          <Table.Column name="entity" width="10rem" onSort={onSort} sort={params.sort}>
            Entity
          </Table.Column>
          <Table.Column>Details</Table.Column>
          <Table.Column name="statusId" width="16rem" onSort={onSort} sort={params.sort}>
            Event
          </Table.Column>
          <Table.Column width="14rem">Action By</Table.Column>
          <Table.Column width="12rem">Source</Table.Column>
        </Table.Header>

        <TableBody fade={query.loading.table}>
          {report.records.results.map((item) => {
            const hasNotes = !!(showNotes && item.notes);
            let isClickable = true;

            if (item.statusId === 'deleted') {
              isClickable = false;
            } else {
              switch (item.entity) {
                case 'allocation':
                  if (!features.allocations) isClickable = false;
                  break;
                case 'expense_item':
                  if (!features.expenseReporting) isClickable = false;
                  break;
                case 'client_approval':
                  if (!features.clientApprovals || !item.project?.enableClientApprovals) isClickable = false;
                  break;
                default:
                  break;
              }
            }

            const handleRowClick = () => onRowClick(item);

            return (
              <Table.Row
                style={{ flexWrap: 'wrap' }}
                onClick={isClickable ? () => handleRowClick() : null}
                key={item.id}>
                <Table.Cell>
                  <DateTime value={item.eventTime} format="l LT" />
                </Table.Cell>
                <Table.Cell>{item.entityName}</Table.Cell>
                {(() => {
                  if (item.statusId !== 'deleted')
                    switch (item.entity) {
                      case 'allocation':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              {item.allocation.assignmentTypeId === 'project' ? (
                                <>
                                  <small>
                                    Project:&nbsp;
                                    <ProjectLink project={item.project} onClick={(e) => e.stopPropagation()} />
                                  </small>
                                  <small>
                                    Client:&nbsp;
                                    <ClientLink client={item.project.client} onClick={(e) => e.stopPropagation()} />
                                    {item.project.client.isInternal && <InternalClientTooltip />}
                                  </small>
                                </>
                              ) : (
                                <small>Time Off Type: {item.allocation.timeOffType.name}</small>
                              )}
                              {item.allocation.resourceTypeId === 'member' ? (
                                <small>Member: {item.allocation.member.name}</small>
                              ) : (
                                <small>Resource Placeholder: {item.allocation.placeholder.name}</small>
                              )}
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'client':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              <small>
                                Client:&nbsp;
                                <ClientLink client={item.client} onClick={(e) => e.stopPropagation()} />
                              </small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'credit_note':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              <small>
                                Credit Note:&nbsp;
                                <RouteLink to={`/app/${workspace.key}/billing/credit-notes/${item.creditNote.id}`}>
                                  #{item.creditNote.number}
                                </RouteLink>
                              </small>
                              <small>
                                Client:&nbsp;
                                <ClientLink client={item.client} onClick={(e) => e.stopPropagation()} />
                                {item.client.isInternal && <InternalClientTooltip />}
                              </small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'expense_item':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              {item.project ? (
                                <>
                                  <small>
                                    Project:&nbsp;
                                    <ProjectLink project={item.project} onClick={(e) => e.stopPropagation()} />
                                  </small>
                                  <small>
                                    Client:&nbsp;
                                    <ClientLink client={item.project.client} onClick={(e) => e.stopPropagation()} />
                                    {item.project.client.isInternal && <InternalClientTooltip />}
                                  </small>
                                </>
                              ) : (
                                <small>Internal Expense</small>
                              )}
                              <small>Member: {item.expenseItem.member.name}</small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'invoice':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              <small>
                                Invoice:&nbsp;
                                <RouteLink to={`/app/${workspace.key}/billing/invoices/${item.invoice.id}`}>
                                  #{item.invoice.number}
                                </RouteLink>
                              </small>
                              <small>
                                Client:&nbsp;
                                <ClientLink client={item.client} onClick={(e) => e.stopPropagation()} />
                                {item.client.isInternal && <InternalClientTooltip />}
                              </small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'member':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              <small>
                                Member:&nbsp;
                                <MemberContactPopover member={item.member}>
                                  <RouteLink to={`/app/${workspace.key}/settings/members/details/${item.member.id}`}>
                                    {item.member.name}
                                  </RouteLink>
                                </MemberContactPopover>
                              </small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'project':
                      case 'client_approval':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              <small>
                                Project:&nbsp;
                                <ProjectLink project={item.project} onClick={(e) => e.stopPropagation()} />
                              </small>
                              <small>
                                Client:&nbsp;
                                <ClientLink client={item.project.client} onClick={(e) => e.stopPropagation()} />
                                {item.project.client.isInternal && <InternalClientTooltip />}
                              </small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'project_task':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              <small>
                                Task:&nbsp;
                                <RouteLink
                                  to={`/app/${workspace.key}/projects/${item.project.client.key}/${item.project.key}/tasks/${item.projectTask.number}/edit`}>
                                  {item.projectTask.name}
                                </RouteLink>
                              </small>
                              <small>
                                Project:&nbsp;
                                <ProjectLink project={item.project} onClick={(e) => e.stopPropagation()} />
                              </small>
                              <small>
                                Client:&nbsp;
                                <ClientLink client={item.project.client} onClick={(e) => e.stopPropagation()} />
                                {item.project.client.isInternal && <InternalClientTooltip />}
                              </small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'security_role':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              <small>
                                Security Role:&nbsp;
                                <RouteLink
                                  to={`/app/${workspace.key}/settings/security-roles/${item.securityRole.id}/edit`}>
                                  {item.securityRole.name}
                                </RouteLink>
                              </small>
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      case 'time_entry':
                        return (
                          <Table.Cell>
                            <DetailsInfo>
                              {item.timeReport.typeId === 'project_time' ? (
                                <>
                                  <small>
                                    Project:&nbsp;
                                    <ProjectLink project={item.project} onClick={(e) => e.stopPropagation()} />
                                  </small>
                                  <small>
                                    Client:&nbsp;
                                    <ClientLink client={item.project.client} onClick={(e) => e.stopPropagation()} />
                                    {item.project.client.isInternal && <InternalClientTooltip />}
                                  </small>
                                </>
                              ) : (
                                <small>Time Off Type: {item.timeReport.timeOffType?.name}</small>
                              )}
                              <small>Member: {item.timeReport.member?.name}</small>
                              {item.timeReport.role && <small>Role: {item.timeReport.role?.name}</small>}
                            </DetailsInfo>
                          </Table.Cell>
                        );
                      default:
                        return <Table.Cell />;
                    }
                  else {
                    return <Table.Cell />;
                  }
                })()}
                <Table.Cell>{item.status?.name}</Table.Cell>
                <Table.Cell>
                  {item.actionBy && (
                    <Member>
                      <MemberContactPopover member={item.actionBy} placement="left">
                        <Avatar value={item.actionBy} isCircle hasBackground initialsFontSize=".9rem" />
                      </MemberContactPopover>
                      <MemberInfo>{item.actionBy.name}</MemberInfo>
                    </Member>
                  )}
                </Table.Cell>
                <Table.Cell>{sources[item.sourceId]?.name}</Table.Cell>
                {hasNotes && <HistoryNotes>{item.notes}</HistoryNotes>}
              </Table.Row>
            );
          })}
        </TableBody>
      </Table>
      {report.records.total > report.records.results.length && (
        <InfiniteScrollingObserver onIntersecting={onLoadMore} />
      )}
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>
      <Filter>
        <HistoryEntityFilter
          materialPlaceholder="Entity"
          value={filters.entity}
          onChange={({ target: { value } }) => handleFilter({ entity: value })}
        />
      </Filter>
      <Filter>
        <HistoryEventFilter
          materialPlaceholder="Event"
          value={filters.event}
          onChange={({ target: { value } }) => handleFilter({ event: value })}
        />
      </Filter>
      <Filter>
        <HistorySourceFilter
          materialPlaceholder="Source"
          value={filters.source}
          onChange={({ target: { value } }) => handleFilter({ source: value })}
        />
      </Filter>
      <MemberFiltersGroup filters={filters} onChange={handleFilter} />
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />
      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}

export default History;
