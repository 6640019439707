const paymentTerms = {
  due_on_receipt: { id: 'due_on_receipt', name: 'Due on receipt' },
  net_10: { id: 'net_10', name: 'Net-10' },
  net_15: { id: 'net_15', name: 'Net-15' },
  net_21: { id: 'net_21', name: 'Net-21' },
  net_30: { id: 'net_30', name: 'Net-30' },
  net_45: { id: 'net_45', name: 'Net-45' },
  net_60: { id: 'net_60', name: 'Net-60' },
  net_75: { id: 'net_75', name: 'Net-75' },
  net_90: { id: 'net_90', name: 'Net-90' },
  net_120: { id: 'net_120', name: 'Net-120' },
  net_1_end_of_month: { id: 'net_1_end_of_month', name: 'Net-1 End-of-Month' },
  net_15_end_of_month: { id: 'net_15_end_of_month', name: 'Net-15 End-of-Month' },
  net_20_end_of_month: { id: 'net_20_end_of_month', name: 'Net-20 End-of-Month' },
  net_30_end_of_month: { id: 'net_30_end_of_month', name: 'Net-30 End-of-Month' },
  net_60_end_of_month: { id: 'net_60_end_of_month', name: 'Net-60 End-of-Month' },
  last_day_of_next_month: { id: 'last_day_of_next_month', name: 'Last Day of next Month' },
};

export default paymentTerms;
