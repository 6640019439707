import {
  ApprovalStatusFilter,
  BackLink,
  ClientLink,
  ClientApprovalStatusFilter,
  DisciplineFilter,
  Hours,
  Icon,
  InternalClientTooltip,
  Level,
  ProjectTaskFilter,
  RouteLink,
  Stack,
  Table,
  Widget,
  InvoicedFilter,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  ProjectTaskTagFilter,
  Checkbox,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import ProjectTaskTag from '../components/ProjectTaskTag';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import RoleDisciplineTag from '../components/RoleDisciplineTag';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TimeStatusTag from '../components/TimeStatusTag';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import InvoicedTag from '../components/InvoicedTag';
import FiltersGroup from '../components/FiltersGroup';
import ProjectTaskRecordStatusTag from '../components/ProjectTaskRecordStatusTag';
import ProjectTaskStatusTag from '../components/ProjectTaskStatusTag';
import ProjectTaskTags from '../components/ProjectTaskTags';
import PeriodNavTag from '../components/PeriodNavTag';
import useClientFilters from '~/hooks/useClientFilters';
import ClientTagsGroup from '../components/ClientTagsGroup';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import useMemberFilters from '~/hooks/useMemberFilters';
import MemberTagsGroup from '../components/MemberTagsGroup';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import useProjectFilters from '~/hooks/useProjectFilters';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Tag from '../components/Tag';

function TimeSummaryByClient() {
  useDocumentTitle('Time Summary by Client');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    projectTasks: [],
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    projectTaskTags: [],
    approvalStatuses: [],
    clientApprovalStatuses: [],
    invoiced: null,
    roleDisciplines: [],
    projectRoles: [],
    includeClientsWithoutTime: false,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        invoiced: searchParamsConfig.invoiced,
        roleDisciplines: { ...searchParamsConfig.disciplines, key: 'roleDiscipline' },
        projectRoles: searchParamsConfig.projectRoles,
        includeClientsWithoutTime: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      invoiced: params.invoiced ?? undefined,
      roleDisciplineId: params.roleDisciplines?.map((v) => v.id),
      projectRoleId: params.projectRoles?.map((v) => v.id),
      includeClientsWithoutTime: params.includeClientsWithoutTime ? 'true' : undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeSummaryByClient(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeSummaryByClient(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/time`} />
        <Stack>
          <h1>Time Summary by Client</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTaskTag value={params.projectTasks} onRemove={() => handleApplyFilters({ projectTasks: [] })} />

            <ProjectTaskStatusTag
              value={params.projectTaskStatuses}
              onRemove={() => handleApplyFilters({ projectTaskStatuses: [] })}
            />

            <ProjectTaskRecordStatusTag
              value={params.projectTaskRecordStatusId}
              onRemove={() => handleApplyFilters({ projectTaskRecordStatusId: null })}
            />

            <ProjectTaskTags
              value={params.projectTaskTags}
              onRemove={() => handleApplyFilters({ projectTaskTags: [] })}
            />

            <TimeStatusTag
              value={params.approvalStatuses}
              onRemove={() => handleApplyFilters({ approvalStatuses: [] })}
            />

            {features.clientApprovals && (
              <ClientApprovalStatusTag
                value={params.clientApprovalStatuses}
                onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
              />
            )}

            <InvoicedTag value={params.invoiced} onRemove={() => handleApplyFilters({ invoiced: null })} />

            <RoleDisciplineTag
              value={params.roleDisciplines}
              onRemove={() => handleApplyFilters({ roleDisciplines: [] })}
            />

            <MemberTagsGroup filters={params} onChange={handleApplyFilters} />

            {params.includeClientsWithoutTime && <Tag>Include clients without time</Tag>}
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          projectRole: params.projectRoles?.map((v) => v.id),
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          roleDiscipline: params.roleDisciplines?.map((v) => v.id),
          projectTask: params.projectTasks?.map((v) => v.id),
          projectTaskStatus: params.projectTaskStatuses?.map((v) => v.id),
          projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
          projectTaskTag: params.projectTaskTags?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Summary>
        <Widget>
          <Meter>
            <Meter.Value label="Client Billable" value={report.totals.billable} color={colors.success} />
            <Meter.Value label="Client Non-Billable" value={report.totals.nonBillable} color={colors.danger} />
            <Meter.Value label="Internal" value={report.totals.internal} color={colors.warning} />
          </Meter>

          <Metrics>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'billable' })}>
                <Metric.Value>
                  <Hours value={report.totals.billable} />
                </Metric.Value>
                <Metric.Label>Client Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'non_billable' })}>
                <Metric.Value>
                  <Hours value={report.totals.nonBillable} />
                </Metric.Value>
                <Metric.Label>Client Non-Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: 'internal' })}>
                <Metric.Value>
                  <Hours value={report.totals.internal} />
                </Metric.Value>
                <Metric.Label>Internal</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink to={timeDetail({ billableType: ['billable', 'non_billable', 'internal'] })}>
                <Metric.Value>
                  <Hours value={report.totals.total} />
                </Metric.Value>
                <Metric.Label>Total</Metric.Label>
              </RouteLink>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <TotalResults>
          {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
          <Table.Total label="Client" value={report.records.length} />
          {report.partialResults && <PartialResultsTooltip />}
        </TotalResults>

        <Table>
          <Table.Header sticky>
            <Table.Column name="name" onSort={onSort} sort={params.sort}>
              Client
            </Table.Column>
            <Table.Column name="billable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Client
              <br />
              Billable
            </Table.Column>
            <Table.Column name="nonBillable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Client
              <br />
              Non-Billable
            </Table.Column>
            <Table.Column name="internal" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Internal
            </Table.Column>
            <Table.Column name="total" width="9rem" align="right" onSort={onSort} sort={params.sort}>
              Total
            </Table.Column>
          </Table.Header>

          <TableBody fade={query.status === 'sorting'}>
            {report.records.map((client) => {
              return (
                <Table.Row key={client.id}>
                  <Table.Cell>
                    <ClientLink client={client} />
                    {client.isInternal && <InternalClientTooltip />}
                  </Table.Cell>
                  <Table.Cell>
                    <RouteLink to={timeDetail({ billableType: 'billable', client: client.id })}>
                      <Hours value={client.billable} />
                    </RouteLink>
                  </Table.Cell>
                  <Table.Cell>
                    <RouteLink to={timeDetail({ billableType: 'non_billable', client: client.id })}>
                      <Hours value={client.nonBillable} />
                    </RouteLink>
                  </Table.Cell>
                  <Table.Cell>
                    <RouteLink to={timeDetail({ billableType: 'internal', client: client.id })}>
                      <Hours value={client.internal} />
                    </RouteLink>
                  </Table.Cell>
                  <Table.Cell>
                    <RouteLink to={timeDetail({ client: client.id })}>
                      <Hours value={client.total} />
                    </RouteLink>
                  </Table.Cell>
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: 'billable' })}>
                  <Hours value={report.totals.billable} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: 'non_billable' })}>
                  <Hours value={report.totals.nonBillable} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: 'internal' })}>
                  <Hours value={report.totals.internal} />
                </RouteLink>
              </Table.Cell>
              <Table.Cell>
                <RouteLink to={timeDetail({ billableType: ['billable', 'non_billable', 'internal'] })}>
                  <Hours value={report.totals.total} />
                </RouteLink>
              </Table.Cell>
            </Table.Row>
          </TableBody>
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeClientsWithoutTime}
          label="Include clients without tracked time"
          onChange={({ target: { checked } }) => handleFilter({ includeClientsWithoutTime: checked })}
        />
      </Filter>

      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Task Filters"
        filters={[
          filters.projectTasks,
          filters.projectTaskRecordStatusId,
          filters.projectTaskStatuses,
          filters.projectTaskTags,
        ]}>
        {filters.projects.length === 1 && (
          <Filter>
            <ProjectTaskFilter
              project={filters.projects[0]}
              value={filters.projectTasks}
              onChange={({ target: { value } }) => handleFilter({ projectTasks: value })}
            />
          </Filter>
        )}
        <Filter>
          <ProjectTaskStatusFilter
            value={filters.projectTaskStatuses}
            onChange={({ target: { value } }) => handleFilter({ projectTaskStatuses: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskRecordStatusFilter
            value={filters.projectTaskRecordStatusId}
            onChange={({ target: { value } }) => handleFilter({ projectTaskRecordStatusId: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskTagFilter
            value={filters.projectTaskTags}
            onChange={({ target: { value } }) => handleFilter({ projectTaskTags: value })}
          />
        </Filter>
      </FiltersGroup>

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Time Filters"
        filters={[filters.approvalStatuses, filters.clientApprovalStatuses, filters.invoiced, filters.roleDisciplines]}>
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => handleFilter({ approvalStatuses: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}

        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => handleFilter({ invoiced: value })}
          />
        </Filter>
        {features.disciplines && (
          <Filter>
            <DisciplineFilter
              value={filters.roleDisciplines}
              materialPlaceholder="Role Discipline"
              onChange={({ target: { value } }) => handleFilter({ roleDisciplines: value })}
            />
          </Filter>
        )}
      </FiltersGroup>
    </Report.Filters>
  );
}

export default TimeSummaryByClient;
