import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { LoadingIcon, ResultMessage, StepTitle } from './styles';
import { Icon, Level } from '~/components'; // Import ToggleSwitch

const BaseIndicator = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: ${colors.white};
  color: ${colors.grey100};
  border: 1px solid ${(props) => (props.passesCheck ? colors.success : props.isInProgress ? colors.info : colors.error)};
`;

const DetailErrorList = styled.ul`
  color: ${colors.grey100};
  list-style-type: none;
  width: 100%;
`;

const TopLevelItem = styled.div`
  cursor: pointer;
  color: ${colors.grey100};
  border: 1px solid ${colors.grey20};
  width: 100%;
  border-radius: 0.35rem;
  padding: 1rem;
  margin: 0rem 0rem 0.5rem -1rem;
`;

const NestedList = styled.ul`
  list-style-type: none;
  padding: 0rem 0rem;
`;

export const ToggleSwitch = styled.div`
  flex: 1;
  display: flex;
  min-width: 2rem;
  justify-content: right;
  margin-top: 0.25rem;
`;

function RowsCheckIndicator({ progress }) {
  if (!progress) return null;

  const { result } = progress;

  if (!result) {
    return (
      <BaseIndicator isInProgress>
        <StepTitle>
          <LoadingIcon icon="spinner" spin />
          <strong>Validation Step 2</strong> - Reviewing CSV Rows
        </StepTitle>
        <small>Check in progress...</small>
      </BaseIndicator>
    );
  }

  const { summary, errorCounts, messages } = result;
  const organizedMessages = summary.passesCheck ? null : organizeErrorsByHeader(messages);

  const statusIcon = summary.passesCheck ? (
    <Icon icon="check" className="icon" />
  ) : (
    <Icon icon="warning" color={colors.warning100} className="icon" />
  );

  return (
    <BaseIndicator
      passesCheck={summary.passesCheck}
      style={{ borderColor: summary.passesCheck ? colors.success : colors.error }}>
      <StepTitle>
        {statusIcon}
        <strong>Validation Step 2</strong> - Reviewing CSV Rows
      </StepTitle>
      <ResultMessage>
        {summary.passesCheck ? (
          'All rows have passed initial validation checks and can be submitted for import.'
        ) : (
          <ErrorList organizedMessages={organizedMessages} errorCounts={errorCounts} />
        )}
      </ResultMessage>
    </BaseIndicator>
  );
}

export default RowsCheckIndicator;

function organizeErrorsByHeader(messages) {
  const headersMap = {};

  Object.entries(messages).forEach(([rowNumber, rowMessages]) => {
    rowMessages.forEach((message) => {
      const { column } = message;
      if (!headersMap[column]) {
        headersMap[column] = {};
      }
      if (!headersMap[column][rowNumber]) {
        headersMap[column][rowNumber] = [];
      }
      headersMap[column][rowNumber].push(message);
    });
  });

  return headersMap;
}

function ErrorList({ organizedMessages, errorCounts }) {
  const [expandedHeaders, setExpandedHeaders] = useState({});

  const toggleExpand = (header) => {
    setExpandedHeaders((prev) => ({
      ...prev,
      [header]: !prev[header],
    }));
  };

  return (
    <DetailErrorList>
      {Object.entries(organizedMessages).map(([header, rows]) => (
        <TopLevelItem key={header} onClick={() => toggleExpand(header)}>
          <Level>
            <strong>
              {header} ({errorCounts[header]} errors)
            </strong>
            <ToggleSwitch>
              <Icon color={colors.grey25} icon={expandedHeaders[header] ? 'chevron-down' : 'chevron-right'} />
            </ToggleSwitch>
          </Level>
          {expandedHeaders[header] && (
            <NestedList>
              {Object.entries(rows).map(([rowNumber, errors]) => (
                <li key={`row-${rowNumber}`}>
                  <strong>Row {parseInt(rowNumber, 10) + 1}</strong>:&nbsp;
                  {errors.map((error, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && ', '}
                      <span>{error.errorMessage}</span>
                    </React.Fragment>
                  ))}
                </li>
              ))}
            </NestedList>
          )}
        </TopLevelItem>
      ))}
    </DetailErrorList>
  );
}
