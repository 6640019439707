import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import path from 'path-browserify';
import { useActions } from '~/hooks';
import { dateFormats, QueryString } from '~/utils';
import { useApi } from './ApiContext';
import { useSubscription } from './SubscriptionContext';

const WorkspaceContext = React.createContext({
  isReady: false,
  workspace: null,
  workspaceId: null,
  isSelected: false,
  hasMessage: false,
  message: null,
  selectWorkspace: () => {},
  clearWorkspace: () => {},
  updateWorkspace: () => {},
  updateMember: () => {},
  deleteWorkspace: () => {},
  refreshWorkspace: () => {},
});

const initialState = { isReady: false, workspace: null };
const handlers = {
  ready: (workspace) => ({ isReady: true, workspace }),
  updateWorkspace: (workspace, state) => ({ isReady: true, workspace: { ...state.workspace, ...workspace } }),
  updateMember: (member, state) => ({
    workspace: { ...state.workspace, member: { ...state.workspace.member, ...member } },
  }),
};

function WorkspaceProvider({ children }) {
  const api = useApi();
  const [{ isReady, workspace }, actions] = useActions(handlers, initialState);
  const { notify } = useSubscription();
  const isRefreshing = useRef(false);
  const isSelected = !!workspace;
  const workspaceId = workspace?.id;

  const history = useHistory();

  // Currently we only show a message at the top of the screen when a
  // subscription is past due or:
  // 1) There is a trial with > 0 days and <= 5 days left
  // 2) The workspace has > 3 active/billable members
  // 3) The workspace billing is not configured, which requires:
  //    a) A billing address has been set
  //    b) A credit card has been added or is set to manual billing
  // The billing status message is only visible by workspace admins.
  const message = useMemo(() => {
    if (!workspace?.member?.securityRole?.manageWorkspace) return null;
    if (workspace?.billingStatus?.isPastDue) {
      return 'Your subscription is past due.';
    }
    if (
      !workspace?.billingStatus?.trialEndsAt ||
      workspace?.billingStatus?.isConfigured === true ||
      workspace?.billingStatus?.memberCount <= 3
    ) {
      return null;
    }
    const trialEnd = moment(workspace.billingStatus.trialEndsAt);
    const trialDaysLeft = Math.max(trialEnd.diff(moment(), 'days', true), 0);
    if (trialDaysLeft > 0 && trialDaysLeft <= 5) {
      const trialEndDate = trialEnd.format(dateFormats.longDate);
      return `Your free trial will end soon on ${trialEndDate}.`;
    }
    return null;
  }, [workspace]);

  const hasMessage = !!message;

  const selectWorkspace = useCallback(
    async (workspaceId, shouldRedirect = false, redirectPath = '') => {
      try {
        const getWorkspace = workspaceId
          ? api.www.workspaces(workspaceId).select
          : api.www.workspaces().lastAccessed().select;
        const { data } = await getWorkspace();
        actions.ready(data);
        if (shouldRedirect) {
          history.push(path.resolve(`/app/${data.key}`, redirectPath));
        }
        return data;
      } catch (error) {
        // If the user is not authenticated, let the router show the login screen
        // Otherwise, the workspace may be invalid, so clear it and navigate to the home page
        // This may eventually handle 404 errors by navigating to the Not Found page
        // Other types of errors may display a notification
        if (error.status === 401) {
          // Pass forward the `source` query string param
          let source = undefined;
          const currentParams = new URLSearchParams(window.location.search);
          if (currentParams.has('source')) {
            source = currentParams.get('source');
            currentParams.delete('source');
          }
          const search = currentParams.toString().length > 0 ? '?' + currentParams.toString() : '';
          const params = {
            redirect: encodeURIComponent(window.location.pathname + search),
            source,
            workspace: workspaceId,
          };
          // Use window location to prevent refreshing this callback every time a route changes
          history.push(`/login`.concat(new QueryString(params).toString(true)));
          return;
        }
        // If the workspace doesn't exist, redirect home
        else if (error.status === 404 && shouldRedirect) {
          history.push('/');
        }

        actions.ready(null);
      }
    },
    [actions, api, history],
  );

  const updateWorkspace = useCallback(
    (workspace) => {
      actions.updateWorkspace(workspace);
      notify(useSubscription.keys.workspace_changed);
    },
    [notify, actions],
  );

  const updateMember = useCallback(
    (member) => {
      actions.updateMember(member);
    },
    [actions],
  );

  const clearWorkspace = useCallback(() => {
    actions.ready(null);
  }, [actions]);

  const deleteWorkspace = useCallback(
    async (values) => {
      await api.www.workspaces(workspace.id).delete(values);
      await selectWorkspace(null, true);
      notify('workspace-deleted');
    },
    [selectWorkspace, workspace, notify, api],
  );

  const refreshWorkspace = useCallback(async () => {
    if (!workspaceId || isRefreshing.current) {
      return;
    }
    isRefreshing.current = true;

    const { data } = await api.www.workspaces(workspaceId).get();
    updateWorkspace(data);

    isRefreshing.current = false;
  }, [api, workspaceId, updateWorkspace]);

  return (
    <WorkspaceContext.Provider
      value={{
        isReady,
        workspace,
        workspaceId,
        isSelected,
        hasMessage,
        message,
        selectWorkspace,
        clearWorkspace,
        updateWorkspace,
        updateMember,
        deleteWorkspace,
        refreshWorkspace,
      }}>
      {children}
    </WorkspaceContext.Provider>
  );
}

function useWorkspace() {
  return useContext(WorkspaceContext);
}

export { WorkspaceContext, useWorkspace, WorkspaceProvider };
