import _ from 'lodash';
import { del, get, patch, post, put } from './agent';
import allocations from './allocations';
import apiKeys from './apiKeys';
import authProviders from './authProviders';
import bamboohr from './bamboohr';
import clientPayments from './clientPayments';
import clientTags from './clientTags';
import clients from './clients';
import companies from './companies';
import companySpendTiers from './companySpendTiers';
import companyTypes from './companyTypes';
import contacts from './contacts';
import creditNotes from './creditNotes';
import disciplines from './disciplines';
import executiveDashboard from './executiveDashboard';
import expenseAdmin from './expenseAdmin';
import expenseCategories from './expenseCategories';
import expenseItems from './expenseItems';
import expenseReports from './expenseReports';
import expensify from './expensify';
import features from './features';
import holidaySchedules from './holidaySchedules';
import holidays from './holidays';
import industries from './industries';
import integrations from './integrations';
import invoiceItems from './invoiceItems';
import invoices from './invoices';
import jobTitles from './jobTitles';
import locations from './locations';
import memberTags from './memberTags';
import members from './members';
import mfa from './mfa';
import myProfile from './myProfile';
import opportunities from './opportunities';
import opportunityLeadSources from './opportunityLeadSources';
import opportunityStages from './opportunityStages';
import opportunityTypes from './opportunityTypes';
import paymentMethods from './paymentMethods';
import personalDashboard from './personalDashboard';
import pipelineActivities from './pipelineActivities';
import pipelineActivityTypes from './pipelineActivityTypes';
import practices from './practices';
import projectMemberRoles from './projectMemberRoles';
import projectMembers from './projectMembers';
import projectTags from './projectTags';
import projectTaskTags from './projectTaskTags';
import projectTaskTemplates from './projectTaskTemplates';
import projectTasks from './projectTasks';
import projectTypes from './projectTypes';
import projects from './projects';
import qbd from './qbd';
import qbo from './qbo';
import reports from './reports';
import resourcePlaceholders from './resourcePlaceholders';
import search from './search';
import securityRoles from './securityRoles';
import skills from './skills';
import stripe from './stripe';
import taxRates from './taxRates';
import timeAdmin from './timeAdmin';
import timeEntries from './timeEntries';
import timeOffTypes from './timeOffTypes';
import timesheets from './timesheets';
import weekFiles from './weekFiles';
import workspaceRoles from './workspaceRoles';
import xero from './xero';
import ratings from './ratings';
import salesforce from './salesforce';

function workspaces(workspaceId = '') {
  const url = _(['/www/workspaces', workspaceId]).compact().join('/');

  return {
    get: () => get(url),
    select: () => post(url),
    trialRequest: () => post(url.concat('/trial-request')),
    lastAccessed: () => ({
      select: () => post(url.concat('/last-accessed')),
    }),
    create: (body) => post(url, body),
    patch: (body) => patch(url, body),
    setImage: (file) =>
      put(url.concat('/image'), file?.bytes, file ? { headers: { 'Content-Type': file?.type } } : undefined),
    setInvoiceImage: (file) =>
      put(url.concat('/invoice-image'), file?.bytes, file ? { headers: { 'Content-Type': file?.type } } : undefined),
    getProjectCodeSettings: () => get(url.concat('/project-code')),
    updateProjectCodeSettings: (body) => patch(url.concat('/project-code'), body),
    getClientCodeSettings: () => get(url.concat('/client-code')),
    updateClientCodeSettings: (body) => patch(url.concat('/client-code'), body),
    getTaskCodeSettings: () => get(url.concat('/task-code')),
    updateTaskCodeSettings: (body) => patch(url.concat('/task-code'), body),
    delete: ({ name }) => del(url, { data: { name } }),
    projectTasks: projectTasks(url.concat('/project-tasks')),
    projectMembers: projectMembers(url.concat('/project-members')),
    members: members(url),
    myProfile: myProfile(url),
    mfa: mfa(url),
    clients: clients(url),
    companies: companies(url),
    companySpendTiers: companySpendTiers(url),
    companyTypes: companyTypes(url),
    contacts: contacts(url),
    projects: projects(url),
    invoiceItems: invoiceItems(url),
    jobTitles: jobTitles(url),
    projectTypes: projectTypes(url),
    clientTags: clientTags(url),
    disciplines: disciplines(url),
    holidays: holidays(url),
    holidaySchedules: holidaySchedules(url),
    industries: industries(url),
    locations: locations(url),
    memberTags: memberTags(url),
    skills: skills(url),
    paymentMethods: paymentMethods(url),
    practices: practices(url),
    opportunityLeadSources: opportunityLeadSources(url),
    opportunityTypes: opportunityTypes(url),
    pipelineActivities: pipelineActivities(url),
    pipelineActivityTypes: pipelineActivityTypes(url),
    opportunityStages: opportunityStages(url),
    opportunities: opportunities(url),
    projectTags: projectTags(url),
    resourcePlaceholders: resourcePlaceholders(url),
    workspaceRoles: workspaceRoles(url),
    securityRoles: securityRoles(url),
    integrations: integrations(url),
    projectTaskTags: projectTaskTags(url),
    projectTaskTemplates: projectTaskTemplates(url),
    taxRates: taxRates(url),
    timesheets: timesheets(url),
    timeEntries: timeEntries(url),
    timeOffTypes: timeOffTypes(url),
    projectMemberRoles: projectMemberRoles(url.concat('/project-member-roles')),
    expenseReports: expenseReports(url.concat('/expense-reports')),
    expenseAdmin: expenseAdmin(url.concat('/expense-admin')),
    expenseItems: expenseItems(url.concat('/expense-items')),
    expenseCategories: expenseCategories(url),
    personalDashboard: personalDashboard(url),
    search: search(url),
    invoices: invoices(url),
    clientPayments: clientPayments(url),
    creditNotes: creditNotes(url),
    timeAdmin: timeAdmin(url),
    ratings: ratings(url),
    reports: reports(url),
    allocations: allocations(url),
    qbd: qbd(url),
    qbo: qbo.workspace(url),
    stripe: stripe.workspace(url),
    xero: xero.workspace(url),
    salesforce: salesforce.workspace(url),
    apiKeys: apiKeys(url),
    expensify: expensify(url),
    bamboohr: bamboohr(url),
    weekFiles: weekFiles(url),
    features: features(url),
    timeApproval: {
      get: () => get(url.concat('/time-approval')),
      update: (body) => put(url.concat('/time-approval'), body),
    },
    expenseApproval: {
      get: () => get(url.concat('/expense-approval')),
      update: (body) => put(url.concat('/expense-approval'), body),
    },
    authProviders: authProviders(url),
    executiveDashboard: executiveDashboard(url),
    removeSampleData: () => del(url.concat('/sample-data')),
  };
}

export default workspaces;
