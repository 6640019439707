/**
 * Validates if a given value can be interpreted as a boolean true.
 * Accepts certain string representations of true such as 'yes', 'true', '1',
 * or any other truthy value.
 *
 * @param {any} value - The value to be validated.
 * @returns {boolean} - Returns true if the value can be considered as boolean true.
 */
export default function isTruthy(value) {
  const normalizedValue = String(value).trim().toLowerCase();
  const trueValues = ['yes', 'true', '1'];
  return trueValues.includes(normalizedValue) || Boolean(value);
}
