import { Checkbox, Currency, DateTime, Icon, InlineTooltip, Level, Spinner, Table } from '~/components';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import ExpenseAmountWidget from '../ExpenseAmountWidget';
import {
  ApprovalAction,
  ApprovalActions,
  Approve,
  Cell,
  Details,
  List,
  ListGroup,
  ListGroupContent,
  ListGroupHeader,
  ListHeader,
  NoResultsCell,
  Notes,
  Reject,
  Row,
  SelectAll,
  ToggleSwitch,
} from './StyledComponents';

const Byline = styled.small`
  display: block;
`;

const ApproverQueueWarning = styled.div`
  position: relative;
  font-size: 1rem;
`;

function ExpenseApprovalResults({
  results,
  selection,
  isSubmitting,
  isApproverQueue,
  onSelectionChange,
  onStatusChange,
  onGroupAction,
  onResultClick,
  onChange,
  action,
}) {
  const [showNotes, setShowNotes] = useState(false);

  const groups = useMemo(() => {
    return results.reduce((a, v) => {
      a[v.member.id] = a[v.member.id] || { member: v.member, items: [] };
      a[v.member.id].items.push(v);
      return a;
    }, {});
  }, [results]);

  const enabledItems = useMemo(() => results, [results]);

  const handleSelectAllChange = () =>
    onSelectionChange(selection.length > 0 ? [] : enabledItems.map((item) => item.id));

  return (
    <>
      <Level>
        <Level.Item>
          <div style={{ marginBottom: '1rem', display: 'flex', position: 'relative', fontSize: '.875rem' }}>
            <Checkbox
              label="Show notes"
              checked={showNotes}
              onChange={(e) => {
                setShowNotes(e.target.checked);
              }}
            />
          </div>
        </Level.Item>

        <Level.Item>
          <Table.Status>
            {!!action && <Spinner />}
            <Table.Total value={results.length} label="Expense Items" />
          </Table.Status>
        </Level.Item>
      </Level>

      <List>
        <ListHeader>
          <Cell $width="3rem" padding="0.7rem 2rem 0.7rem 1.125rem">
            <SelectAll>
              <Checkbox
                checked={selection.length > 0}
                partial={selection.length < enabledItems.length}
                disabled={isSubmitting || enabledItems.length === 0 || action === 'filter' || !isApproverQueue}
                onChange={handleSelectAllChange}
              />
              {!isApproverQueue && <ApproverQueueTooltip />}
            </SelectAll>
          </Cell>

          <Cell padding="0.875rem 1rem">Member</Cell>

          <Cell right $width="5rem" padding="0.875rem 1rem">
            # of Items
          </Cell>

          <Cell right $width="7rem" padding="0.875rem 1rem">
            Client Billable
          </Cell>

          <Cell right $width="8.25rem" padding="0.875rem 1rem">
            Client
            <br />
            Non-billable
          </Cell>

          <Cell right $width="7rem" padding="0.875rem 1rem">
            Internal
          </Cell>

          <Cell right $width="7rem" padding="0.875rem 1rem">
            Total
          </Cell>

          <Cell $width="8rem" style={{ justifyContent: 'flex-end' }} padding="0.875rem 1rem">
            {!isApproverQueue && (
              <ApproverQueueWarning>
                <Icon icon="warning" color={colors.warning} />
                <ApproverQueueTooltip />
              </ApproverQueueWarning>
            )}
          </Cell>
        </ListHeader>

        {results.length === 0 && (
          <Table.Row>
            <NoResultsCell style={{ fontSize: '0.875rem' }} fade={action === 'filter'}>
              No results
            </NoResultsCell>
          </Table.Row>
        )}

        {_.map(groups, (group, key) => {
          return (
            <Group
              key={key}
              group={group}
              selection={selection}
              isSubmitting={isSubmitting}
              isApproverQueue={isApproverQueue}
              onSelectionChange={onSelectionChange}
              onAction={onGroupAction}
              action={action}>
              {group.items.map((item) => {
                const checked = !!selection?.includes(item.id);

                const submitting =
                  (isSubmitting === 'batch' && selection?.includes(item.id)) ||
                  isSubmitting?.group?.items.some((ei) => ei.id === item.id) ||
                  isSubmitting === item.id;

                const pendingApproval = item.statusId === 'pending_approval';

                const disableActions = submitting || !pendingApproval || !isApproverQueue;

                const handleRowClick = () => {
                  onResultClick(item, 'edit');
                };

                const handleSelectionChange = () =>
                  onSelectionChange(checked ? selection.filter((id) => id !== item.id) : [...selection, item.id]);

                const handleActionClick = (statusId) => {
                  onStatusChange(item, statusId);
                };

                return (
                  <Row key={item.id} data-testid="row" clickable={!!handleRowClick} onClick={handleRowClick}>
                    <Cell
                      flex="0"
                      $width="2.5rem"
                      padding="0.5rem 0rem 0.5rem 3.5rem"
                      style={{ position: 'relative' }}
                      onClick={(e) => e.stopPropagation()}>
                      <Checkbox checked={checked} disabled={disableActions} onChange={handleSelectionChange} />
                    </Cell>

                    <Cell $width="7.5rem">
                      <DateTime value={item.date} />
                    </Cell>

                    <Cell>
                      <Details>
                        <div>
                          {item.project && (
                            <p>
                              {item.project.name}
                              <Byline>{item.project.client.name}</Byline>
                            </p>
                          )}
                        </div>

                        <div>
                          {item.vendorName && <p>{item.vendorName}</p>}

                          {item.expenseCategory && <p>{item.expenseCategory.name}</p>}
                        </div>
                      </Details>

                      {showNotes && item.notes && (
                        <Notes>
                          <em>{item.notes}</em>
                        </Notes>
                      )}
                    </Cell>

                    <Cell right $width="8rem">
                      <ExpenseAmountWidget item={item} showApprovalActions onApprovalChange={onChange} />
                    </Cell>

                    <Cell right $width="8rem">
                      <ApprovalActions onClick={(e) => e.stopPropagation()}>
                        <ApprovalAction>
                          <Approve
                            disabled={disableActions}
                            status={item.statusId}
                            onClick={() => handleActionClick('approved')}>
                            <Icon icon="thumbs-up" />
                            {!isApproverQueue && <ApproverQueueTooltip />}
                          </Approve>
                        </ApprovalAction>
                        <ApprovalAction>
                          <Reject
                            disabled={disableActions}
                            status={item.statusId}
                            onClick={() => handleActionClick('rejected')}>
                            <Icon icon="thumbs-down" />
                            {!isApproverQueue && <ApproverQueueTooltip />}
                          </Reject>
                        </ApprovalAction>
                      </ApprovalActions>
                    </Cell>
                  </Row>
                );
              })}
            </Group>
          );
        })}
      </List>
    </>
  );
}

const sum = (item) => _.round(item.convertedAmount, 2);

function Group({ group, selection, isSubmitting, isApproverQueue, children, onSelectionChange, onAction, action }) {
  const [collapsed, setCollapsed] = useState(true);

  const amount = useMemo(() => {
    return {
      clientBillable: _.round(
        _(group.items)
          .filter((item) => item.project?.client?.isInternal === false && item.isBillable === true)
          .sumBy(sum),
        2,
      ),
      clientNonbillable: _.round(
        _(group.items)
          .filter((item) => item.project?.client?.isInternal === false && item.isBillable === false)
          .sumBy(sum),
        2,
      ),
      internal: _.round(
        _(group.items)
          .filter((item) => !item.project || item.project?.client?.isInternal === true)
          .sumBy(sum),
        2,
      ),
      total: _.round(_(group.items).sumBy(sum), 2),
    };
  }, [group]);

  const enabledItems = useMemo(() => group.items, [group.items]);

  const groupSelection = useMemo(() => {
    return selection?.filter((id) => group.items.some((item) => item.id === id));
  }, [group, selection]);

  const groupSelected = useMemo(() => {
    return group.items.some((e) => groupSelection.includes(e.id));
  }, [group, groupSelection]);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const handleGroupSelectionChange = () => {
    onSelectionChange(
      groupSelected
        ? selection.filter((id) => !group.items.map(({ id }) => id).includes(id))
        : [...selection, ...enabledItems.map(({ id }) => id)],
    );
  };

  const status = useMemo(() => {
    return {
      hasApproved: _.some(enabledItems, (item) => item.statusId === 'approved'),
      hasRejected: _.some(enabledItems, (item) => item.statusId === 'rejected'),
      hasPending: _.some(enabledItems, (item) => item.statusId === 'pending_approval'),
      hasNotSubmitted: _.some(enabledItems, (entry) => entry.statusId === 'not_submitted'),

      pendingApproval: _.every(enabledItems, (entry) => entry.statusId === 'pending_approval'),

      get approved() {
        if (!this.hasApproved) return null;
        if (this.hasApproved && !this.hasRejected && !this.hasPending && !this.hasNotSubmitted) return 'approved';
        return 'partially_approved';
      },

      get rejected() {
        if (!this.hasRejected) return null;
        if (this.hasRejected && !this.hasApproved && !this.hasPending && !this.hasNotSubmitted) return 'rejected';
        return 'partially_rejected';
      },
    };
  }, [enabledItems]);

  const handleActionClick = (statusId) => {
    onAction({ ...group, items: enabledItems.filter((e) => e.statusId === 'pending_approval') }, statusId);
  };

  const submitting = isSubmitting?.group?.member.id === group.member.id || (isSubmitting === 'batch' && groupSelected);

  const disableActions = submitting || enabledItems.length === 0 || !status.pendingApproval || !isApproverQueue;

  return (
    <ListGroup fade={action === 'filter'}>
      <ListGroupHeader data-testid="group" onClick={handleToggleClick}>
        <Cell flex="0" padding="0.5rem 0.25rem 0.5rem 0.75rem">
          <ToggleSwitch>
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Cell>

        <Cell flex="0" padding="0" style={{ position: 'relative' }} onClick={(event) => event.stopPropagation()}>
          <Checkbox
            disabled={disableActions}
            checked={groupSelected}
            partial={groupSelection.length < enabledItems.length}
            onChange={handleGroupSelectionChange}
          />
          {!isApproverQueue && <ApproverQueueTooltip />}
        </Cell>

        <Cell>{group.member.name}</Cell>

        <Cell right $width="5rem">
          {group.items.length}
        </Cell>

        <Cell right color={colors.success} $width="7rem">
          <Currency value={amount.clientBillable} />
        </Cell>

        <Cell right color={colors.danger} $width="8.25rem">
          <Currency value={amount.clientNonbillable} />
        </Cell>

        <Cell right color={colors.warning} $width="7rem">
          <Currency value={amount.internal} />
        </Cell>

        <Cell right $width="7rem">
          <Currency value={amount.total} />
        </Cell>

        <Cell right $width="8rem">
          <ApprovalActions onClick={(e) => e.stopPropagation()}>
            <ApprovalAction>
              <Approve disabled={disableActions} status={status.approved} onClick={() => handleActionClick('approved')}>
                <Icon icon="thumbs-up" />
              </Approve>
            </ApprovalAction>
            <ApprovalAction>
              <Reject disabled={disableActions} status={status.rejected} onClick={() => handleActionClick('rejected')}>
                <Icon icon="thumbs-down" />
              </Reject>
            </ApprovalAction>
          </ApprovalActions>
        </Cell>
      </ListGroupHeader>

      {!collapsed && <ListGroupContent>{children}</ListGroupContent>}
    </ListGroup>
  );
}

function ApproverQueueTooltip() {
  return <InlineTooltip message="Select an Approver to enable approval actions." />;
}

export default ExpenseApprovalResults;
