import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage } from '~/components';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import moment from 'moment';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import { dateFormats, emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

function ExpenseReportForm({ expenseReport, status, message, member, isSubmitting, onSubmit, onClose }) {
  const isNew = !expenseReport || !expenseReport.id;

  useDocumentTitle(isNew ? 'New Expense Report' : `Edit ${expenseReport.title}`);

  const { workspace } = useWorkspace();
  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const title = isNew ? 'New Report' : 'Edit Report';

  const initialValues = mergeValues(
    {
      title: '',
      date: moment().format(dateFormats.isoDate),
      notes: '',
    },
    expenseReport,
  );

  const isImpersonation = member && member.id !== workspace.member.id;

  return (
    <Drawer
      isOpen
      title={title}
      byline="Expenses"
      onOpen={() => isNew && firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());
        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values) => {
              onSubmit(values, closeDrawer);
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              title: Yup.string().label('Title').max(255).required(),
              date: Yup.date()
                .label('Date')
                .nullable()
                .required()
                .test({
                  message: `You cannot create an expense report with a date that is this far in the past.`,
                  test: (value) => {
                    if (
                      (member || workspace.member).permissions.manageTimeAndExpenses ||
                      !workspace.lockTimeAndExpenses
                    )
                      return true;
                    const age = moment.duration(moment().diff(moment(value), 'days'), 'days').asDays();
                    return age <= workspace.lockTimeAndExpensesAfterDays;
                  },
                })
                .test({
                  message: `You cannot create an expense report with a date that is this far in the past.`,
                  test: (value) => {
                    if (
                      (member || workspace.member).permissions.manageTimeAndExpenses ||
                      !workspace.lockTimeAndExpensesAfterMonthEnds
                    )
                      return true;

                    return !moment(value)
                      .startOf('month')
                      .isSameOrBefore(
                        moment()
                          .subtract(
                            moment().date() >= workspace.lockTimeAndExpensesAfterMonthEndsDays ? 1 : 2,
                            'months',
                          )
                          .startOf('month'),
                      );
                  },
                }),
              notes: Yup.string().label('Notes').max(5000),
            })}>
            {() => {
              return (
                <Form>
                  <Form.Section title="Expense Report Details">
                    {isImpersonation && (
                      <Form.Control>
                        <ReadTextbox label="Member" value={member.name} />
                      </Form.Control>
                    )}
                    <Form.Control>
                      <Field.Text ref={firstFieldRef} name="title" placeholder="Title" maxLength={255} />
                    </Form.Control>
                    <Form.Control>
                      <Field.DayPicker name="date" placeholder="Date" />
                    </Form.Control>
                    <Form.Control>
                      <Field.TextArea name="notes" placeholder="Notes" maxLength={5000} />
                    </Form.Control>
                    <Form.Control></Form.Control>
                  </Form.Section>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} type="submit">
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

const initialState = { expenseReport: null, isReady: false };
const handlers = {
  ready: ({ expenseReport }) => ({ isReady: true, expenseReport }),
};
export default function ({ onSaved, onClose, member }) {
  const api = useApi();
  const history = useHistory();
  const { workspace } = useWorkspace();
  const [{ isReady, expenseReport }, actions] = useActions(handlers, initialState);
  const [formState, form] = useForm();
  const { number } = useParams();

  const fetchData = useCallback(async () => {
    if (!number) {
      actions.ready({ expenseReport: {} });
      return;
    }
    try {
      const { data: expenseReport } = await api.www.workspaces(workspace.id).expenseReports(number).get({ op: 'edit' });
      actions.ready({ expenseReport });
    } catch (error) {
      actions.ready({ expenseReport: null });
    }
  }, [actions, workspace.id, number, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSubmit(values, closeDrawer) {
    try {
      form.submit();
      const body = emptyStringToNull(values);

      if (!expenseReport?.id && member) body.memberId = member.id;
      const isNew = !expenseReport?.id;
      const { data } = await api.www.workspaces(workspace.id).expenseReports(expenseReport?.id).upsert(body);
      form.done();
      await onSaved(data);

      if (isNew) {
        history.push(`/app/${workspace.key}/expenses/reports/${data.number}`);
      } else closeDrawer();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  if (!isReady) return null;
  if (!expenseReport) return <ErrorPage.NotFound publicSite={false} />;
  return (
    <ExpenseReportForm
      member={member}
      expenseReport={expenseReport}
      onSubmit={handleSubmit}
      onClose={onClose}
      {...formState}
    />
  );
}

export { ExpenseReportForm };
