import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, del, patch } from './agent';

const projectHealthReports =
  (projectHealthUrl) =>
  (id = '') => {
    const url = _([projectHealthUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
    };
  };

export default projectHealthReports;
