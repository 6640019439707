import {
  Button,
  Buttons,
  CancelButton,
  Currency,
  DateTime,
  DeleteConfirmation,
  Field,
  Form,
  HelpTooltip,
  Icon,
  Level,
  ModalCard,
  Table,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { ProjectDrawerContext } from '../ProjectDrawer';
import { Header } from './Header';
import { TableTitle } from './TableTitle';

const InvoicedIcon = styled(Icon)`
  color: ${colors.primary};
`;

const ContainerControl = styled.span`
  display: flex;
  align-items: center;
`;

function InvoiceMilestonesTable({ projectModel, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  const confirmation = useConfirmation();

  const handleSave = (value) => {
    const milestones = value.id
      ? projectModel.invoiceMilestones.map((bm) => (bm.id === value.id ? { ...bm, ...value } : bm))
      : [...projectModel.invoiceMilestones, { ...value, id: _.uniqueId('im_') }];

    setEditIndex(null);
    onChange(milestones);
  };

  const handleDelete = (item) => {
    onChange(projectModel.invoiceMilestones.filter((r) => r.id !== item.id));
  };

  const handleAddMultiple = async () => {
    await confirmation.prompt((resolve) => {
      const handleSubmit = (values) => {
        const otherItems = [
          ...projectModel.invoiceMilestones,
          ...values.map((im) => ({ ...im, id: _.uniqueId('im_') })),
        ];
        onChange(otherItems);
        resolve(true);
      };

      return (
        <MultipleInvoiceMilestonesForm
          project={projectModel}
          currency={currency}
          onSubmit={handleSubmit}
          onCancel={() => resolve(false)}
        />
      );
    });
  };

  const total = _.sumBy(projectModel.invoiceMilestones, 'fee');

  const currency = projectModel.currency;

  return (
    <>
      <Header>
        <Level>
          <Level.Item>
            <TableTitle>
              Fixed Fee Billing Schedule
              <HelpTooltip
                message="Schedule the billing of the fixed fee project revenue below."
                style={{ marginLeft: '0.5rem' }}
              />
            </TableTitle>
          </Level.Item>
          <Level.Item right narrow>
            <Button isOutline onClick={handleAddMultiple}>
              Add Multiple
            </Button>
          </Level.Item>
        </Level>
      </Header>
      <Form.Control>
        <Table small>
          <Table.BoxHeader>
            <Table.Column width="10.5rem">Bill Date</Table.Column>
            <Table.Column>Milestone Description</Table.Column>
            <Table.Column width="11rem" align="right">
              Amount
            </Table.Column>
            <Table.Column width="2rem" />
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {projectModel.invoiceMilestones.map((item, index) => (
              <InvoiceMilestoneRow
                key={item.id}
                currency={currency}
                invoiceMilestone={item}
                disableActions={editIndex !== null}
                isEditing={editIndex === index}
                onEdit={() => setEditIndex(index)}
                onCancel={() => setEditIndex(null)}
                onSaved={handleSave}
                onDeleted={handleDelete}
              />
            ))}

            <InvoiceMilestoneRow
              currency={currency}
              invoiceMilestone={{}}
              isEditing={editIndex === -1}
              disableActions={editIndex !== null}
              onEdit={() => setEditIndex(-1)}
              onCancel={() => setEditIndex(null)}
              onSaved={handleSave}
            />

            <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
              <Table.Cell style={{ flex: 2 }}>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell>
                <Currency value={total} currency={currency} />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Body>
        </Table>
      </Form.Control>
    </>
  );
}

function InvoiceMilestoneRow({
  invoiceMilestone,
  disableActions,
  isEditing,
  currency,
  onEdit,
  onCancel,
  onSaved,
  onDeleted,
}) {
  async function handleSubmit(values) {
    onSaved(values);
  }

  async function handleDelete() {
    onDeleted(invoiceMilestone);
  }
  if (!isEditing)
    return (
      <InvoiceMilestoneRowDetails
        invoiceMilestone={invoiceMilestone}
        disableActions={disableActions}
        currency={currency}
        onEdit={onEdit}
        onDelete={handleDelete}
      />
    );

  return (
    <InvoiceMilestoneRowForm
      invoiceMilestone={invoiceMilestone}
      currency={currency}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
}

function InvoiceMilestoneRowDetails({
  invoiceMilestone: { id, date, details, fee, invoice },
  disableActions,
  currency,
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  if (!id)
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  // Note: it was agreed that milestones associated with draft invoices can be deleted and modified without restrictions
  const isInvoiced = invoice && invoice.statusId !== 'draft';
  const areActionsDisabled = isInvoiced || disableActions;
  const tooltip = areActionsDisabled ? "Invoiced milestones can't be modified." : undefined;

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this invoice milestone?</DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow>
      <Table.Cell>
        <DateTime value={date} />
      </Table.Cell>
      <Table.Cell>{details}</Table.Cell>
      <Table.Cell>
        <Currency value={fee} currency={currency} />
      </Table.Cell>
      <Table.Cell style={{ padding: 3 }}>
        {isInvoiced && (
          <Tooltip message="An invoice has been published for this milestone.">
            <InvoicedIcon icon="file-invoice-dollar" />
          </Tooltip>
        )}
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={areActionsDisabled} tooltip={tooltip} onClick={onEdit} />
        <hr />
        <TableBoxRowActions.Delete disabled={areActionsDisabled} tooltip={tooltip} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function InvoiceMilestoneRowForm({ invoiceMilestone, currency, onSubmit, onCancel }) {
  // Submit the row if the drawer's "Save" button is clicked
  const form = useRef();
  const { setForms } = useContext(ProjectDrawerContext);

  useEffect(() => {
    // Register the inline form
    setForms((forms) => [...forms, { id: 'invoice_milestone', ref: form }]);

    return () => {
      // Unregister the inline form
      setForms((forms) => forms.filter((f) => f.id !== 'invoice_milestone'));
    };
  }, [setForms]);

  const initialValues = mergeValues(
    {
      id: null,
      date: null,
      details: '',
      fee: '',
      type: 'custom',
    },
    invoiceMilestone,
  );

  return (
    <Formik
      innerRef={form}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        date: Yup.date().label('Date').nullable().required(),
        details: Yup.string().label('Invoice Milestone').max(255).required(),
        fee: Yup.number().label('Fee').min(0).max(99999999999).nullable().required(),
      })}>
      {({ submitForm }) => {
        return (
          <Table.BoxRow focused onEnter={submitForm}>
            <Table.Cell>
              <Field.DayPicker
                autoFocus
                name="date"
                materialPlaceholder="Bill Date"
                materialAlwaysVisible
                clearable={false}
              />
            </Table.Cell>
            <Table.Cell>
              <Field.Text
                name="details"
                materialPlaceholder="Invoice Milestone"
                materialAlwaysVisible
                maxLength={255}
                style={{ width: '34rem' }}
              />
            </Table.Cell>
            <Table.Cell>
              <Field.Currency name="fee" materialPlaceholder="Fee" materialAlwaysVisible currency={currency} />
            </Table.Cell>
            <TableBoxRowActions.Form onSubmit={submitForm} onCancel={onCancel} />
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

function MultipleInvoiceMilestonesForm({ currency, project, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const initialValues = {
    start: null,
    end: null,
    dayOfMonth: null,
    description: '',
    includeDate: true,
    fee: '',
  };

  const handleSubmit = async (values) => {
    const { start, end, dayOfMonth, description, includeDate, fee } = emptyStringToNull(values);

    const { data: dates } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .generateRecurringDates({ start, end, dayOfMonth });

    const instances = dates.map((date) => {
      const mDate = moment(date);
      return {
        type: 'recurring',
        date: mDate.format(dateFormats.isoDate),
        details: includeDate ? `${description} - ${mDate.format('MMMM YYYY')}` : description,
        fee,
      };
    });

    onSubmit(instances);
  };

  return (
    <ModalCard title="Add Multiple Invoice Milestones" onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          start: Yup.date().label('Starting Month').nullable().required(),
          end: Yup.date()
            .label('Ending Month')
            .nullable()
            .min(Yup.ref('start'), 'Ending Month must be after Starting Month')
            .required(),
          dayOfMonth: Yup.mixed()
            .label('Day of Month')
            .oneOf([...Array(28).keys()].map((i) => i + 1).concat('last'))
            .required(),
          description: Yup.string().label('Description').max(200).required(),
          fee: Yup.number().label('Fee').min(0).max(99999999999).required(),
        })}>
        {({ submitForm }) => {
          return (
            <>
              <ModalCard.Body>
                <Form.Control>
                  <Field.DayPicker
                    name="start"
                    placeholder="Starting Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                  <Field.DayPicker
                    name="end"
                    placeholder="Ending Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                </Form.Control>

                <Form.Control>
                  <ContainerControl style={{ marginTop: '0' }}>
                    Scheduled on the
                    <div style={{ margin: '0 0.5rem' }}>
                      <Field.SingleSelect
                        name="dayOfMonth"
                        style={{ width: '7rem', margin: '0 0.5rem', textAlign: 'right' }}
                        materialPlaceholder={false}>
                        {[...Array(28).keys()].map((day) => (
                          <option key={day} value={day + 1}>
                            {day + 1}
                            {{
                              1: 'st',
                              2: 'nd',
                              3: 'rd',
                            }[day + 1] || 'th'}
                          </option>
                        ))}
                        <option value="last">Last</option>
                      </Field.SingleSelect>
                    </div>
                    <span>day of each month</span>
                  </ContainerControl>
                </Form.Control>

                <Form.Control>
                  <Field.Text name="description" placeholder="Description" maxLength={200} />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox name="includeDate" label="Append the month and year to the description" />
                </Form.Control>

                <Form.Control>
                  <Field.Currency name="fee" placeholder="Amount" currency={currency} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Cancel</CancelButton>
                  <Button onClick={submitForm}>Add</Button>
                </Buttons>
              </ModalCard.Footer>
            </>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default InvoiceMilestonesTable;
