import { Button, DeleteConfirmation, Dropdown, Field, Icon, Percent } from '~/components';
import { Form, Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useEditableRow, useForm } from '~/hooks';
import React from 'react';
import styled from 'styled-components';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';
import { DaysInPeriod, PeriodDateRange, PeriodDateRangeField } from '../components';
import { periodStartDateValidator } from '../utils';
import { colors } from '~/styles';

const ProgressLine = styled.div`
  display: block;
  width: ${({ width }) => width ?? '100%'};
  height: 0.5rem;
  background-color: ${({ color }) => color ?? colors.grey5};
  border-radius: 999rem;
  overflow: hidden;
  transition: width 0.5s ease-out;
`;

const Progress = styled(ProgressLine)`
  margin: 0.25rem 0;
  width: 10.8125rem;
`;

function UtilizationTargetPeriodRow({
  member,
  period,
  periods,
  disableActions,
  isEditing,
  onEdit,
  onCancel,
  onSaved,
  onDeleted,
}) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const [rowState, row] = useEditableRow();
  const [formState, form] = useForm();

  async function handleSubmit(values) {
    try {
      form.submit();
      const body = emptyStringToNull(values);
      const { data } = await api.www
        .workspaces(workspace.id)
        .members(member.id)
        .utilizationTargetPeriods(period ? period.id : undefined)
        .upsert(body);
      await onSaved(data);
      form.done();
      row.save();
    } catch ({ message }) {
      form.error({ message });
      toast.error(message);
    }
  }

  async function handleDelete() {
    try {
      await api.www
        .workspaces(workspace.id)
        .members(member.id)
        .utilizationTargetPeriods(period ? period.id : undefined)
        .delete();
      await onDeleted(period.id);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const isDefault = period.id && !period.start;

  if (!isEditing)
    return (
      <UtilizationTargetPeriodRowDetails
        period={period}
        member={member}
        saved={rowState.saved}
        disableActions={disableActions}
        onEdit={onEdit}
        onDelete={handleDelete}
        isDefault={isDefault}
      />
    );

  return (
    <UtilizationTargetPeriodRowForm
      period={period}
      periods={periods}
      member={member}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isDefault={isDefault}
      {...formState}
    />
  );
}

const ProgressContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    padding-left: 1.125rem;
    text-align: right;
  }
`;

function UtilizationTargetPeriodRowDetails({
  period,
  period: { id, targetPercentage },
  member,
  isDefault,
  saved,
  disableActions,
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  if (!id)
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this utilization target period?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow>
      <Table.Cell>
        <PeriodDateRange period={period} member={member} />
      </Table.Cell>
      <Table.Cell>
        {targetPercentage > 0 && (
          <>
            <ProgressContainer>
              <Progress>
                <ProgressLine width={`${Math.min(targetPercentage, 100)}%`} color={colors.primary} />
              </Progress>
              <span>
                <Percent value={targetPercentage / 100} minimumFractionDigits={0} />
              </span>
            </ProgressContainer>
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        <DaysInPeriod period={period} member={member} />
      </Table.Cell>
      <TableBoxRowActions>
        {saved ? (
          <TableBoxRowActions.Success />
        ) : (
          <>
            <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />

            <hr />

            <TableBoxRowActions.Dropdown disabled={disableActions}>
              <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
              <Dropdown.Item
                disabled={isDefault}
                tooltip={isDefault ? 'The default period cannot be deleted.' : undefined}
                onClick={handleDelete}>
                Delete
              </Dropdown.Item>
            </TableBoxRowActions.Dropdown>
          </>
        )}
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

const FormProgressContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  && > .field {
    padding-left: 0.5rem;
    flex: 0;

    input {
      width: 5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
`;

function UtilizationTargetPeriodRowForm({ period, periods, isSubmitting, onSubmit, onCancel, member, isDefault }) {
  const initialValues = {
    // Only the default period may have a null start date
    start: period.start === undefined ? new Date() : period.start,
    // Set the default hours per day based on the workspace's business days
    targetPercentage: period.targetPercentage || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        targetPercentage: Yup.number().label('Target').min(0).max(999.99).nullable(),
        start: periodStartDateValidator({ periods, period, member }),
      })}>
      {({ values }) => {
        return (
          <Table.BoxRow focused>
            <Form>
              <Table.Cell>
                <PeriodDateRangeField
                  autoFocus={!period.id}
                  periods={periods}
                  period={period}
                  member={member}
                  start={values.start}
                  isDefault={isDefault}
                />
              </Table.Cell>
              <Table.Cell>
                <FormProgressContainer>
                  <Progress>
                    <ProgressLine width={`${Math.min(values.targetPercentage, 100)}%`} color={colors.primary} />
                  </Progress>
                  <Field.Number
                    autoFocus={!!period.id}
                    name="targetPercentage"
                    placeholder="%"
                    min={0}
                    max={999.99}
                    precision={2}
                  />
                </FormProgressContainer>
              </Table.Cell>
              <Table.Cell>
                <DaysInPeriod periods={periods} period={period} member={member} start={values.start} />
              </Table.Cell>
              <TableBoxRowActions.Form submit isLoading={isSubmitting} onCancel={onCancel} />
            </Form>
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

export default UtilizationTargetPeriodRow;
