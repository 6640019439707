import styled from 'styled-components';
import { Icon } from '~/components'; // Ensure this path is correct
import { colors } from '~/styles';

const BaseContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  background-color: ${colors.white};
  color: ${colors.grey100};

  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    list-style-type: disc;
  }

  li {
    margin-bottom: 0.25rem;
  }
`;

export const DetailedValidationResults = styled(BaseContainer)`
  border: 1px solid ${colors.accent}; /* Accent color for error/validation messages */
`;

export const ProgressWrapper = styled(BaseContainer)`
  border: 1px solid ${colors.primary50}; /* Primary color for progress indication */
`;

export const ValidationSummary = styled.div`
  margin-top: 1rem;
`;

export const ResultMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    list-style-type: disc;
  }

  li {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
`;

export const StepTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .icon {
    margin-right: 0.5rem; // Added spacing to the right of the icon
  }

  strong {
    margin-right: 0.5rem;
  }
`;

export const LoadingIcon = styled(Icon)`
  display: flex;
  font-size: 1rem;
`;
