import React from 'react';
import styled from 'styled-components';
import Tooltip from './Tooltip';
import { colors } from '~/styles';

const ScoreBarContainer = styled.div`
  min-width: 100%;
`;

const Meter = styled.div`
  display: flex;
  min-height: 0.75rem;
  background: ${colors.white};
  border: 1px solid ${colors.grey10};
  position: relative;
  border-radius: 0.5rem;
  padding: 0.125rem;
  min-width: 100%;
`;

const InsideBar = styled.div`
  min-width: 0.1rem;
  border-radius: 0.25rem;
  width: ${({ width }) => width};
  background: ${({ color }) => color};
`;

export default function ProjectHealthScoreBar({ score }) {
  const percentScore = (100 * (score / 12)).toFixed(2) + '%';
  const scoreColor = score > 10 ? colors.green : score < 9 ? colors.red : colors.yellow;

  return (
    <ScoreBarContainer data-testid="health_bar">
      <Tooltip message={<div>{score} out of 12</div>}>
        <Meter>
          <InsideBar width={percentScore} color={scoreColor}></InsideBar>
        </Meter>
      </Tooltip>
    </ScoreBarContainer>
  );
}
