import moment from 'moment';
import { dateFormats } from '~/utils';

const approvalIntervalOptions = {
  get since_7_days_ago() {
    return {
      key: 'since_7_days_ago',
      label: 'Everything since 7 days ago',
      start: moment().subtract(7, 'days').format(dateFormats.isoDate),
      end: null,
      unit: 'custom',
    };
  },
  get since_30_days_ago() {
    return {
      key: 'since_30_days_ago',
      label: 'Everything since 30 days ago',
      start: moment().subtract(30, 'days').format(dateFormats.isoDate),
      end: null,
      unit: 'custom',
    };
  },
  get since_45_days_ago() {
    return {
      key: 'since_45_days_ago',
      label: 'Everything since 45 days ago',
      start: moment().subtract(45, 'days').format(dateFormats.isoDate),
      end: null,
      unit: 'custom',
    };
  },
  get since_60_days_ago() {
    return {
      key: 'since_60_days_ago',
      label: 'Everything since 60 days ago',
      start: moment().subtract(60, 'days').format(dateFormats.isoDate),
      end: null,
      unit: 'custom',
    };
  },
  get since_90_days_ago() {
    return {
      key: 'since_90_days_ago',
      label: 'Everything since 90 days ago',
      start: moment().subtract(90, 'days').format(dateFormats.isoDate),
      end: null,
      unit: 'custom',
    };
  },
  get since_180_days_ago() {
    return {
      key: 'since_180_days_ago',
      label: 'Everything since 180 days ago',
      start: moment().subtract(180, 'days').format(dateFormats.isoDate),
      end: null,
      unit: 'custom',
    };
  },
  get since_365_days_ago() {
    return {
      key: 'since_365_days_ago',
      label: 'Everything since 365 days ago',
      start: moment().subtract(365, 'days').format(dateFormats.isoDate),
      end: null,
      unit: 'custom',
    };
  },
};

export default approvalIntervalOptions;
