import { DateTime, Form, Icon } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const HistoryItem = styled.div`
  position: relative;
  margin-left: 1.25rem;
  padding-left: 3.75rem;
  padding-bottom: 2rem;
  border-left: solid 2px ${colors.grey10};

  &:last-child {
    padding-bottom: 0;
    border-color: transparent;
  }
`;

const HistoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: -1.3125rem;
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  background-color: ${colors.white};
  border: solid 3px ${({ color }) => color};
  border-radius: 50%;
`;

const HistoryTitle = styled.div`
  font-weight: ${weights.bold};
`;

const HistoryDetails = styled.div`
  font-size: 0.875rem;
`;

const HistoryNotes = styled.div`
  margin-top: 0.75rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-style: italic;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

function TimeEntryHistoryIcon({ color = colors.black, size = '1rem', ...props }) {
  return (
    <HistoryIcon color={color} size={size}>
      <Icon {...props} />
    </HistoryIcon>
  );
}

export default function HistoryTab({ history }) {
  return (
    <div>
      <Form.Section title="Time Entry History">
        {history.map((item) => (
          <HistoryItem key={item.id}>
            {
              {
                created: <TimeEntryHistoryIcon icon="plus" color={colors.success} size="1rem" />,
                imported: <TimeEntryHistoryIcon icon="file-import" color={colors.black} size="1rem" />,
                updated: <TimeEntryHistoryIcon icon="sync" color={colors.black} size="0.875rem" />,
                submitted: <TimeEntryHistoryIcon icon="inbox-in" color={colors.warning} size="0.875rem" />,
                rejected: <TimeEntryHistoryIcon icon="times" color={colors.danger} size="1rem" />,
                approved: <TimeEntryHistoryIcon icon="check" color={colors.primary} size="0.875rem" />,
                auto_approved: <TimeEntryHistoryIcon icon="check" color={colors.primary} size="0.875rem" />,
                pending_approval: <TimeEntryHistoryIcon icon="sync" color={colors.warning} size="0.875rem" />,
                not_submitted: <TimeEntryHistoryIcon icon="sync" color={colors.black} size="0.875rem" />,
                client_approved: <TimeEntryHistoryIcon icon="check" color={colors.primary} size="0.875rem" />,
                client_rejected: <TimeEntryHistoryIcon icon="times" color={colors.danger} size="0.875rem" />,
                client_pending_approval: <TimeEntryHistoryIcon icon="sync" color={colors.warning} size="0.875rem" />,
                timesheet_submitted: <TimeEntryHistoryIcon icon="inbox-in" color={colors.primary} size="0.875rem" />,
                timesheet_unsubmitted: <TimeEntryHistoryIcon icon="inbox-out" color={colors.danger} size="0.875rem" />,
                approval_step_approved: <TimeEntryHistoryIcon icon="thumbs-up" color={colors.black} size="0.875rem" />,
                approval_step_rejected: (
                  <TimeEntryHistoryIcon icon="thumbs-down" color={colors.black} size="0.875rem" />
                ),
                approval_step_reset: <TimeEntryHistoryIcon icon="sync" color={colors.black} size="0.875rem" />,
              }[item.status.id]
            }
            <HistoryTitle>{item.status.name}</HistoryTitle>
            <HistoryDetails>
              <DateTime value={item.createdAt} format="l [at] LT" /> by {item.member ? item.member.name : 'Ruddr'} via{' '}
              {item.source.name}
            </HistoryDetails>
            {item.notes && <HistoryNotes>{item.notes}</HistoryNotes>}
          </HistoryItem>
        ))}
      </Form.Section>
    </div>
  );
}
