import { DateTime, Drawer, Icon } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const HistoryItem = styled.div`
  position: relative;
  margin-left: 1.25rem;
  padding-left: 3.75rem;
  padding-bottom: 2rem;
  border-left: solid 2px ${colors.grey10};

  &:last-child {
    padding-bottom: 0;
    border-color: transparent;
  }
`;

const HistoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: -1.3125rem;
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  background-color: ${colors.white};
  border: solid 3px ${({ color }) => color};
  border-radius: 50%;
`;

const HistoryTitle = styled.div`
  font-weight: ${weights.bold};
`;

const HistoryDetails = styled.div`
  font-size: 0.875rem;
`;

const HistoryNotes = styled.div`
  margin-top: 0.75rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-style: italic;
  white-space: pre-wrap;
`;

function CreditNoteHistoryIcon({ color = colors.black, size = '1rem', ...props }) {
  return (
    <HistoryIcon color={color} size={size}>
      <Icon {...props} />
    </HistoryIcon>
  );
}

export default function CreditNoteHistoryDrawer({ creditNoteId, onClose }) {
  const api = useApi();
  const [query, setQuery] = useState({ status: 'loading', data: null });
  const { workspace } = useWorkspace();

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNoteId).history();

    setQuery({ status: 'ready', data });
  }, [api, workspace.id, creditNoteId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Drawer isOpen title="Credit Note History" onClose={onClose}>
      {query.data?.map((item) => (
        <HistoryItem key={item.id}>
          {
            {
              created: <CreditNoteHistoryIcon icon="plus" color={colors.success} size="1rem" />,
              imported: <CreditNoteHistoryIcon icon="file-import" color={colors.black} />,
              updated: <CreditNoteHistoryIcon icon="sync" color={colors.black} size="0.875rem" />,
              published: <CreditNoteHistoryIcon icon="inbox-in" color={colors.primary} />,
              unpublished: <CreditNoteHistoryIcon icon="times" color={colors.danger} />,
              sent: <CreditNoteHistoryIcon icon="inbox-out" color={colors.primary} />,
              saved_to_qbo: <CreditNoteHistoryIcon icon="cloud-upload-alt" color={colors.primary} />,
              saved_to_xero: <CreditNoteHistoryIcon icon="cloud-upload-alt" color={colors.primary} />,
            }[item.status.id]
          }
          <HistoryTitle>{item.status.name}</HistoryTitle>

          <HistoryDetails>
            <DateTime value={item.eventTime} format="l [at] LT" /> by {item.member?.name ?? 'Ruddr'}
            {item.source.id !== 'system' && <> via {item.source.name}</>}
          </HistoryDetails>

          {item.notes && <HistoryNotes>{item.notes}</HistoryNotes>}
        </HistoryItem>
      ))}
    </Drawer>
  );
}
