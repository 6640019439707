import { Button, Currency, DeleteConfirmation, Dropdown, ExportDialog, Level, SearchInput, Table } from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import { ActiveStatusSelect, CustomDataHeader } from '../custom-data/components';
import ExportDropdown from '../ExportDropdown';
import ResourcePlaceholderDrawer from './ResourcePlaceholderDrawer';

function ResourcePlaceholdersPage() {
  const documentTitle = useDocumentTitle('Resource Placeholders');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();
  const features = useFeatures();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', isActive: 'true', size: 1000 });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).resourcePlaceholders().get(params);
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .resourcePlaceholders()
          .get(params, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  const handleEdit = async (resourcePlaceholder) => {
    setDialog(
      <ResourcePlaceholderDrawer
        resourcePlaceholder={resourcePlaceholder}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Resource Placeholders');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).resourcePlaceholders(item.id).setActiveStatus(flag);
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDelete(placeholder) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this Resource Placeholder?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .resourcePlaceholders(placeholder ? placeholder.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <CustomDataHeader>
        <CustomDataHeader.Details>
          <CustomDataHeader.Title>Resource Placeholders</CustomDataHeader.Title>
          <CustomDataHeader.Description>
            Within the Resources area of Ruddr, you can allocate members or resource placeholders to projects. Resource
            placeholders should be used when you aren't certain which team member will ultimately be assigned to the
            project.
          </CustomDataHeader.Description>
        </CustomDataHeader.Details>
        <CustomDataHeader.Buttons>
          <Button onClick={() => handleEdit({})}>New Resource Placeholder</Button>
        </CustomDataHeader.Buttons>
      </CustomDataHeader>
      <Level>
        <Level.Item width="20rem">
          <SearchInput
            value={params.q}
            placeholder="Search"
            materialPlaceholder="Name"
            materialAlwaysVisible
            onChange={handleFilterChange}
          />
        </Level.Item>
        <Level.Item width="20rem">
          <ActiveStatusSelect value={params.isActive} onChange={handleFilterChange} />
        </Level.Item>
        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('resource_placeholders.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('resource_placeholders.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
      </Level>
      <Table.Total value={data.length} label="Resource Placeholder" style={{ marginTop: '1rem' }} />
      <Table>
        <Table.BoxHeader>
          <Table.Column>Name</Table.Column>
          <Table.Column isVisible={features.practices}>Practice</Table.Column>
          <Table.Column>Location</Table.Column>
          <Table.Column isVisible={features.disciplines}>Discipline</Table.Column>
          <Table.Column align="center" width="8rem">
            Cost Currency
          </Table.Column>
          <Table.Column align="right" width="8rem">
            Average
            <br />
            Cost/HR
          </Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {data.map((placeholder) => {
            const { id, name, discipline, location, practice, isActive, costCurrency, costPerHour } = placeholder;

            return (
              <Table.BoxRow onClick={() => handleEdit(placeholder)} key={id} isDisabled={!isActive}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{practice?.name}</Table.Cell>
                <Table.Cell>{location?.name}</Table.Cell>
                <Table.Cell>{discipline?.name}</Table.Cell>
                <Table.Cell>{costCurrency}</Table.Cell>
                <Table.Cell>
                  <Currency value={costPerHour} maximumFractionDigits={7} currency={costCurrency} />
                </Table.Cell>
                <TableBoxRowActions>
                  <>
                    <TableBoxRowActions.Edit onClick={() => handleEdit(placeholder)} />

                    <hr />

                    <TableBoxRowActions.Dropdown>
                      {({ setIsOpen }) => (
                        <>
                          <Dropdown.Item onClick={() => handleEdit(placeholder)}>Edit</Dropdown.Item>
                          <Dropdown.Item
                            onClick={async () => {
                              await handleActiveStatusChange(placeholder, !isActive);
                              setIsOpen(false);
                            }}>
                            {isActive ? 'Deactivate' : 'Activate'}
                          </Dropdown.Item>
                          <Dropdown.DeleteItem
                            tooltip="This item is currently in use."
                            onCheckDependencies={async (workspace) =>
                              (await workspace.resourcePlaceholders(id).hasDependencies()).data
                            }
                            onClick={() => handleDelete(placeholder)}>
                            Delete
                          </Dropdown.DeleteItem>
                        </>
                      )}
                    </TableBoxRowActions.Dropdown>
                  </>
                </TableBoxRowActions>
              </Table.BoxRow>
            );
          })}
        </Table.Body>
      </Table>

      {dialog}
    </>
  );
}

export default ResourcePlaceholdersPage;
