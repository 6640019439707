import {
  Avatar,
  Button,
  DateTime,
  DeleteConfirmation,
  Dropdown,
  Level,
  MemberContactPopover,
  Page,
  ProjectHealthCircle,
  ProjectHealthScoreBar,
  Spinner,
  Stack,
  Table,
} from '~/components';
import styled from 'styled-components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import ProjectHealthReportForm from './ProjectHealthReportForm';
import ProjectHealthReportView from './ProjectHealthReportView';

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

export default function ProjectHealthTab({ project, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ data, isReady, isFetching }, setQuery] = useState({ data: null, isReady: false, isFetching: false });
  const { projectHealthReportId, action } = useParams();
  const history = useHistory();
  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).healthReports().get();
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  useEffect(() => {
    const redirect = () => {
      history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/health`);
    };

    switch (action) {
      case 'edit':
        setDialog(
          <ProjectHealthReportForm
            id={projectHealthReportId}
            projectId={project.id}
            onDeleted={() => {
              fetchData();
              onChange && onChange();
            }}
            onSaved={() => {
              fetchData();
              onChange && onChange();
            }}
            onClose={redirect}
          />,
        );
        break;

      case 'view':
        setDialog(<ProjectHealthReportView id={projectHealthReportId} projectId={project.id} onClose={redirect} />);
        break;

      default:
        setDialog(null);
        break;
    }
  }, [
    projectHealthReportId,
    action,
    project.id,
    fetchData,
    onChange,
    history,
    project.client.key,
    project.key,
    workspace.key,
  ]);

  const handleCreate = () => {
    setDialog(
      <ProjectHealthReportForm
        projectId={project.id}
        onClose={() => setDialog(null)}
        onSaved={() => {
          fetchData();
          onChange && onChange();
        }}
      />,
    );
  };

  return (
    <>
      <Stack margin="2.5rem 0 0">
        <Page.Section>
          <Level>
            <Level.Item right narrow>
              <Button onClick={handleCreate}>Create a Project Health Report</Button>
            </Level.Item>
          </Level>
        </Page.Section>
        {!isReady ? (
          <PageLoader />
        ) : !data ? (
          <ErrorPage.NotFound publicSite={false} />
        ) : (
          <Page.Section>
            <Table.Status>
              {isFetching && <Spinner />}
              <Table.Total label="Project Health Report" value={data.length} />
            </Table.Status>

            <Table data-testid="table">
              <Table.BoxHeader sticky>
                <Table.Column width="7rem">Date</Table.Column>

                <Table.Column align="center">Budget Health</Table.Column>

                <Table.Column align="center">Schedule Health</Table.Column>

                <Table.Column align="center">Client Satisfaction</Table.Column>

                <Table.Column align="center">Team Satisfaction</Table.Column>

                <Table.Column align="center" width="8rem">
                  Score
                </Table.Column>

                <Table.Column>Created By</Table.Column>

                <Table.BoxActionsColumn />
              </Table.BoxHeader>

              <Table.Body fade={isFetching}>
                {data.map((report) => {
                  const handleView = () => {
                    history.push(
                      `/app/${workspace.key}/projects/${project.client.key}/${project.key}/health/${report.id}/view`,
                    );
                  };

                  const handleEdit = () => {
                    history.push(
                      `/app/${workspace.key}/projects/${project.client.key}/${project.key}/health/${report.id}/edit`,
                    );
                  };

                  const handleDelete = async () => {
                    await confirmation.prompt((resolve) => (
                      <DeleteConfirmation
                        resolve={async (confirm) => {
                          if (!confirm) resolve(false);

                          await api.www.workspaces(workspace.id).projects(project.id).healthReports(report.id).delete();

                          await fetchData();
                          onChange && onChange();

                          resolve(true);
                        }}>
                        Are you sure you want to delete this project health report?
                      </DeleteConfirmation>
                    ));
                  };

                  return (
                    <Table.BoxRow
                      key={report.id}
                      data-testid="row"
                      onClick={project.permissions.edit ? handleEdit : handleView}>
                      <Table.Cell>
                        <DateTime value={report.date} />
                      </Table.Cell>

                      <Table.Cell>
                        <ProjectHealthCircle value={report.budgetHealth} />
                      </Table.Cell>

                      <Table.Cell>
                        <ProjectHealthCircle value={report.scheduleHealth} />
                      </Table.Cell>

                      <Table.Cell>
                        <ProjectHealthCircle value={report.clientSatisfaction} />
                      </Table.Cell>

                      <Table.Cell>
                        <ProjectHealthCircle value={report.teamSatisfaction} />
                      </Table.Cell>

                      <Table.Cell>
                        <ProjectHealthScoreBar score={report.score} />
                      </Table.Cell>

                      <Table.Cell>
                        <Member>
                          <MemberContactPopover member={report.createdBy} placement="left">
                            <Avatar value={report.createdBy} isCircle hasBackground initialsFontSize=".9rem" />
                          </MemberContactPopover>
                          <MemberInfo>{report.createdBy.name}</MemberInfo>
                        </Member>
                      </Table.Cell>

                      <TableBoxRowActions>
                        {project.permissions.edit ? (
                          <TableBoxRowActions.Edit onClick={handleEdit} />
                        ) : (
                          <TableBoxRowActions.View onClick={handleView} />
                        )}

                        <hr />

                        <TableBoxRowActions.Dropdown>
                          {({ setIsOpen }) => {
                            const handleAction = (action) => setIsOpen(false) || action;

                            return (
                              <>
                                <Dropdown.Item onClick={() => handleAction(handleView())}>View</Dropdown.Item>

                                <Dropdown.Item
                                  disabled={!project.permissions.edit}
                                  tooltip={
                                    !project.permissions.edit
                                      ? 'Insufficient permissions to edit this project health report.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(handleEdit())}>
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  disabled={!project.permissions.edit}
                                  tooltip={
                                    !project.permissions.edit
                                      ? 'Insufficient permissions to delete this project health report.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(handleDelete())}>
                                  Delete
                                </Dropdown.Item>
                              </>
                            );
                          }}
                        </TableBoxRowActions.Dropdown>
                      </TableBoxRowActions>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>
          </Page.Section>
        )}
      </Stack>

      {dialog}
    </>
  );
}
