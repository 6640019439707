import {
  BillableIcon,
  Button,
  Buttons,
  CancelButton,
  Currency,
  DateTime,
  DeleteConfirmation,
  Field,
  Form,
  HelpTooltip,
  Icon,
  Level,
  ModalCard,
  Table,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { Header } from './Header';
import { TableTitle } from './TableTitle';
import styled from 'styled-components';

const InvoicedIcon = styled(Icon)`
  color: ${colors.primary};
`;

const ContainerControl = styled.span`
  display: flex;
  align-items: center;
`;

function ProjectExpenses({ projectModel, onChange }) {
  const [formEntry, setEditForm] = useState(null);
  const handleAdd = () => setEditForm({});
  const handleEdit = (entry) => setEditForm(entry);
  const handleCancel = () => setEditForm(null);

  const handleSubmit = (value) => {
    value = emptyStringToNull(value);

    const expenses = value.id
      ? projectModel.projectExpenses.map((rre) => (rre.id === value.id ? { ...rre, ...value } : rre))
      : [...projectModel.projectExpenses, { ...value, id: _.uniqueId('pe_') }];

    onChange(expenses);
    handleCancel();
  };

  const handleDelete = (item) => {
    onChange(projectModel.projectExpenses.filter((r) => r.id !== item.id));
  };

  const total = _.round(_.sumBy(projectModel.projectExpenses, 'amount'), 2);

  const confirmation = useConfirmation();
  const currency = projectModel.currency;

  const handleAddMultiple = async () => {
    await confirmation.prompt((resolve) => {
      const handleSubmit = (values) => {
        const expenses = [...projectModel.projectExpenses, ...values.map((pe) => ({ ...pe, id: _.uniqueId('pe_') }))];
        onChange(expenses);
        resolve(true);
      };

      return (
        <MultipleProjectExpensesForm
          project={projectModel}
          currency={currency}
          onSubmit={handleSubmit}
          onCancel={() => resolve(false)}
        />
      );
    });
  };

  return (
    <>
      <Header>
        <Level>
          <Level.Item>
            <TableTitle>
              Expenses Ledger
              <HelpTooltip
                message="Schedule the recognition of project expenses below. These are expenses that are not reflected on a workspace member's expense report."
                style={{ marginLeft: '0.5rem' }}
              />
            </TableTitle>
          </Level.Item>

          <Level.Item right narrow>
            <Button isOutline onClick={handleAddMultiple}>
              Add Multiple
            </Button>
          </Level.Item>
        </Level>
      </Header>

      <Form.Control>
        <Table small>
          <Table.BoxHeader>
            <Table.Column width="8rem">Date</Table.Column>
            <Table.Column>Expense Category</Table.Column>
            <Table.Column>Notes</Table.Column>
            <Table.Column align="center" width="6rem">
              Billable
            </Table.Column>
            <Table.Column align="right" width="7rem">
              Amount
            </Table.Column>
            <Table.Column width="2rem" />
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {projectModel.projectExpenses
              .filter((item) => !item.isBillable || (item.isBillable && projectModel.isBillable))
              .map((item) => (
                <ProjectExpenseRow
                  key={item.id}
                  currency={currency}
                  projectExpense={item}
                  onEdit={() => handleEdit(item)}
                  onDelete={() => handleDelete(item)}
                />
              ))}

            <Table.Row>
              <Table.Cell>
                <Button isAnchor isStrong onClick={handleAdd}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Quick Add
                </Button>
              </Table.Cell>
            </Table.Row>

            <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
              <Table.Cell style={{ flex: 2 }}>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Currency value={total} currency={currency} />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Body>
        </Table>
      </Form.Control>

      {formEntry && (
        <ProjectExpenseForm
          project={projectModel}
          currency={currency}
          projectExpense={formEntry}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

function ProjectExpenseRow({
  currency,
  projectExpense: { date, expenseCategory, notes, amount, isBillable, invoice },
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  const isInvoiced = invoice && invoice.statusId !== 'draft';
  const areActionsDisabled = isInvoiced;
  const tooltip = areActionsDisabled ? "Invoiced expenses can't be modified." : undefined;

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this project expense?</DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow>
      <Table.Cell>
        <DateTime value={date} />
      </Table.Cell>
      <Table.Cell>{expenseCategory.name}</Table.Cell>
      <Table.Cell>{notes}</Table.Cell>
      <Table.Cell>
        <BillableIcon value={isBillable} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={amount} currency={currency} />
      </Table.Cell>
      <Table.Cell style={{ padding: 3 }}>
        {isInvoiced && (
          <Tooltip message="An invoice has been published for this project expense.">
            <InvoicedIcon icon="file-invoice-dollar" />
          </Tooltip>
        )}
      </Table.Cell>

      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={areActionsDisabled} tooltip={tooltip} onClick={onEdit} />

        <hr />

        <TableBoxRowActions.Delete disabled={areActionsDisabled} tooltip={tooltip} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function ProjectExpenseForm({ project, projectExpense, currency, onSubmit, onCancel }) {
  const initialValues = mergeValues(
    {
      amount: '',
      date: moment().format(dateFormats.isoDate),
      expenseCategory: null,
      id: null,
      isBillable: false,
      notes: '',
      vendor: '',
    },
    projectExpense,
  );

  const handleSubmit = (values) => {
    onSubmit(
      emptyStringToNull({
        ...values,
        expenseCategoryId: values.expenseCategory.id,
      }),
    );
  };

  return (
    <ModalCard title={initialValues.id ? 'Edit Project Expense' : 'Add Project Expense'} onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable().required(),
          date: Yup.date().label('Date').nullable().required(),
          expenseCategory: Yup.object().label('Expense Category').nullable().required(),
          notes: Yup.string().label('Notes').max(255),
          vendor: Yup.string().label('Vendor').max(255),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.DayPicker name="date" placeholder="Date" clearable={false} />
                </Form.Control>

                <Form.Control>
                  <Field.ExpenseCategorySelect name="expenseCategory" placeholder="Expense Category" allowNew />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox name="isBillable" label="Bill to client" disabled={!project.isBillable} />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="vendor" placeholder="Vendor" maxLength={255} />
                </Form.Control>

                <Form.Control>
                  <Field.Currency name="amount" placeholder="Amount" currency={currency} />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="notes" placeholder="Notes" maxLength={255} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Close</CancelButton>

                  <Button type="submit">Add</Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

function MultipleProjectExpensesForm({ project, currency, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const initialValues = {
    start: null,
    end: null,
    dayOfMonth: null,
    amount: '',
    expenseCategory: null,
    isBillable: false,
    notes: '',
    vendor: '',
  };

  const handleSubmit = async (values) => {
    const { start, end, dayOfMonth, amount, expenseCategory, isBillable, notes, vendor } = emptyStringToNull(values);

    const { data: dates } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .generateRecurringDates({ start, end, dayOfMonth });

    const instances = dates.map((date) => {
      return {
        date: moment(date).format(dateFormats.isoDate),
        amount,
        expenseCategoryId: expenseCategory?.id,
        expenseCategory,
        isBillable,
        notes,
        vendor,
      };
    });

    onSubmit(instances);
  };

  return (
    <ModalCard title="Add Multiple Project Expenses" onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          start: Yup.date().label('Starting Month').nullable().required(),
          end: Yup.date()
            .label('Ending Month')
            .nullable()
            .min(Yup.ref('start'), 'Ending Month must be after Starting Month')
            .required(),
          dayOfMonth: Yup.mixed()
            .label('Day of Month')
            .oneOf([...Array(28).keys()].map((i) => i + 1).concat('last'))
            .required(),
          amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable().required(),
          expenseCategory: Yup.object().label('Expense Category').nullable().required(),
          notes: Yup.string().label('Notes').max(255),
          vendor: Yup.string().label('Vendor').max(255),
        })}>
        {() => {
          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.DayPicker
                    name="start"
                    placeholder="Starting Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                  <Field.DayPicker
                    name="end"
                    placeholder="Ending Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                </Form.Control>

                <Form.Control>
                  <ContainerControl style={{ marginTop: '0' }}>
                    Scheduled on the
                    <div style={{ margin: '0 0.5rem' }}>
                      <Field.SingleSelect
                        name="dayOfMonth"
                        style={{ width: '7rem', margin: '0 0.5rem', textAlign: 'right' }}
                        materialPlaceholder={false}>
                        {[...Array(28).keys()].map((day) => (
                          <option key={day} value={day + 1}>
                            {day + 1}
                            {{
                              1: 'st',
                              2: 'nd',
                              3: 'rd',
                            }[day + 1] || 'th'}
                          </option>
                        ))}
                        <option value="last">Last</option>
                      </Field.SingleSelect>
                    </div>
                    <span>day of each month</span>
                  </ContainerControl>
                </Form.Control>

                <Form.Control>
                  <Field.ExpenseCategorySelect name="expenseCategory" placeholder="Expense Category" allowNew />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox name="isBillable" label="Bill to client" disabled={!project.isBillable} />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="vendor" placeholder="Vendor" maxLength={255} />
                </Form.Control>

                <Form.Control>
                  <Field.Currency name="amount" placeholder="Amount" currency={currency} />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="notes" placeholder="Notes" maxLength={255} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Close</CancelButton>
                  <Button type="submit">Add</Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default ProjectExpenses;
