import {
  BackLink,
  BillableIcon,
  Checkbox,
  ClientApprovalStatusFilter,
  DateTime,
  DisciplineFilter,
  Hours,
  Icon,
  InfiniteScrollingObserver,
  InvoicedFilter,
  Level,
  ProjectTaskFilter,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  ProjectTaskTagFilter,
  Stack,
  Table,
  Tag,
  TimeBillableTypeFilter,
  TimeLockIcon,
  TimeOffTypeFilter,
  Tooltip,
  Widget,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import _ from 'lodash';
import timeBillableTypes from '~/lookups/time-billable-types.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import ViewTimeEntry from '~/routes/app/time/view-time-entry';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientApprovalStatusTag from '../components/ClientApprovalStatusTag';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import FiltersGroup from '../components/FiltersGroup';
import Header from '../components/Header';
import InvoicedTag from '../components/InvoicedTag';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import MemberTagsGroup from '../components/MemberTagsGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import PeriodTag from '../components/PeriodTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import ProjectTaskRecordStatusTag from '../components/ProjectTaskRecordStatusTag';
import ProjectTaskStatusTag from '../components/ProjectTaskStatusTag';
import ProjectTaskTag from '../components/ProjectTaskTag';
import ProjectTaskTags from '../components/ProjectTaskTags';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import RoleDisciplineTag from '../components/RoleDisciplineTag';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TimeBillableTypeTag from '../components/TimeBillableTypeTag';
import TimeOffTypeTag from '../components/TimeOffTypeTag';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const Byline = styled.small`
  display: block;
`;

const TimeEntryNotes = styled.p`
  flex-basis: 100%;
  padding: 0 4rem 1rem 12rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

const FirstApprover = ({ entry }) => {
  if (!entry.approvers) return null;
  const approver = entry.approvers[0];

  return approver;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Approvers = ({ entry }) => {
  let approversCount = entry.approvers?.length - 1; // Remove the first approver because it already shows a tag
  if (approversCount <= 0 || !approversCount) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Approvers</Title>

          {entry.approvers.map((approver) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={approver}>
              <small>{approver}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{approversCount}</small>
      </Tag>
    </Tooltip>
  );
};

function TimeEntriesPendingApproval() {
  const documentTitle = useDocumentTitle('Time Entries Pending Approval');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const isMounted = useIsMounted();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [query, setQuery] = useState({
    report: null,
    params: {
      period: null,
      createdPeriod: null,
      modifiedPeriod: null,
      projectTasks: [],
      projectTaskStatuses: [],
      projectTaskRecordStatusId: null,
      projectTaskTags: [],
      clientApprovalStatuses: [],
      billableTypes: [],
      timeOffTypes: [],
      invoiced: null,
      roleDisciplines: [],
      projectRoles: [],
      currency: workspace.currency,
      sort: new QuerySort('date', 'asc'),
      page: 0,
      size: 50,
      ...clientFilters.filters,
      ...projectFilters.filters,
      ...memberFilters.filters,
    },
    action: 'load',
    status: 'loading',
    loading: {
      summary: false,
      table: false,
    },
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.report ||
        state.report.records.total <= state.report.records.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        modifiedPeriod: reportsSearchParamsConfig.modifiedPeriod,
        projectTasks: searchParamsConfig.projectTasks,
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: searchParamsConfig.recordStatusId,
        projectTaskTags: searchParamsConfig.projectTaskTags,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        billableTypes: searchParamsConfig.timeBillableTypes,
        timeOffTypes: searchParamsConfig.timeOffTypes,
        invoiced: searchParamsConfig.invoiced,
        roleDisciplines: { ...searchParamsConfig.disciplines, key: 'roleDiscipline' },
        projectRoles: searchParamsConfig.projectRoles,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('date', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams(params),
  });

  const urlSearchParams = useMemo(() => {
    return {
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      modifiedStart: params.modifiedPeriod?.start ?? undefined,
      modifiedEnd: params.modifiedPeriod?.end ?? undefined,
      projectTaskId: params.projectTasks?.map((v) => v.id),
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      billableTypeId: params.billableTypes?.map((v) => v.id),
      timeOffTypeId: params.timeOffTypes?.map((v) => v.id),
      invoiced: params.invoiced ?? undefined,
      roleDisciplineId: params.roleDisciplines?.map((v) => v.id),
      projectRoleId: params.projectRoles?.map((v) => v.id),
      currency: params.currency ?? undefined,
      sort: params.sort,
      page: params.page,
      size: params.size,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    };
  }, [params, features.clientApprovals, clientFilters, projectFilters, memberFilters]);

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeEntriesPendingApproval(urlSearchParams);

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      action: null,
      status: 'ready',
      loading: { summary: false, table: false },
      report: {
        ...data,
        records: {
          ...data.records,
          results:
            state.action === 'load-more'
              ? [...state.report.records.results, ...data.records.results]
              : data.records.results,
        },
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [showNotes, setShowNotes] = useState(false);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, page: 0, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const [drawer, setDrawer] = useState(null);

  const handleRowClick = (entry) => {
    setDrawer({ entry, mode: entry.isLocked ? 'view' : 'edit' });
  };

  const handleCloseDrawer = () => {
    setDrawer(null);
    documentTitle.set('Time Entries Pending Approval');
  };

  const handleRowSaved = async (savedTimeEntry) => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .timeEntriesPendingApproval({
        ...urlSearchParams,
        timeEntryId: savedTimeEntry.id,
      });

    if (!isMounted.current) return;

    const entry = data.records.find((record) => record.id === savedTimeEntry.id);

    setQuery((state) => ({
      ...state,
      report: {
        ...data,
        records: {
          total: entry ? state.report.records.total : state.report.records.total - 1,
          results: entry
            ? state.report.records.results.map((result) => (result.id === savedTimeEntry.id ? entry : result))
            : state.report.records.results.filter((result) => result.id !== savedTimeEntry.id),
        },
      },
    }));
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeEntriesPendingApproval(
            { ...urlSearchParams, page: undefined, size: null, showNotes },
            { headers: { accept: mimeType } },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Report>
        <Header>
          <BackLink defaultPath={`/app/${workspace.key}/reports/time`} />

          <Stack>
            <h1>Time Entries Pending Approval</h1>

            <Tags>
              <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

              <PeriodTag
                value={params.createdPeriod}
                label="Created Date Range"
                onRemove={() => handleApplyFilters({ createdPeriod: intervalOptions.all_dates })}
              />

              <PeriodTag
                value={params.modifiedPeriod}
                label="Modified Date Range"
                onRemove={() => handleApplyFilters({ modifiedPeriod: intervalOptions.all_dates })}
              />

              <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

              <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />

              {features.clientApprovals && (
                <ClientApprovalStatusTag
                  value={params.clientApprovalStatuses}
                  onRemove={() => handleApplyFilters({ clientApprovalStatuses: [] })}
                />
              )}

              <TimeBillableTypeTag
                value={params.billableTypes}
                onRemove={() => handleApplyFilters({ billableTypes: [] })}
              />

              <TimeOffTypeTag value={params.timeOffTypes} onRemove={() => handleApplyFilters({ timeOffTypes: [] })} />

              <InvoicedTag value={params.invoiced} onRemove={() => handleApplyFilters({ invoiced: null })} />

              <RoleDisciplineTag
                value={params.roleDisciplines}
                onRemove={() => handleApplyFilters({ roleDisciplines: [] })}
              />

              <ProjectTaskTag value={params.projectTasks} onRemove={() => handleApplyFilters({ projectTasks: [] })} />

              <ProjectTaskStatusTag
                value={params.projectTaskStatuses}
                onRemove={() => handleApplyFilters({ projectTaskStatuses: [] })}
              />

              <ProjectTaskRecordStatusTag
                value={params.projectTaskRecordStatusId}
                onRemove={() => handleApplyFilters({ projectTaskRecordStatusId: null })}
              />

              <ProjectTaskTags
                value={params.projectTaskTags}
                onRemove={() => handleApplyFilters({ projectTaskTags: [] })}
              />

              <MemberTagsGroup filters={params} onChange={handleApplyFilters} />
            </Tags>
          </Stack>

          <Level>
            <Level.Item narrow right>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(mimeTypes.pdf);
                        setIsOpen(false);
                      }}>
                      Export to PDF
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>
            </Level.Item>

            <Level.Item narrow right>
              <FilterButton isOutline onClick={showFilters} />
            </Level.Item>
          </Level>
        </Header>

        {(() => {
          switch (query.status) {
            case 'loading':
            case 'filtering':
              return <PageLoader />;

            default:
              return (
                <>
                  <Data
                    query={query}
                    onLoadMore={loadMore}
                    onSort={handleSort}
                    onRowClick={handleRowClick}
                    showNotes={showNotes}
                    setShowNotes={setShowNotes}
                    onCurrencyChange={handleCurrencyChange}
                  />
                  <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
                </>
              );
          }
        })()}
      </Report>

      {drawer &&
        {
          edit: () => (
            <EditTimeEntry
              id={drawer.entry.id}
              memberId={drawer.entry.member.id}
              onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(drawer.entry.id).update(body)}
              onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
              onSaved={handleRowSaved}
              onDeleted={fetchData}
              onTimerChange={fetchData}
              onClose={handleCloseDrawer}
            />
          ),
          view: () => <ViewTimeEntry id={drawer.entry.id} onClose={handleCloseDrawer} />,
        }[drawer.mode]()}
    </>
  );
}

function Data({ query, onLoadMore, onSort, onRowClick, showNotes, setShowNotes, onCurrencyChange }) {
  const features = useFeatures();
  const { report, params } = query;

  const metrics = useMemo(() => {
    const include = (metric) => _.isEmpty(params.billableTypes) || _.includes(params.billableTypes, metric);

    const metrics = [];

    if (include(timeBillableTypes.billable))
      metrics.push({ label: 'Client Billable', value: report.totals.billable, color: colors.success, key: 'billable' });
    if (include(timeBillableTypes.non_billable))
      metrics.push({
        label: 'Client Non-Billable',
        value: report.totals.nonBillable,
        color: colors.danger,
        key: 'non_billable',
      });
    if (include(timeBillableTypes.internal))
      metrics.push({ label: 'Internal', value: report.totals.internal, color: colors.warning, key: 'internal' });
    if (include(timeBillableTypes.time_off))
      metrics.push({ label: 'Time Off', value: report.totals.timeOff, color: colors.primary50, key: 'time_off' });

    return metrics;
  }, [params, report]);

  return (
    <>
      <Report.Summary fade={query.loading.summary}>
        <Widget>
          <Meter>
            {metrics.map((metric) => (
              <Meter.Value key={metric.key} label={metric.label} value={metric.value} color={metric.color} />
            ))}
          </Meter>

          <Metrics>
            {metrics.map((metric) => (
              <Metric key={metric.key}>
                <Metric.Value>
                  <Hours value={metric.value} />
                </Metric.Value>
                <Metric.Label>{metric.label}</Metric.Label>
              </Metric>
            ))}
            <Metric>
              <Metric.Value>
                <Hours value={report.totals.total} />
              </Metric.Value>
              <Metric.Label>Total</Metric.Label>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Level style={{ marginBottom: '.5rem' }}>
          <Level.Item width="8rem">
            <div style={{ display: 'flex', position: 'relative', fontSize: '.875rem' }}>
              <Checkbox
                label="Show notes"
                checked={showNotes}
                onChange={(e) => {
                  setShowNotes(e.target.checked);
                }}
              />
            </div>
          </Level.Item>

          {features.multicurrency && (
            <Level.Item>
              <CurrencyDropdown value={query.params.currency} onChange={onCurrencyChange} />
            </Level.Item>
          )}

          <Level.Item>
            <TotalResults>
              {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
              <Table.Total label="Time Entry" value={report.records.total} />
              {report.partialResults && <PartialResultsTooltip />}
            </TotalResults>
          </Level.Item>
        </Level>

        <Table>
          <Table.Header sticky>
            <Table.Column width="3.5rem" />
            <Table.Column name="date" width="7.5rem" onSort={onSort} sort={params.sort}>
              Date
            </Table.Column>
            <Table.Column name="member.name" onSort={onSort} sort={params.sort}>
              Member/Type
            </Table.Column>
            <Table.Column name="project.name" onSort={onSort} sort={params.sort}>
              Project/Client
            </Table.Column>
            <Table.Column name="role.name" width="18rem" onSort={onSort} sort={params.sort}>
              Role/Task
            </Table.Column>
            <Table.Column name="statusId">Approver(s)</Table.Column>
            <Table.Column name="running_hours" width="5rem" align="right" onSort={onSort} sort={params.sort}>
              Hours
            </Table.Column>
            <Table.Column width="3.5rem" />
          </Table.Header>

          <TableBody fade={query.loading.table}>
            {report.records.results.map((entry) => {
              const hasNotes = !!(showNotes && entry.notes);

              const handleRowClick = () => onRowClick(entry);

              return (
                <Table.Row style={{ flexWrap: 'wrap' }} onClick={handleRowClick} key={entry.id}>
                  <Table.Cell>
                    <BillableIcon value={entry.isActuallyBillable} />
                  </Table.Cell>
                  <Table.Cell>
                    <DateTime value={entry.date} />
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      {entry.member.name}
                      <Byline>{entry.member.employmentType.name}</Byline>
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    {entry.typeId === 'project_time' ? (
                      entry.project && (
                        <p>
                          {entry.project.name}
                          <Byline>{entry.project.client.name}</Byline>
                        </p>
                      )
                    ) : (
                      <p>{entry.type.name}</p>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {entry.typeId === 'project_time' ? (
                      entry.project && (
                        <p>
                          {entry.project?.useRoles && entry.role?.name}
                          {entry.task && <Byline>{entry.task.name}</Byline>}
                        </p>
                      )
                    ) : (
                      <p>{entry.timeOffType?.name}</p>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <FirstApprover entry={entry} />
                    <Approvers entry={entry} />
                  </Table.Cell>

                  <Table.Cell>
                    <Hours value={entry.hours} />
                  </Table.Cell>

                  <Table.Cell>{entry.isLocked && <TimeLockIcon value={entry.lockStatusId} />}</Table.Cell>
                  {hasNotes && <TimeEntryNotes>{entry.notes}</TimeEntryNotes>}
                </Table.Row>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell />
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Hours value={report.totals.total} />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          </TableBody>
        </Table>
        {report.records.total > report.records.results.length && (
          <InfiniteScrollingObserver onIntersecting={onLoadMore} />
        )}
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Task Filters"
        filters={[
          filters.projectTasks,
          filters.projectTaskRecordStatusId,
          filters.projectTaskStatuses,
          filters.projectTaskTags,
        ]}>
        {filters.projects.length === 1 && (
          <Filter>
            <ProjectTaskFilter
              project={filters.projects[0]}
              value={filters.projectTasks}
              onChange={({ target: { value } }) => handleFilter({ projectTasks: value })}
            />
          </Filter>
        )}
        <Filter>
          <ProjectTaskStatusFilter
            value={filters.projectTaskStatuses}
            onChange={({ target: { value } }) => handleFilter({ projectTaskStatuses: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskRecordStatusFilter
            value={filters.projectTaskRecordStatusId}
            onChange={({ target: { value } }) => handleFilter({ projectTaskRecordStatusId: value })}
          />
        </Filter>

        <Filter>
          <ProjectTaskTagFilter
            value={filters.projectTaskTags}
            onChange={({ target: { value } }) => handleFilter({ projectTaskTags: value })}
          />
        </Filter>
      </FiltersGroup>

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Time Filters"
        filters={[
          filters.createdPeriod?.start,
          filters.createdPeriod?.end,
          filters.modifiedPeriod?.start,
          filters.modifiedPeriod?.end,
          filters.billableTypes,
          filters.timeOffTypes,
          filters.clientApprovalStatuses,
          filters.invoiced,
          filters.roleDisciplines,
        ]}>
        <Filter>
          <ReportPeriodFilter
            value={filters.createdPeriod}
            label="Created Date Range"
            onChange={({ target: { value } }) => handleFilter({ createdPeriod: value })}
          />
        </Filter>

        <Filter>
          <ReportPeriodFilter
            value={filters.modifiedPeriod}
            label="Modified Date Range"
            onChange={({ target: { value } }) => handleFilter({ modifiedPeriod: value })}
          />
        </Filter>

        <Filter>
          <TimeBillableTypeFilter
            value={filters.billableTypes}
            onChange={({ target: { value } }) => handleFilter({ billableTypes: value })}
          />
        </Filter>

        <Filter>
          <TimeOffTypeFilter
            value={filters.timeOffTypes}
            onChange={({ target: { value } }) => handleFilter({ timeOffTypes: value })}
          />
        </Filter>

        {features.clientApprovals && (
          <Filter>
            <ClientApprovalStatusFilter
              value={filters.clientApprovalStatuses}
              onChange={({ target: { value } }) => handleFilter({ clientApprovalStatuses: value })}
            />
          </Filter>
        )}

        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => handleFilter({ invoiced: value })}
          />
        </Filter>

        {features.disciplines && (
          <Filter>
            <DisciplineFilter
              value={filters.roleDisciplines}
              materialPlaceholder="Role Discipline"
              onChange={({ target: { value } }) => handleFilter({ roleDisciplines: value })}
            />
          </Filter>
        )}
      </FiltersGroup>
    </Report.Filters>
  );
}

export default TimeEntriesPendingApproval;
