import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Grid, Level, MemberSelect, Page, SingleSelect } from '~/components';
import { useRating, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import { intervalOptions } from '~/utils';
import AllocatedHoursWidget from '../AllocatedHoursWidget';
import DashboardDropdown from '../DashboardDropdown';
import MemmberTimeSummaryWidget from './MemberTimeSummaryWidget';
import MemberUtilizationWidget from './MemberUtilizationWidget';
import RealizationRateWidget from './RealizationRateWidget';
import VarianceWidget from './VarianceWidget';

const MemberName = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const Title = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

const Section = styled.section`
  margin-top: 1.5rem;
`;

function PersonalDashboard() {
  useDocumentTitle('Personal Dashboard');
  const features = useFeatures();

  const intervals = useMemo(
    () => ({
      this_week_to_date: { ...intervalOptions.this_week_to_date, period: 'week' },
      this_month_to_date: { ...intervalOptions.this_month_to_date, period: 'month' },
      this_quarter_to_date: { ...intervalOptions.this_quarter_to_date, period: 'quarter' },
      this_year_to_date: { ...intervalOptions.this_year_to_date, period: 'year' },
      last_week: { ...intervalOptions.last_week, period: 'week' },
      last_month: { ...intervalOptions.last_month, period: 'month' },
      last_quarter: { ...intervalOptions.last_quarter, period: 'quarter' },
      last_year: { ...intervalOptions.last_year, period: 'year' },
    }),
    [],
  );

  const personalDashboardInterval = {
    key: 'personal_dashboard.interval',

    get value() {
      let interval = localStorage.getItem(this.key);
      if (_.has(intervals, interval)) return interval;

      interval = intervals.this_week_to_date.key;
      this.value = interval;
      return interval;
    },

    set value(value) {
      localStorage.setItem(this.key, value);
    },
  };

  const { workspace } = useWorkspace();
  const auth = useAuth();
  const [interval, setInterval] = useState(personalDashboardInterval.value);
  const [member, setMember] = useState();
  const { autoOpenRatingModal } = useRating();
  const { start, end, period } = intervals[interval];

  const handleChangeInterval = ({ target: { value } }) => {
    setInterval(value);
    personalDashboardInterval.value = value;
  };

  useEffect(() => {
    autoOpenRatingModal();
  }, [autoOpenRatingModal]);

  const utilization = useMemo(
    () => ({
      billable: ['billable'],
      client: ['billable', 'non_billable'],
      productive: ['billable', 'non_billable', 'productive'],
      total: ['billable', 'non_billable', 'productive', 'internal'],
    }),
    [],
  );

  const filterMembers = useMemo(() => {
    return [workspace.member.id];
  }, [workspace]);

  const currentMember = member || workspace.member;
  const { id: memberId } = currentMember;

  return (
    <Page>
      <Page.Header>
        <Level width="100%">
          <Level.Item>
            <MemberName>
              {currentMember.name}
              {currentMember.name.toLowerCase().endsWith('s') ? `'` : `'s`}
            </MemberName>
            {auth.workspace.manage ? <DashboardDropdown value="personal" /> : <Title>Personal Dashboard</Title>}
          </Level.Item>

          <Level.Item right width="15rem">
            <SingleSelect value={interval} onChange={handleChangeInterval}>
              {_.map(intervals, ({ label }, key) => (
                <option key={key} value={key}>
                  {label}
                </option>
              ))}
            </SingleSelect>
          </Level.Item>

          {auth.members.manageTimeAndExpenses && (
            <Level.Item right width="19rem">
              <MemberSelect
                filterMembers={filterMembers}
                placeholder="Teammates"
                permission="manageTimeAndExpenses"
                withPermissions
                onChange={(event) => setMember(event.target.value)}
                value={member}
                align="right"
              />
            </Level.Item>
          )}
        </Level>
      </Page.Header>
      <Section>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <MemmberTimeSummaryWidget
                start={start}
                end={end}
                period={period}
                interval={interval}
                memberId={memberId}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column data-testid="realization-rate">
              <RealizationRateWidget start={start} end={end} memberId={memberId} />
            </Grid.Column>
            {(!member || member.permissions.manageAllocations) && (
              <>
                {features.allocations && (
                  <Grid.Column data-testid="allocated-hours">
                    <AllocatedHoursWidget start={start} end={end} memberId={memberId} />
                  </Grid.Column>
                )}
                {features.allocations && features.forecast && (
                  <Grid.Column data-testid="variance">
                    <VarianceWidget start={start} end={end} memberId={memberId} />
                  </Grid.Column>
                )}
              </>
            )}
          </Grid.Row>
          {features.utilization && (
            <Grid.Row>
              <Grid.Column data-testid="billable_utilization">
                <MemberUtilizationWidget
                  start={start}
                  end={end}
                  title="Billable Utilization"
                  helpMessage="Your billable hours divided by your capacity."
                  types={utilization.billable}
                  showTarget
                  memberId={memberId}
                />
              </Grid.Column>
              <Grid.Column data-testid="client_utilization">
                <MemberUtilizationWidget
                  start={start}
                  end={end}
                  title="Client Utilization"
                  helpMessage="The sum of your billable and non-billable client hours divided by your capacity."
                  types={utilization.client}
                  memberId={memberId}
                />
              </Grid.Column>
              <Grid.Column data-testid="member_utilization">
                <MemberUtilizationWidget
                  start={start}
                  end={end}
                  title="Productive Utilization"
                  helpMessage="The sum of your client hours and internal hours on productive projects divided by your capacity."
                  types={utilization.productive}
                  memberId={memberId}
                />
              </Grid.Column>
              <Grid.Column data-testid="total_utilization">
                <MemberUtilizationWidget
                  start={start}
                  end={end}
                  title="Total Utilization"
                  helpMessage="The sum of all your client and internal hours divided by your capacity."
                  types={utilization.total}
                  memberId={memberId}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Section>
    </Page>
  );
}

export default PersonalDashboard;
