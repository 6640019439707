import { BackLink, ClientLink, Icon, InternalClientTooltip, Page, Tooltip, Pill, Level } from '~/components';
import { useWorkspace } from '~/contexts';
import path from 'path-browserify';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import QBO from './assets/qbo.svg?react';
import ProjectStatusesPopover from './ProjectStatusesPopover';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const ProjectName = styled.h1`
  display: flex;

  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const StatusTag = styled(Tag)`
  color: ${({ status }) =>
    ({
      tentative: colors.grey25,
      not_started: colors.warning,
      in_progress: colors.success,
      paused: colors.danger,
      completed: colors.black,
      cancelled: colors.grey55,
    })[status]};

  .icon {
    font-size: 0.625rem;
    margin-right: 0.7rem;
  }
`;

const EditLink = styled(Link)`
  flex-shrink: 0;
  margin-left: auto;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;

  &:hover {
    background-color: ${colors.grey10};
  }
`;

function ProjectHeader({ project, onChange }) {
  const location = useLocation();
  const { workspace } = useWorkspace();
  const { name, status, client, recordStatus, lockTimeAndExpenses } = project;

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/projects`} />
      <InfoContainer>
        <Eyebrow>Project Dashboard</Eyebrow>
        <ProjectName>
          {name}
          {recordStatus.id === 'archived' && (
            <div>
              <Pill
                style={{
                  marginLeft: '.5rem',
                  backgroundColor: colors.grey25,
                }}>
                Archived
              </Pill>
            </div>
          )}
          {lockTimeAndExpenses && (
            <Tooltip message="Time and expenses have been locked for this project.">
              <Pill
                style={{
                  marginLeft: '.5rem',
                  backgroundColor: colors.red,
                }}>
                <Icon icon="lock" spaceRight /> Locked
              </Pill>
            </Tooltip>
          )}
          {project.qboProjectId && (
            <Tooltip message="This project is connected with QuickBooks." placement="right">
              <QBO style={{ width: '1.25rem', height: '1.25rem', marginLeft: '.5rem' }} />
            </Tooltip>
          )}
        </ProjectName>
        <Tags>
          <Tag>
            <ClientLink client={client} />
            {client.isInternal && <InternalClientTooltip />}
          </Tag>
          <StatusTag status={status.id}>
            {project.permissions.edit ? (
              <ProjectStatusesPopover projectId={project.id} currentStatus={status} onStatusChange={onChange}>
                <Icon icon="circle" />
                <span>{status.name}</span>
              </ProjectStatusesPopover>
            ) : (
              <>
                <Icon icon="circle" />
                <span>{status.name}</span>
              </>
            )}
          </StatusTag>
        </Tags>
      </InfoContainer>

      {project.permissions.edit && (
        <Level right>
          <Level.Item>
            <EditLink to={`${resolveEditPath('history', location)}${location.search}`}>
              <Icon icon="history" />
            </EditLink>
          </Level.Item>

          <Level.Item>
            <EditLink to={`${resolveEditPath('edit', location)}${location.search}`} data-testid="edit-project">
              <Icon icon="pencil-alt" />
            </EditLink>
          </Level.Item>
        </Level>
      )}
    </Page.Header>
  );
}

export default ProjectHeader;
