import {
  ActionButton,
  Checkbox,
  ClientSelect,
  Currency,
  DayPickerInput,
  HelpTooltip,
  Level,
  MemberSelect,
  Page,
  RouteLink,
  SingleSelect,
  ProjectStatusFilter,
  ProjectTaskStatusFilter,
  Spinner,
  Table,
  PracticeSelect,
} from '~/components';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useDateTimeFormat, useDocumentTitle, useFeatures } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, QueryString } from '~/utils';
import ApprovedExpensesIcon from './assets/approved-expenses.svg?react';
import ApprovedServicesIcon from './assets/approved-services.svg?react';
import OtherItemsIcon from './assets/other-items.svg?react';
import ReadyToBillIcon from './assets/ready-to-bill.svg?react';
import CreateInvoiceDialog from './invoice-dialogs/CreateInvoiceDialog';
import ReadyToBillResults from './ReadyToBillResults';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 1.25rem;
  margin: 0 -2rem;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0 3px 15px ${colors.grey10};
  border-radius: 5px;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const SummaryBox = styled.div`
  display: flex;
  flex: 1;
  padding: 1.5rem 1rem;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const SummaryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 3.125rem;
  height: 3.125rem;
`;

const SummaryAmount = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  font-weight: ${weights.bold};
  font-size: 1.25rem;

  p {
    font-size: 0.75rem;
    font-weight: ${weights.normal};
    line-height: 1rem;
    white-space: nowrap;
  }
`;

const Actions = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: ${colors.white};

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }
`;

const intervalKey = 'readyToBillInterval';

function getSavedInterval(intervals, defaultInterval) {
  const intervalJson = localStorage.getItem(intervalKey);
  if (intervalJson) {
    const savedInterval = JSON.parse(intervalJson);
    if (savedInterval && savedInterval.key && savedInterval.key in intervals) {
      const interval = intervals[savedInterval.key];
      if (interval.key === 'custom') {
        interval.start = savedInterval.start;
        interval.end = savedInterval.end;
      }
      return interval;
    }
  }
  return defaultInterval;
}

function setSavedInterval(interval) {
  localStorage.setItem(intervalKey, JSON.stringify(interval));
}

const includePriorUnbilledItemsKey = 'includePriorUnbilledItems';

function getSavedIncludePriorUnbilledItems() {
  return localStorage.getItem(includePriorUnbilledItemsKey) === 'true';
}

function setSavedIncludePriorUnbilledItems(value) {
  localStorage.setItem(includePriorUnbilledItemsKey, value);
}

const clientKey = 'client';

function getSavedClient() {
  const clientJson = localStorage.getItem(clientKey);
  return JSON.parse(clientJson);
}

function setSavedClient(value) {
  localStorage.setItem(clientKey, JSON.stringify(value || null));
}

const projectAdminKey = 'projectAdmin';

function getSavedProjectAdmin() {
  const projectAdmin = localStorage.getItem(projectAdminKey);
  return JSON.parse(projectAdmin);
}

function setSavedProjectAdmin(value) {
  localStorage.setItem(projectAdminKey, JSON.stringify(value || null));
}

const approvedItemsOnlyKey = 'approvedItemsOnly';

function getSavedApprovedItemsOnly() {
  return localStorage.getItem(approvedItemsOnlyKey) === 'true';
}

function setSavedApprovedItemsOnly(value) {
  localStorage.setItem(approvedItemsOnlyKey, value);
}

const projectStatusesKey = 'projectStatuses';

function getSavedProjectStatuses() {
  const projectStatuses = localStorage.getItem(projectStatusesKey);
  if (projectStatuses) return JSON.parse(projectStatuses);
  return [];
}

function setSavedProjectStatuses(value) {
  localStorage.setItem(projectStatusesKey, JSON.stringify(value || []));
}

const projectTaskStatusesKey = 'projectTaskStatuses';

function getSavedProjectTaskStatuses() {
  const projectTaskStatuses = localStorage.getItem(projectTaskStatusesKey);
  if (projectTaskStatuses) return JSON.parse(projectTaskStatuses);
  return [];
}

function setSavedProjectTaskStatuses(value) {
  localStorage.setItem(projectTaskStatusesKey, JSON.stringify(value || []));
}

function setSavedClientPractice(value) {
  localStorage.setItem('clientPractice', JSON.stringify(value || null));
}

function setSavedProjectPractice(value) {
  localStorage.setItem('projectPractice', JSON.stringify(value || null));
}

function getSavedClientPractice() {
  const clientPractice = localStorage.getItem('clientPractice');
  if (clientPractice) return JSON.parse(clientPractice);
  return null;
}

function getSavedProjectPractice() {
  const projectPractice = localStorage.getItem('projectPractice');
  if (projectPractice) return JSON.parse(projectPractice);
  return null;
}

function ReadyToBillPage() {
  useDocumentTitle('Ready to Bill');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [selection, setSelection] = useState([]);
  const [{ status, data }, setQuery] = useState({ status: 'loading', data: null });

  const intervals = useMemo(
    () =>
      _.pick(
        intervalOptions,
        'custom',
        'this_week',
        'this_semi_month',
        'this_month',
        'this_month_to_date',
        'last_week',
        'last_semi_month',
        'last_month',
      ),
    [],
  );
  const [{ key, start, end }, setInterval] = useState(getSavedInterval(intervals, intervals.this_month));
  const [includePriorUnbilledItems, setIncludePriorUnbilledItems] = useState(getSavedIncludePriorUnbilledItems);
  const [client, setClient] = useState(getSavedClient);
  const [projectAdmin, setProjectAdmin] = useState(getSavedProjectAdmin);
  const [approvedItemsOnly, setApprovedItemsOnly] = useState(getSavedApprovedItemsOnly);
  const [projectStatuses, setProjectStatuses] = useState(getSavedProjectStatuses);
  const [projectTaskStatuses, setProjectTaskStatuses] = useState(getSavedProjectTaskStatuses);
  const [clientPractice, setClientPractice] = useState(getSavedClientPractice);
  const [projectPractice, setProjectPractice] = useState(getSavedProjectPractice);

  const { notify } = useSubscription();

  const [dialogItem, setDialogItem] = useState(null);

  const toast = useToast();

  const features = useFeatures();

  const fetchData = useCallback(async () => {
    setQuery((state) => (state.status === 'ready' ? { ...state, status: 'filtering' } : state));

    const { data } = await api.www
      .workspaces(workspace.id)
      .invoices()
      .readyToBill({
        start: start || undefined,
        end: end || undefined,
        includePriorUnbilledItems: includePriorUnbilledItems || undefined,
        clientId: client ? client.id : undefined,
        projectAdminId: projectAdmin ? projectAdmin.id : undefined,
        approvedItemsOnly: approvedItemsOnly || undefined,
        projectStatusId: projectStatuses?.map((v) => v.id),
        projectTaskStatusId: projectTaskStatuses?.map((v) => v.id),
        size: 200,
        clientPracticeId: clientPractice?.id,
        projectPracticeId: projectPractice?.id,
      });

    setQuery({ status: 'ready', data });
  }, [
    workspace.id,
    api,
    start,
    end,
    includePriorUnbilledItems,
    approvedItemsOnly,
    projectStatuses,
    projectTaskStatuses,
    client,
    projectAdmin,
    clientPractice,
    projectPractice,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const history = useHistory();

  const handlePeriodChange = ({ target: { value } }) => {
    const { key, ...intervalSettings } = intervals[value];
    const interval = { key, start, end };

    if (value !== 'custom') {
      interval.start = intervalSettings.start;
      interval.end = intervalSettings.end;
    }

    handleIntervalChange(interval);
  };

  const handleDateChange = (name, value) => {
    handleIntervalChange({ key, start, end, [name]: value });
  };

  const handleIntervalChange = (interval) => {
    setInterval(interval);
    setSelection([]);
    setSavedInterval(interval);
  };

  const handleCreateClick = () => {
    const projects = data.projects.filter((p) => selection.includes(p.id));
    const { client, currency } = projects[0];
    setDialogItem({ client, currency, projects });
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleCreate = async (client, currency, projects) => {
    try {
      const { data: invoice } = await api.www
        .workspaces(workspace.id)
        .invoices()
        .createReadyToBill({
          clientId: client.id,
          currency,
          projectIds: projects.map((p) => p.id),
          issuedOn: moment().format(dateFormats.isoDate),
          servicesThrough: moment(end || new Date()).format(dateFormats.isoDate),
          periodStart: includePriorUnbilledItems ? null : start,
          periodEnd: end,
          approvedItemsOnly: approvedItemsOnly || undefined,
        });

      notify(useSubscription.keys.refresh_timer);
      notify(useSubscription.keys.refresh_time_approval_count);
      notify(useSubscription.keys.refresh_expense_approval_count);

      history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleIncludePriorUnbilledItemsChange = ({ target: { checked } }) => {
    setIncludePriorUnbilledItems(checked);
    setSavedIncludePriorUnbilledItems(checked);
  };

  const handleClientChange = (client) => {
    setClient(client);
    setSavedClient(client);
  };

  const handleProjectAdminChange = (projectAdmin) => {
    setProjectAdmin(projectAdmin);
    setSavedProjectAdmin(projectAdmin);
  };

  const handleApprovedItemsOnlyChange = ({ target: { checked } }) => {
    setApprovedItemsOnly(checked);
    setSavedApprovedItemsOnly(checked);
  };

  const handleProjectStatusesChange = (projectStatuses) => {
    setProjectStatuses(projectStatuses);
    setSavedProjectStatuses(projectStatuses);
  };

  const handleProjectTaskStatusesChange = (projectTaskStatuses) => {
    setProjectTaskStatuses(projectTaskStatuses);
    setSavedProjectTaskStatuses(projectTaskStatuses);
  };

  const handleClientPractice = (clientPractice) => {
    setClientPractice(clientPractice);
    setSavedClientPractice(clientPractice);
  };

  const handleProjectPractice = (projectPractice) => {
    setProjectPractice(projectPractice);
    setSavedProjectPractice(projectPractice);
  };

  const uninvoicedRevenue = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/financial/uninvoiced-revenue?${new QueryString(
        {
          start: includePriorUnbilledItems ? 'not_set' : start ?? 'not_set',
          end: end ?? 'not_set',
          projectRecordStatusId: 'all',
          client: client ? client.id : undefined,
          timeStatus: approvedItemsOnly ? ['approved'] : undefined,
          expenseStatus: approvedItemsOnly ? ['approved'] : undefined,
          projectStatus: projectStatuses?.map((v) => v.id),
          projectTaskStatus: projectTaskStatuses?.map((v) => v.id),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [
      includePriorUnbilledItems,
      end,
      start,
      client,
      approvedItemsOnly,
      projectStatuses,
      projectTaskStatuses,
      workspace.key,
    ],
  );

  const dateTimeFormat = useDateTimeFormat();

  if (status === 'loading') return <PageLoader />;

  return (
    <>
      <Page>
        <Level margin="0 0 1.5rem 0">
          <Level.Item width="27rem">
            <SingleSelect
              placeholder="Date Range"
              value={key}
              onChange={handlePeriodChange}
              data-testid="select_interval">
              {_.map(intervals, (interval, key) => (
                <option key={key} value={key}>
                  {interval.label}{' '}
                  {interval.key !== 'custom' && (
                    <>
                      ({dateTimeFormat.format(interval.start)} - {dateTimeFormat.format(interval.end)})
                    </>
                  )}
                </option>
              ))}
            </SingleSelect>
          </Level.Item>

          {key === 'custom' && (
            <>
              <Level.Item width="15rem">
                <DayPickerInput
                  value={start}
                  placeholder="Start"
                  onChange={(value) => handleDateChange('start', value)}
                />
              </Level.Item>
              <Level.Item width="0">through</Level.Item>
              <Level.Item width="15rem">
                <DayPickerInput value={end} placeholder="End" onChange={(value) => handleDateChange('end', value)} />
              </Level.Item>
            </>
          )}

          <Level.Item>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                label="Include unbilled prior items"
                checked={includePriorUnbilledItems}
                onChange={handleIncludePriorUnbilledItemsChange}
              />
              <HelpTooltip
                style={{ marginLeft: '.5rem' }}
                message="Invoice for billable items dated prior to the date range that have not previously been invoiced."
              />
            </div>
          </Level.Item>
          <Level.Item>
            <Table.Status>{status !== 'ready' && <Spinner />}</Table.Status>
          </Level.Item>
        </Level>

        <Level margin="0 0 1.5rem 0">
          <Level.Item width="20rem">
            <ClientSelect
              name="client"
              placeholder="All"
              materialAlwaysVisible
              materialPlaceholder="Client"
              activeOnly={false}
              value={client}
              onChange={({ target: { value } }) => handleClientChange(value)}
            />
          </Level.Item>
          {features.practices && (
            <Level.Item width="20rem">
              <PracticeSelect
                name="clientPractice"
                placeholder="All"
                materialPlaceholder="Client Practice"
                materialAlwaysVisible
                value={clientPractice}
                onChange={({ target: { value } }) => handleClientPractice(value)}
              />
            </Level.Item>
          )}

          <Level.Item width="14.9rem">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                label="Approved items only"
                checked={approvedItemsOnly}
                onChange={handleApprovedItemsOnlyChange}
              />
            </div>
          </Level.Item>
        </Level>
        <Level margin="0 0 1.5rem 0">
          {features.practices && (
            <Level.Item width="20rem">
              <PracticeSelect
                name="projectPractice"
                placeholder="All"
                materialPlaceholder="Project Practice"
                materialAlwaysVisible
                value={projectPractice}
                onChange={({ target: { value } }) => handleProjectPractice(value)}
              />
            </Level.Item>
          )}
          <Level.Item width="20rem">
            <MemberSelect
              name="projectAdmin"
              placeholder="All"
              materialPlaceholder="Project Admin"
              materialAlwaysVisible
              value={projectAdmin}
              onChange={({ target: { value } }) => handleProjectAdminChange(value)}
            />
          </Level.Item>
          <Level.Item width="20rem">
            <ProjectStatusFilter
              value={projectStatuses}
              onChange={({ target: { value } }) => handleProjectStatusesChange(value)}
            />
          </Level.Item>
          <Level.Item width="20rem">
            <ProjectTaskStatusFilter
              value={projectTaskStatuses}
              onChange={({ target: { value } }) => handleProjectTaskStatusesChange(value)}
            />
          </Level.Item>
        </Level>

        <SummarySection>
          <Summary fade={status === 'filtering'}>
            <SummaryBox>
              <SummaryIcon>
                <ApprovedServicesIcon />
              </SummaryIcon>
              <SummaryAmount>
                <div>
                  <RouteLink to={uninvoicedRevenue({ itemType: ['time_entry', 'fixed_fee_milestone'] })}>
                    <Currency value={data.totals.services} />
                    <p>Total Services</p>
                  </RouteLink>
                </div>
              </SummaryAmount>
            </SummaryBox>
            <SummaryBox>
              <SummaryIcon>
                <ApprovedExpensesIcon />
              </SummaryIcon>
              <SummaryAmount>
                <div>
                  <RouteLink to={uninvoicedRevenue({ itemType: ['expense_item', 'project_expense_item'] })}>
                    <Currency value={data.totals.expenses} />
                    <p>Total Expenses</p>
                  </RouteLink>
                </div>
              </SummaryAmount>
            </SummaryBox>
            <SummaryBox>
              <SummaryIcon>
                <OtherItemsIcon />
              </SummaryIcon>
              <SummaryAmount>
                <div>
                  <RouteLink to={uninvoicedRevenue({ itemType: 'other_item' })}>
                    <Currency value={data.totals.otherItems} />
                    <p>Total Other Items</p>
                  </RouteLink>
                </div>
              </SummaryAmount>
            </SummaryBox>
            <SummaryBox>
              <SummaryIcon>
                <ReadyToBillIcon />
              </SummaryIcon>
              <SummaryAmount>
                <div>
                  <RouteLink to={uninvoicedRevenue()}>
                    <Currency value={data.total} />
                    <p>Total to Bill</p>
                  </RouteLink>
                </div>
              </SummaryAmount>
            </SummaryBox>
          </Summary>
        </SummarySection>

        <ReadyToBillResults
          includePriorUnbilledItems={includePriorUnbilledItems}
          start={start}
          end={end}
          data={data}
          selection={selection}
          onSelectionChange={handleSelectionChange}
          status={status}
          approvedItemsOnly={approvedItemsOnly}
          projectTaskStatuses={projectTaskStatuses}
        />
      </Page>

      {selection.length > 0 && (
        <Actions>
          <Level right>
            <Level.Item>
              <ActionButton disabled={selection.length === 0} onClick={() => handleCreateClick()}>
                Create Invoice
              </ActionButton>
            </Level.Item>
          </Level>
        </Actions>
      )}

      {dialogItem && (
        <CreateInvoiceDialog
          client={dialogItem.client}
          currency={dialogItem.currency}
          projects={dialogItem.projects}
          onCreate={handleCreate}
          onClose={() => setDialogItem(null)}
        />
      )}
    </>
  );
}

export default ReadyToBillPage;
