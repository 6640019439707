import {
  Button,
  DeleteConfirmation,
  Dropdown,
  ExportDialog,
  HelpTooltip,
  Level,
  SearchInput,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import ExportDropdown from '../ExportDropdown';
import { ActiveStatusSelect, CustomDataHeader } from '../custom-data/components';
import PipelineActivityTypeDrawer from './PipelineActivityTypeDrawer';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

function PipelineActivityTypesPage() {
  const documentTitle = useDocumentTitle('Pipeline Activity Types');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', isActive: 'true' });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).pipelineActivityTypes().get(params);
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .pipelineActivityTypes()
          .get(params, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };
  const handleEdit = async (pipelineActivityType) => {
    setDialog(
      <PipelineActivityTypeDrawer
        pipelineActivityType={pipelineActivityType}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Pipeline Activity Types');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).pipelineActivityTypes(item.id).setActiveStatus(flag);
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const handleDelete = async (pipelineActivityType) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this Pipeline Activity Type?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .pipelineActivityTypes(pipelineActivityType ? pipelineActivityType.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  };

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <CustomDataHeader>
        <CustomDataHeader.Details>
          <CustomDataHeader.Title>Pipeline Activity Types</CustomDataHeader.Title>
          <CustomDataHeader.Description>
            Pipeline Activity Types are used to track the types of activities that occur during the sales process.
          </CustomDataHeader.Description>
        </CustomDataHeader.Details>
        <CustomDataHeader.Buttons>
          <Button onClick={() => handleEdit({})}>New Pipeline Activity Type</Button>
        </CustomDataHeader.Buttons>
      </CustomDataHeader>
      <Level>
        <Level.Item width="20rem">
          <SearchInput
            value={params.q}
            placeholder="Search"
            materialPlaceholder="Name"
            materialAlwaysVisible
            onChange={handleFilterChange}
          />
        </Level.Item>
        <Level.Item width="20rem">
          <ActiveStatusSelect value={params.isActive} onChange={handleFilterChange} />
        </Level.Item>
        <Level.Item right narrow>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('pipeline_activity_types.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('pipeline_activity_types.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Level.Item>
      </Level>
      <Table.Total value={data.length} label="Pipeline Activity Type" style={{ marginTop: '1rem' }} />
      <Table>
        <Table.BoxHeader>
          <Table.Column width="20rem">Name</Table.Column>
          <Table.Column>Description</Table.Column>

          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {data.map((item) => {
            const { id, name, description, isActive, isRestricted } = item;

            return (
              <Table.BoxRow onClick={() => handleEdit(item)} key={id} isDisabled={!isActive}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{description}</Table.Cell>

                <TableBoxRowActions>
                  {isRestricted ? (
                    <TooltipContainer>
                      <HelpTooltip message="This pipeline activity type is read-only." />
                    </TooltipContainer>
                  ) : (
                    <>
                      <TableBoxRowActions.Edit onClick={() => handleEdit(item)} />

                      <hr />

                      <TableBoxRowActions.Dropdown>
                        {({ setIsOpen }) => (
                          <>
                            <Dropdown.Item onClick={() => handleEdit(item)}>Edit</Dropdown.Item>
                            <Dropdown.Item
                              onClick={async () => {
                                await handleActiveStatusChange(item, !isActive);
                                setIsOpen(false);
                              }}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </Dropdown.Item>
                            <Dropdown.DeleteItem
                              tooltip="This item is currently in use."
                              onCheckDependencies={async (workspace) =>
                                (await workspace.pipelineActivityTypes(id).hasDependencies()).data
                              }
                              onClick={() => handleDelete(item)}>
                              Delete
                            </Dropdown.DeleteItem>
                          </>
                        )}
                      </TableBoxRowActions.Dropdown>
                    </>
                  )}
                </TableBoxRowActions>
              </Table.BoxRow>
            );
          })}
        </Table.Body>
      </Table>

      {dialog}
    </>
  );
}

export default PipelineActivityTypesPage;
