import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

export default function PipelineActivityTypeDrawer({ pipelineActivityType, onSaved, onClose, onDeleted }) {
  const title = `${pipelineActivityType?.id ? 'Edit' : 'New'} Pipeline Activity Type`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();
  const [hasDependencies, setHasDependencies] = useState(true);

  useEffect(() => {
    if (!pipelineActivityType?.id) {
      setHasDependencies(false);
      return;
    }

    (async function fetchData() {
      const dependencies = await api.www
        .workspaces(workspace.id)
        .pipelineActivityTypes(pipelineActivityType.id)
        .hasDependencies();
      setHasDependencies(dependencies.data);
    })();
  }, [api, pipelineActivityType, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      name: '',
      description: '',
      typeId: '',
    },
    pipelineActivityType,
  );

  const showDeleteButton = pipelineActivityType.id && !hasDependencies && !pipelineActivityType?.isRestricted;

  return (
    <>
      <Drawer
        isOpen
        title={title}
        byline="Custom Data"
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(values, { resetForm }) {
            try {
              form.submit(formRef.current.status.action);

              const body = emptyStringToNull(values);

              const { data } = await api.www
                .workspaces(workspace.id)
                .pipelineActivityTypes(pipelineActivityType ? pipelineActivityType.id : undefined)
                .upsert(body);

              await onSaved(data);
              form.save(formRef.current.status.action);

              switch (formRef.current.status.action) {
                case 'new':
                  resetForm();
                  toast.success('Pipeline Activity Type successfully saved.');
                  break;
                case 'close':
                  closeDrawer();
                  break;
              }
              form.done();
            } catch ({ message }) {
              form.error({ message });
            }
          }

          async function handleDelete() {
            const confirm = await confirmation.prompt((resolve) => (
              <DeleteConfirmation resolve={resolve}>
                Are you sure you want to delete this Pipeline Activity Type?
              </DeleteConfirmation>
            ));
            if (!confirm) return;
            try {
              await api.www.workspaces(workspace.id).pipelineActivityTypes(pipelineActivityType.id).delete();
              await onDeleted();
              closeDrawer();
            } catch ({ message }) {
              toast.error(message);
            }
          }

          return (
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                name: Yup.string().label('Name').max(255).required(),
                description: Yup.string().label('Description').max(5000),
                typeId: Yup.string().label('Type').oneOf(['call', 'email', 'file', 'note']).required(),
              })}>
              {(formik) => {
                const submit = async (action) => {
                  formik.setStatus({ action });
                  formik.submitForm();
                };

                return (
                  <Form>
                    <Form.Section title="General">
                      <Form.Control>
                        <Field.Text
                          autoFocus
                          name="name"
                          placeholder="Name"
                          maxLength={255}
                          disabled={pipelineActivityType?.isRestricted}
                        />
                      </Form.Control>
                      <Form.Control>
                        <Field.SingleSelect
                          name="typeId"
                          placeholder="Type"
                          disabled={pipelineActivityType?.isRestricted}>
                          <option value="call">Call</option>
                          <option value="email">Email</option>
                          <option value="file">File</option>
                          <option value="meeting">Meeting</option>
                          <option value="note">Note</option>
                        </Field.SingleSelect>
                      </Form.Control>
                      <Form.Control>
                        <Field.TextArea
                          name="description"
                          placeholder="Description"
                          maxLength={5000}
                          disabled={pipelineActivityType?.isRestricted}
                        />
                      </Form.Control>
                    </Form.Section>

                    {status && <FormMessage.Error>{message}</FormMessage.Error>}

                    <Drawer.Actions>
                      {showDeleteButton && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                        {!pipelineActivityType?.id && (
                          <ActionButton
                            isLoading={isSubmitting === 'new'}
                            ok={saved === 'new'}
                            type="submit"
                            onClick={() => submit('new')}>
                            Save &amp; New
                          </ActionButton>
                        )}

                        <ActionButton
                          isLoading={isSubmitting === 'close'}
                          ok={saved === 'close'}
                          type="submit"
                          onClick={() => submit('close')}>
                          Save &amp; Close
                        </ActionButton>
                      </Buttons>
                    </Drawer.Actions>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </Drawer>
    </>
  );
}
