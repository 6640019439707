import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '~/styles';
import { Level } from '~/components';

const InstructionTable = styled.table`
  && {
    font-size: 0.75rem;
    border-radius: 4px;
  }
  thead th {
    border: 1px solid ${colors.grey25};
    font-weight: bold;
    padding: 0.25rem;
    color: ${colors.grey100};
  }
  tbody td {
    border: 1px solid ${colors.grey25};
    padding: 0.25rem;
    color: ${colors.grey100};
  }

  tbody td:first-child {
    width: 8rem;
  }
`;

const SampleFile = styled.a`
  display: flex;
  flex: 1;
  font-size: 0.875rem;
  margin: -0.5rem 0 0rem 0;
  max-width: 10rem;
`;

const ToggleButton = styled.button`
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: ${colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.75rem;

  &:hover {
    background-color: ${colors.primaryDark};
  }
`;

const Container = styled.div`
  margin-bottom: 1rem;
`;

const ReferenceSection = ({ validationRules, sampleFileKey }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Container>
      <Level>
        <Level.Item narrow>
          <ToggleButton onClick={toggleVisibility}>
            {isVisible ? 'Hide Reference Table' : 'Show Reference Table'}
          </ToggleButton>
        </Level.Item>
        <Level.Item right center>
          {sampleFileKey && (
            <SampleFile target="blank" href={`/csv/${sampleFileKey}.csv`}>
              {sampleFileKey}.csv
            </SampleFile>
          )}
        </Level.Item>
      </Level>
      {isVisible && (
        <InstructionTable>
          <thead>
            <tr>
              <th>Column</th>
              <th>Requirement</th>
              <th>Description</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(validationRules).map(([field, rule]) => (
              <tr key={field}>
                <td>
                  {rule.helpUrl ? (
                    <a href={rule.helpUrl} target="_blank" rel="noopener noreferrer">
                      {field}
                    </a>
                  ) : (
                    field
                  )}
                </td>
                <td>{rule.requirementType}</td>
                <td>{rule.description}</td>
                <td>{rule.dataType}</td>
              </tr>
            ))}
          </tbody>
        </InstructionTable>
      )}
    </Container>
  );
};

ReferenceSection.propTypes = {
  validationRules: PropTypes.object.isRequired,
};

export default ReferenceSection;
