import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import logo from '~/assets/logo-white.svg';
import { Icon } from '~/components';
import { colors, devices, weights } from '~/styles';
import ResponsiveContainer from './ResponsiveContainer';

// Some properties are only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledLink = ({ isOutline, ...props }) => <Link {...props} />;

const GlobalStyle = createGlobalStyle`
  @media ${devices.touch} {
    html {
      overflow: hidden;
    }
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.primary};
  overflow: auto;
  z-index: 500;

  @media ${devices.desktop} {
    display: none;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18.5rem;
  min-height: 100%;
  margin: 0 auto;
  padding: 6rem 0;

  @media ${devices.mobile} {
    width: 13.125rem;
    padding: 4rem 0;
  }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4.25rem;

  @media ${devices.mobile} {
    margin-top: 3.25rem;
  }
`;

const ActionLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.375rem;
  padding: 0 2em;
  color: ${colors.primary};
  font-size: 1.5rem;
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.white};
  border: solid 2px transparent;
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.white};
  }

  ${({ isOutline }) =>
    isOutline &&
    css`
      color: ${colors.white};
      background-color: transparent;
      border-color: ${colors.white};

      &:hover {
        color: ${colors.grey10};
        background-color: transparent;
        border-color: ${colors.grey10};
      }
    `}

  &:not(:first-child) {
    margin-top: 1.75rem;
  }

  @media ${devices.mobile} {
    height: 3.125rem;
    font-size: 1rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
`;

const ActionOverflow = styled.span`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Logo = styled.div`
  margin-top: 8.875rem;

  @media ${devices.mobile} {
    margin-top: 7.75rem;
  }
`;

const LogoImage = styled.img`
  display: block;
  height: 3.75rem;

  @media ${devices.mobile} {
    height: 2.5rem;
  }
`;

const CloseContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const CloseResponsive = styled(ResponsiveContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4.75rem;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 2.5rem;
  height: 2.5rem;
  color: ${colors.white};
  font-size: 2rem;
  cursor: pointer;
`;

export default function TouchNav({ isReady, user, workspace, onClose }) {
  const handleClick = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  let actions = null;

  if (isReady) {
    if (!user) {
      actions = (
        <Actions>
          <ActionLink to="/login" isOutline={true} onClick={handleClick}>
            Login
          </ActionLink>
          <ActionLink to="/signup" onClick={handleClick}>
            Sign Up
          </ActionLink>
        </Actions>
      );
    } else if (!workspace) {
      actions = (
        <Actions>
          <ActionLink to="/logout" isOutline={true} onClick={handleClick}>
            Logout
          </ActionLink>
          <ActionLink to="/signup" onClick={handleClick}>
            Create a Workspace
          </ActionLink>
        </Actions>
      );
    } else {
      actions = (
        <Actions>
          <ActionLink to="/logout" isOutline={true} onClick={handleClick}>
            Logout
          </ActionLink>
          <ActionLink to={`/app/${workspace.key}`} onClick={handleClick}>
            <ActionOverflow>Go to {workspace.name}</ActionOverflow>
          </ActionLink>
        </Actions>
      );
    }
  }

  return ReactDOM.createPortal(
    <>
      <GlobalStyle />
      <Background>
        <Navigation>
          {actions}
          <Logo>
            <Link to="/" onClick={handleClick}>
              <LogoImage src={logo} alt="Ruddr" />
            </Link>
          </Logo>
        </Navigation>
        <CloseContainer>
          <CloseResponsive>
            <Close onClick={handleClick}>
              <Icon icon="times" />
            </Close>
          </CloseResponsive>
        </CloseContainer>
      </Background>
    </>,
    document.body,
  );
}
