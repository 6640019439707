import React from 'react';
import InvoiceMilestonesTable from './InvoiceMilestonesTable';
import OtherItemsTable from './OtherItemsTable';
import ProjectExpenses from './ProjectExpenses';
import RevenueRecognitionLedger from './RevenueRecognitionLedger';

export default function AccountingTab({
  projectModel,
  onProjectExpensesChange,
  onInvoiceMilestonesChange,
  onOtherItemsChange,
  onRevenueRecognitionLedgerChange,
}) {
  return (
    <>
      {['fixed', 'fixed_recurring'].includes(projectModel.billingTypeId) && (
        <InvoiceMilestonesTable projectModel={projectModel} onChange={onInvoiceMilestonesChange} />
      )}

      {['fixed', 'fixed_recurring'].includes(projectModel.billingTypeId) &&
        ['manual', 'auto'].includes(projectModel.revenueRecognitionMethod) && (
          <RevenueRecognitionLedger projectModel={projectModel} onChange={onRevenueRecognitionLedgerChange} />
        )}

      {['tm', 'fixed', 'fixed_recurring'].includes(projectModel.billingTypeId) && (
        <OtherItemsTable projectModel={projectModel} onChange={onOtherItemsChange} />
      )}

      <ProjectExpenses projectModel={projectModel} onChange={onProjectExpensesChange} />
    </>
  );
}
