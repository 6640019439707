import {
  Confirmation,
  DateTime,
  Dropdown,
  ExportDialog,
  Icon,
  InfiniteScrollingObserver,
  Level,
  Page,
  SearchInput,
  Spinner,
  Tag,
  Tags,
  Tooltip,
} from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import _ from 'lodash';
import pluralize from 'pluralize';
import ExportDropdown from '../../settings/ExportDropdown.jsx';
import SalesforceIndicator from '../components/SalesforceIndicator';

const ContactInfo = styled.div`
  line-height: 1;
  align-items: center;
`;

const ContactName = styled.div`
  display: flex;
  align-items: center;
`;

const ContactTitle = styled.small`
  font-size: 0.75rem;
  color: ${colors.grey40};
  display: flex;
  padding-top: 0.25rem;
`;

const AddLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

const AssociatedTo = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function FirstAssignment({ assignments }) {
  if (assignments.length === 0) return null;

  const firstAssignment = assignments[0];
  let color;

  switch (firstAssignment.type) {
    case 'client':
      color = colors.primary10;
      break;
    case 'company':
      color = colors.warning10;
      break;
    case 'opportunity':
      color = colors.grey5;
      break;
    default:
      color = colors.black;
  }

  return (
    <Tag style={{ backgroundColor: color, color: colors.black }}>
      <p>{firstAssignment.name}</p>
    </Tag>
  );
}

function Assignments({ assignments }) {
  if (assignments.length <= 1) return null;

  const getColor = (type) => {
    switch (type) {
      case 'client':
        return colors.primary10;
      case 'company':
        return colors.warning10;
      case 'opportunity':
        return colors.grey5;
      default:
        return colors.black;
    }
  };

  const assignmentsByType = assignments.reduce((acc, assignment) => {
    acc[assignment.type] = acc[assignment.type] || [];
    acc[assignment.type].push(assignment);
    acc[assignment.type] = _.orderBy(acc[assignment.type], ['name'], ['asc']);

    return acc;
  }, {});

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem', marginBottom: '-0.75rem' }}>
          {_.map(assignmentsByType, (assignments, type) => (
            <div key={type} style={{ marginBottom: '1rem' }}>
              <AssociatedTo>{pluralize(type)}</AssociatedTo>
              {assignments.map((assignment, index) => (
                <Tag style={{ backgroundColor: getColor(assignment.type) }} key={index}>
                  <small>{assignment.name}</small>
                </Tag>
              ))}
            </div>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <p style={{ margin: '0.1rem' }}>+{assignments.length - 1}</p>
      </Tag>
    </Tooltip>
  );
}

export default function ContactsListPage() {
  useDocumentTitle('Contacts');

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();

  const { url } = useRouteMatch();

  const [query, setQuery] = useState({
    data: null,
    params: {
      q: '',
      page: 0,
      size: 25,
      sort: new QuerySort('name', 'asc'),
    },
    action: 'load',
    status: 'loading',
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).contacts().get(params);
      setQuery((state) => ({
        ...state,
        action: null,
        status: 'ready',
        error: null,
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
        },
      }));
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [workspace.id, api, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleDeleted() {
    setQuery((state) => ({ ...state, status: 'load' }));
    setParams({ ...params, page: 0 });
  }

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, sort: new QuerySort(column, direction) });
  };

  const handleDelete = async (contact) => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this contact?</Confirmation>
    ));

    if (!confirm) return;
    await api.www.workspaces(workspace.id).contacts(contact.id).delete();

    await handleDeleted();
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/contacts/${id}/overview/edit`);
  };

  const handleOverview = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/contacts/${id}/overview`);
  };

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .contacts()
          .export(params, {
            headers: { accept: mimeType },
            responseType: 'blob',
          })}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Section>
          <Level padding="0.5rem 0 0 0">
            <Level.Item width="20rem">
              <SearchInput
                value={params.q}
                placeholder="Search"
                materialPlaceholder="Contact Name"
                materialAlwaysVisible
                onChange={handleChange}
              />
            </Level.Item>

            <Level right>
              <Level.Item>
                <ExportDropdown>
                  {({ setIsOpen }) => (
                    <>
                      <ExportDropdown.Item
                        onClick={async () => {
                          await handleExport(`contacts.csv`, mimeTypes.csv);
                          setIsOpen(false);
                        }}>
                        Export to CSV
                      </ExportDropdown.Item>

                      <ExportDropdown.Item
                        onClick={async () => {
                          await handleExport(`contacts.xlsx`, mimeTypes.xlsx);
                          setIsOpen(false);
                        }}>
                        Export to Excel
                      </ExportDropdown.Item>
                    </>
                  )}
                </ExportDropdown>
              </Level.Item>

              {auth.pipeline.manage && (
                <Level.Item right narrow>
                  <Tooltip message="Create Contact">
                    <AddLink to={`${url}/new`} className="button">
                      <Icon icon="plus" />
                    </AddLink>
                  </Tooltip>
                </Level.Item>
              )}
            </Level>
          </Level>
        </Page.Section>

        <Page.Section>
          <Table.Status>
            {query.status !== 'ready' && <Spinner />}
            <Table.Total value={query.data.total} label="Contact" />
          </Table.Status>
          <Table>
            <Table.BoxHeader sticky>
              <Table.Column name="name" onSort={handleSort} sort={params.sort}>
                Contact
              </Table.Column>
              <Table.Column>Assigned To</Table.Column>
              <Table.Column name="email" onSort={handleSort} sort={params.sort}>
                Email
              </Table.Column>
              <Table.Column name="createdAt" onSort={handleSort} sort={params.sort} width="8rem" align="right">
                Created
              </Table.Column>
              <Table.BoxActionsColumn />
            </Table.BoxHeader>

            <Table.Body fade={query.status === 'filtering'}>
              {query.data.results.map((contact) => {
                const { id, email, name, title, createdAt, permissions, assignments, salesforceId } = contact;

                async function handleRowClick(contact) {
                  handleOverview(contact);
                }

                return (
                  <Table.BoxRow key={id} onClick={() => handleRowClick(contact)}>
                    <Table.Cell>
                      <ContactInfo>
                        <ContactName>{name}</ContactName>

                        {title && <ContactTitle>{title}</ContactTitle>}
                      </ContactInfo>
                    </Table.Cell>
                    <Table.Cell>
                      <Tags>
                        <FirstAssignment assignments={assignments} />
                        <Assignments assignments={assignments} />
                      </Tags>
                    </Table.Cell>
                    <Table.Cell>{email}</Table.Cell>
                    <Table.Cell>
                      {salesforceId && <SalesforceIndicator />}
                      <DateTime value={createdAt} />
                    </Table.Cell>
                    <TableBoxRowActions>
                      {permissions.manage ? (
                        <TableBoxRowActions.Edit onClick={() => handleEdit(contact)} />
                      ) : (
                        <TableBoxRowActions.View onClick={() => handleOverview(contact)} />
                      )}

                      <hr />

                      <TableBoxRowActions.Dropdown>
                        {({ setIsOpen }) => {
                          const handleAction = (action) => setIsOpen(false) || action;

                          return (
                            <>
                              <Dropdown.Item onClick={() => handleAction(handleOverview(contact))}>View</Dropdown.Item>

                              <Dropdown.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage ? 'Insufficient permissions to edit this contact.' : undefined
                                }
                                onClick={() => handleAction(handleEdit(contact))}>
                                Edit
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage ? 'Insufficient permissions to delete this contact.' : undefined
                                }
                                onClick={() => handleAction(handleDelete(contact))}>
                                Delete
                              </Dropdown.Item>
                            </>
                          );
                        }}
                      </TableBoxRowActions.Dropdown>
                    </TableBoxRowActions>
                  </Table.BoxRow>
                );
              })}
            </Table.Body>
          </Table>

          {query.data.total > query.data.results.length && (
            <InfiniteScrollingObserver key={query.data.results.length} onIntersecting={loadMore} />
          )}
        </Page.Section>
      </Page>
    </>
  );
}
