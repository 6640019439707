import { useApi, useToast, useWorkspace } from '~/contexts';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';

export default function XeroRedirect() {
  const api = useApi();
  const location = useLocation();
  const { selectWorkspace } = useWorkspace();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const state = new URLSearchParams(location.search).get('state');

      try {
        await api.xero.postRedirectUri({ uri: window.location.href });
        toast.success('Successfully connected with Xero');
        const workspace = await selectWorkspace(state);
        history.push(`/app/${workspace.key}/settings/integrations/xero?action=setup`);
      } catch (error) {
        toast.error(error.message);
        const workspace = await selectWorkspace(state);
        history.push(`/app/${workspace.key}/settings/integrations`);
      }
    })();
  }, [location.search, api, selectWorkspace, toast, history]);

  return <PageLoader />;
}
