import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import { Button, Field, Icon } from '~/components';
import { Form, Table, TableBoxRowActions } from '~/components/table';
import { mergeValues } from '~/utils';

function MemberMappingRowForm({ mapping, people, onCancel, onSave }) {
  const initialValues = mergeValues(
    {
      member: null,
      expensifyEmail: '',
    },
    mapping,
  );

  const onSubmit = (values) => {
    const id = mapping ? mapping.id : _.uniqueId('temp_');
    const { member, expensifyEmail } = values;
    onSave({ id, member, memberId: member.id, expensifyEmail });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        member: Yup.object().label('Ruddr Member').nullable().required(),
        expensifyEmail: Yup.string().label('Expensify Person').required(),
      })}>
      {() => {
        return (
          <Table.BoxRow focused>
            <Form>
              <Table.Cell>
                <Field.MemberSelect name="member" placeholder="Ruddr Member" clearable={false} />
              </Table.Cell>
              <Table.Cell>
                <Field.SingleSelect name="expensifyEmail" placeholder="Expensify Person">
                  {people.map((person) => (
                    <option key={person.email} value={person.email}>
                      {person.email}
                    </option>
                  ))}
                </Field.SingleSelect>
              </Table.Cell>
              <TableBoxRowActions.Form submit onCancel={onCancel} />
            </Form>
          </Table.BoxRow>
        );
      }}
    </Formik>
  );
}

function MemberMappingRowDetails({ mapping, disableActions, onEdit, onDelete }) {
  return (
    <Table.BoxRow>
      <Table.Cell>{mapping.member.name}</Table.Cell>
      <Table.Cell>{mapping.expensifyEmail}</Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />
        <hr />
        <TableBoxRowActions.Delete disabled={disableActions} onClick={() => onDelete(mapping)} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function MemberMappingRowAdd({ disableActions, onEdit }) {
  return (
    <Table.Row>
      <Table.Cell>
        <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
          <Icon icon="plus" size="xs" spaceRight />
          Add Mapping
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}

export default function MemberMappingRow({
  mapping,
  people,
  disableActions,
  isEditing,
  isNew,
  onEdit,
  onCancel,
  onSave,
  onDelete,
}) {
  if (!isEditing && isNew) {
    return <MemberMappingRowAdd disableActions={disableActions} onEdit={onEdit} />;
  } else if (!isEditing) {
    return (
      <MemberMappingRowDetails mapping={mapping} disableActions={disableActions} onEdit={onEdit} onDelete={onDelete} />
    );
  } else {
    return <MemberMappingRowForm mapping={mapping} people={people} onCancel={onCancel} onSave={onSave} />;
  }
}
