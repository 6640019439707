import {
  BillableIcon,
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  ModalCard,
  SearchInput,
  Table,
} from '~/components';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { Card } from '~/components/ModalCard';
import { useFeatures } from '~/hooks';
import Fuse from 'fuse.js';

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 1rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
  opacity: ${({ fade }) => (fade ? 0.1 : 1)};

  height: 25rem;
  overflow-y: auto;
`;

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const StyledModal = styled(ModalCard)`
  ${Card} {
    width: 64rem;
  }
`;

const StyledHeader = styled(Table.Header)`
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}
`;

function ClientRolesModal({ clientRoles, onChange, onClose }) {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const features = useFeatures();

  const [searchQuery, setSearchQuery] = useState('');

  const options = useMemo(() => {
    if (!searchQuery) return clientRoles;

    const fuse = new Fuse(clientRoles, { keys: ['name'], threshold: 0.1 });
    const results = fuse.search(searchQuery);

    return results.map((result) => result.item);
  }, [clientRoles, searchQuery]);

  const handleBulkSelectionChange = () => {
    setSelectedRoles(
      selected.some
        ? selectedRoles.filter(({ id }) => !selectedRoles.some((role) => id === role.id))
        : [...selectedRoles, ...clientRoles],
    );
  };

  const handleAdd = () => {
    onChange(selectedRoles);
    onClose();
  };

  const selected = useMemo(() => {
    if (!selectedRoles) return {};

    const rolesById = _.groupBy(clientRoles, 'id');

    return {
      roles: selectedRoles.filter(({ id }) => !!rolesById[id]),

      get some() {
        return this.roles.length > 0;
      },
      get all() {
        return this.roles.length === clientRoles.length;
      },
    };
  }, [selectedRoles, clientRoles]);

  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value);
  };

  return (
    <StyledModal title="Add Client Roles" onClose={onClose}>
      <ModalCard.Body style={{ marginBottom: 0 }}>
        <SearchInput
          value={searchQuery}
          placeholder="All"
          materialPlaceholder="Search"
          materialAlwaysVisible
          onChange={handleSearchChange}
        />
        <StyledTable>
          <StyledHeader sticky>
            <Table.Column align="center" width="3rem">
              <StyledCheckbox>
                <Checkbox
                  checked={selected.some || false}
                  partial={!selected.all || false}
                  onChange={handleBulkSelectionChange}
                />
              </StyledCheckbox>
            </Table.Column>
            <Table.Column width="17rem">Role Name</Table.Column>
            <Table.Column isVisible={features.practices} width="15rem">
              Practice
            </Table.Column>
            <Table.Column width="15rem">Location</Table.Column>
            <Table.Column align="right">Rate</Table.Column>
          </StyledHeader>
          <Table.Body>
            {options.map((role) => {
              const checked = !!selectedRoles.find((r) => r.id === role.id);

              const handleSelectionChange = () => {
                setSelectedRoles(checked ? selectedRoles.filter(({ id }) => id !== role.id) : [...selectedRoles, role]);
              };

              return (
                <Table.Row key={role.id}>
                  <Table.Cell>
                    <StyledCheckbox>
                      <Checkbox
                        tooltip="please"
                        checked={checked}
                        onChange={handleSelectionChange}
                        data-testid={`${role.id}_checkbox`}
                      />
                    </StyledCheckbox>
                  </Table.Cell>
                  <Table.Cell>{role.name}</Table.Cell>
                  <Table.Cell>{role.practice?.name}</Table.Cell>
                  <Table.Cell>{role.location?.name}</Table.Cell>
                  <Table.Cell>
                    <Currency value={role.rate} maximumFractionDigits={7} currency={role.currency} />
                    <BillableIcon style={{ marginLeft: '0.4rem' }} value={role.isBillable} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </StyledTable>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>

          <Button disabled={!selected.some} onClick={handleAdd}>
            Add
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </StyledModal>
  );
}

export default ClientRolesModal;
