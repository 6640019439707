import isFalsy from './is-falsy';
import isTruthy from './is-truthy';
import { parseDate } from './parse-date';

const defaultRules = {
  client: {
    requirementType: 'required',
    description:
      'The Name of the client associated with this ledger entry. This field must match the Name of a Client exactly, including spaces, punctuation and capitalization, otherwise the ledger entry may be skipped during import.',
    dataType: 'string',
    validator: (value) => value.trim() !== '',
  },
  project: {
    requirementType: 'required',
    description:
      'The name of the project associated with the ledger entry. This field must match the name of a project exactly, including spaces, punctuation and capitalization, otherwise the allocation may be skipped during import.',
    dataType: 'string',
    validator: (value) => value.trim() !== '',
  },
  date: {
    requirementType: 'required',
    description: 'Date of item being imported in "YYYY-MM-DD" or "MM/DD/YYYY" format.',
    dataType: 'date',
    validator: (value) => {
      return !!parseDate(value);
    },
  },
};

export default function validationRules() {
  return {
    projectLedgers: {
      'Import ID': {
        requirementType: 'required',
        description:
          'Unique identifier for the imported record. Imported row will be checked for duplicate. If a duplicate is found, the row will be skipped from import.',
        dataType: 'string',
        validator: (value) => {
          return value !== undefined && value !== null && value.trim() !== '';
        },
      },
      'Client Name': {
        requirementType: 'required',
        description:
          'The Name of the client associated with this ledger entry. This field must match the Name of a Client exactly, including spaces, punctuation and capitalization, otherwise the ledger entry may be skipped during import.',
        dataType: 'string',
        validator: (value) => value.trim() !== '',
      },
      'Project Name': {
        requirementType: 'required',
        description:
          'The name of the project associated with the ledger entry. This field must match the name of a project exactly, including spaces, punctuation and capitalization, otherwise the allocation may be skipped during import.',
        dataType: 'string',
        validator: (value) => value.trim() !== '',
      },
      Ledger: {
        requirementType: 'required',
        description: `Indicate the project ledger on which the entry should be created: ‘other’ for the Other Items to Bill ledger, ‘expense’ for the project Expenses ledger, 'milestone' for the Fixed Fee Billing Schedule, and ‘revenue” for the Manual Revenue Recognition ledger.`,
        dataType: 'string',
        validator: (value) => ['milestone', 'other', 'expense', 'revenue'].includes(value.trim().toLowerCase()),
      },
      Notes: {
        requirementType: 'optional',
        description: 'Notes or description related to the ledger item',
        dataType: 'string',
        validator: () => true,
      },
      Date: defaultRules.date,
      Amount: {
        requirementType: 'required',
        description:
          'Amount or "Fee" of the ledger item; project currency will be assumed. For "other" item, quantity and rate, if provided will be use to check Amount is valid.',
        dataType: 'number',
        validator: (value, row) => {
          const isValidAmount = value && !isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0;
          const isValidQuantity =
            row['Quantity'] !== undefined &&
            !isNaN(parseFloat(row['Quantity'])) &&
            isFinite(row['Quantity']) &&
            Number(row['Quantity']) >= 0;
          const isValidRate =
            row['Rate'] !== undefined &&
            !isNaN(parseFloat(row['Rate'])) &&
            isFinite(row['Rate']) &&
            Number(row['Rate']) >= 0;

          const matchesQuantityAndRate =
            isValidQuantity && isValidRate ? Number(value) === Number(row['Quantity']) * Number(row['Rate']) : true;

          return isValidAmount && matchesQuantityAndRate;
        },
      },
      Quantity: {
        requirementType: 'conditional',
        description: 'Quantity of the ledger item; this will only be used for "other" items to bill.',
        dataType: 'number',
        validator: (value, row) => {
          const r =
            row['Ledger'] !== 'other' ||
            (row['Ledger'] === 'other' &&
              value !== undefined &&
              !isNaN(parseFloat(value)) &&
              isFinite(value) &&
              Number(value) >= 0);
          return r;
        },
      },
      Rate: {
        requirementType: 'conditional',
        description: 'Rate of the ledger item; this will only be used for "other" items to bill.',
        dataType: 'number',
        validator: (value, row) => {
          const r =
            row['Ledger'] !== 'other' ||
            (row['Ledger'] === 'other' &&
              value !== undefined &&
              !isNaN(parseFloat(value)) &&
              isFinite(value) &&
              Number(value) >= 0);
          return r;
        },
      },
      'Invoice Item': {
        requirementType: 'conditional',
        description:
          'Invoice Item of the ledger item; required if ledger is "other" item to bill. Specify the Invoice Item that you wish to be associated with the Other Item being created on the ledger. If not found the default will be automatically assigned.',
        dataType: 'string',
        helpUrl:
          'https://help.ruddr.io/hc/en-us/articles/13007144801559-How-do-default-Invoice-Items-get-applied-in-invoices',
        validator: (value, row) => {
          const r = row['Ledger'] !== 'other' || (row['Ledger'] === 'other' && value.trim() !== '');
          return r;
        },
      },
      'Expense Category': {
        requirementType: 'conditional',
        description: 'Expense Category of the ledger item, required if ledger "expense".',
        dataType: 'string',
        helpUrl: 'https://help.ruddr.io/hc/en-us/articles/360055882494-Manage-Expense-Categories',
        validator: (value, row) => {
          const r = row['Ledger'] != 'expense' || (row['Ledger'] === 'expense' && value.trim() !== '');
          return r;
        },
      },
      Billable: {
        requirementType: 'optional',
        description: '"Yes" if you consider this item billable to the client; only used if ledger is "expense".',
        dataType: 'boolean',
        validator: (value, row) => {
          return (
            row['Ledger'] !== 'expense' ||
            (row['Ledger'] === 'expense' && (value === undefined || isTruthy(value) || isFalsy(value)))
          );
        },
      },
      Vendor: {
        requirementType: 'optional',
        description: 'Vendor related to the ledger item, only is used if ledger is expense',
        dataType: 'string',
        validator: () => true,
      },
    },
    allocations: {
      'Import ID': {
        requirementType: 'required',
        description:
          'This ID can be a text value (or numeric), but needs to be unique for each record that is imported. This value is leveraged for identifying each imported record in the event that errors are found and also designates the records as an “imported” record, as opposed to a record entered via the Ruddr application.',
        dataType: 'string',
        validator: (value) => value.trim() !== '',
      },
      'Resource Type': {
        requirementType: 'required',
        description:
          'Specify "member" or "placeholder", depending on the type of resource for which you are creating an allocation.',
        dataType: 'string',
        validator: (value) => ['member', 'placeholder'].includes(value.trim().toLowerCase()),
      },
      'Assignment Type': {
        requirementType: 'required',
        description: 'Provide a value of "project" or "time_off" for the type of allocation you are creating.',
        dataType: 'string',
        validator: (value) => ['project', 'time_off'].includes(value.trim().toLowerCase()),
      },
      'Time Off Type': {
        requirementType: 'conditional',
        description:
          '(if Assignment Type = "time_off")	If you specify an Assignment Type of "time_off", you are required to specify the Time Off Type for the allocation. Time Off Types are managed in the Custom Data section of workspace settings. If you specify a Time Off Type that has not already been created in the workspace, the record will be skipped.',
        dataType: 'string',
        validator: (value, row) => {
          const r =
            row['Assignment Type'] != 'time_off' || (row['Assignment Type'] === 'time_off' && value.trim() !== '');
          return r;
        },
      },
      'Placeholder Name': {
        requirementType: 'conditional',
        description:
          '(if Assignment Type = "placeholder")	When you specify "placeholder" as the Resource Type, you must provide the name of the Resource Placeholder, as defined in the Custom Data area of workspace settings. If a Placeholder Name is specified that has not already been created in the workspace, the record will be skipped.',
        dataType: 'string',
        validator: (value, row) =>
          row['Resource Type'] != 'placeholder' || (row['Resource Type'] === 'placeholder' && value.trim() !== ''),
      },
      'Member Email': {
        requirementType: 'conditional',
        description:
          '(if Assignment Type = "member")	When you specify "member" as the Resource Type, you are required to provide the email address associated with the workspace member for which the allocation is being created.',
        dataType: 'email',
        validator: (value, row) =>
          row['Resource Type'] != 'member' ||
          (row['Resource Type'] === 'member' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)),
      },
      'Client Name': {
        requirementType: 'conditional',
        description:
          'The Name of the client associated with this allocation. This field must match the Name of a Client exactly, including spaces, punctuation and capitalization, otherwise the allocation may be skipped during import.',
        dataType: 'string',
        validator: (value, row) => row['Assignment Type'] != 'project' || value.trim() !== '',
      },
      'Project Name': {
        requirementType: 'conditional',
        description:
          'The name of the project associated with the allocation. This field must match the name of a project exactly, including spaces, punctuation and capitalization, otherwise the allocation may be skipped during import.',
        dataType: 'string',
        validator: (value, row) => row['Assignment Type'] != 'project' || value.trim() !== '',
      },
      'Role Name': {
        requirementType: 'optional',
        description:
          'For allocations against projects that Use Roles, specify the role name for which the hours should be allocated. Note that forecasted services revenue in Ruddr for Time and Materials projects is calculated using the bill rate associated with a project role. The field must match the name of the project role exactly, otherwise the allocation may be skipped during import.',
        dataType: 'string',
        validator: () => true,
      },
      'Task Name': {
        requirementType: 'optional',
        description: 'Name of the project task, optional, if provided must match the task name within the project.',
        dataType: 'string',
        validator: () => true,
      },
      Notes: {
        requirementType: 'optional',
        description: 'Any notes that you wish to associate with the allocation.',
        dataType: 'string',
        validator: () => true,
      },
      Start: {
        requirementType: 'required',
        description: 'Start date of the allocation in "YYYY-MM-DD" or "MM/DD/YYYY" format.',
        dataType: 'date',
        validator: (value) => {
          const startDate = parseDate(value);
          return !!startDate;
        },
      },
      End: {
        requirementType: 'required',
        description: 'End date of the allocation in "YYYY-MM-DD" or "MM/DD/YYYY" format and must be after Start.',
        dataType: 'date',
        validator: (value, row) => {
          const endDate = parseDate(value);
          const startDate = parseDate(row.Start);

          if (!endDate || !startDate) {
            return false;
          }

          // Check that the end date is not before the start date
          return endDate >= startDate;
        },
      },

      Unit: {
        requirementType: 'required',
        description:
          'If allocating by Day / Week / Month, specify one of the following: "day", "week", "month". If allocating by Total Hours, specify "allocation". If allocating by Percentage of Capacity, specify "ratio_of_capacity"',
        dataType: 'string',
        validator: (value) =>
          ['day', 'week', 'month', 'allocation', 'ratio_of_capacity'].includes(value.trim().toLowerCase()),
      },
      Hours: {
        requirementType: 'required',
        description:
          'When allocating by Day / Week / Month or by Total Hours, specify the number of hours. When allocating by Percentage of Capacity, specify the percentage, in decimal format (ex:  .9 if allocating for 90% of capacity)',
        dataType: 'number',
        validator: (value) => {
          return value && !isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0;
        },
      },
      'Override Time Off': {
        requirementType: 'optional',
        description:
          '(if Assignment Type = "project")	Either a "Yes" or "No" value, indicating whether or not the allocation should be on days where the member has time off.',
        dataType: 'boolean',
        validator: (value, row) => row['Assignment Type'] != 'project' || value.trim() !== '',
      },
    },
  };
}
