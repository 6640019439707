import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  Drawer,
  Field,
  FieldControl,
  Form,
  FormMessage,
  Table,
} from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import { mergeValues, emptyStringToNull } from '~/utils';
import HolidayScheduleHolidayRow from './HolidayScheduleHolidayRow';

function HolidayScheduleDrawer({ onClose, onDelete, onSaved }) {
  const { holidayScheduleId } = useParams();

  const title = `${holidayScheduleId ? 'Edit' : 'New'} Holiday Schedule`;
  useDocumentTitle(title);
  const toast = useToast();
  const api = useApi();
  const { workspace } = useWorkspace();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const [holidays, setHolidays] = useState([]);
  const [holidaySchedule, setHolidaySchedule] = useState(null);
  const [hasDependencies, setHasDependencies] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [editForm, setEditForm] = useState(null);

  useEffect(() => {
    if (!holidayScheduleId) {
      return;
    }
    async function fetchData() {
      const { data } = await api.www.workspaces(workspace.id).holidaySchedules(holidayScheduleId).get();
      setHolidaySchedule(data);
    }
    fetchData();
    return fetchData.cancel;
  }, [api, holidayScheduleId, workspace]);

  useEffect(() => {
    if (!holidayScheduleId) {
      return;
    }
    async function fetchData() {
      const { data: hasDependencies } = await api.www
        .workspaces(workspace.id)
        .holidaySchedules(holidayScheduleId)
        .hasDependencies();

      setHasDependencies(hasDependencies);
    }
    fetchData();
    return fetchData.cancel;
  }, [api, holidayScheduleId, workspace]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.www.workspaces(workspace.id).holidays().lookup();
      setHolidays(data);
    }
    fetchData();
    return fetchData.cancel;
  }, [api, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      name: '',
      description: '',
      holidays: [],
    },
    holidaySchedule,
  );

  return (
    <Drawer
      isOpen
      title={title}
      byline="Custom Data"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values, { resetForm }) {
          try {
            form.submit(formRef.current.status.action);

            const cleanedValues = _.cloneDeep(values);
            cleanedValues.holidays = _.map(cleanedValues.holidays, (holiday) => ({ id: holiday.id }));

            const body = emptyStringToNull(cleanedValues);
            const { data } = await api.www.workspaces(workspace.id).holidaySchedules(holidaySchedule?.id).upsert(body);

            if (onSaved) {
              await onSaved(data);
            }
            form.save(formRef.current.status.action);

            switch (formRef.current.status.action) {
              case 'new':
                resetForm();
                toast.success('Holiday Schedule successfully saved.');
                break;
              case 'close':
                closeDrawer();
                break;
            }
            form.done();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const deleted = await onDelete(holidaySchedule);
          if (deleted) {
            closeDrawer();
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().label('Name').max(255).required(),
              holidays: Yup.array()
                .of(Yup.object().shape({ id: Yup.string().required() }))
                .test('unique', 'Duplicate holidays not allowed.', (values) => {
                  const ids = values.map((value) => value.id);
                  return new Set(ids).size === ids.length;
                }),
            })}>
            {(formik) => {
              const submit = async (action) => {
                formik.setStatus({ action });
                if (!editForm) {
                  formik.submitForm();
                }

                if (editForm && editForm.current) {
                  await editForm.current.submitForm();
                }
              };

              const handleDeleteHoliday = ({ id }) => {
                const holidays = _.reject(formik.values.holidays, { id });
                formik.setFieldValue('holidays', holidays);
              };

              const handleSaveHoliday = (holiday) => {
                if (!_.find(formik.values.holidays, { id: holiday.id })) {
                  const holidays = _.sortBy([...formik.values.holidays, holiday], 'name');
                  formik.setFieldValue('holidays', holidays);
                }
                setEditIndex(null);
              };

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}

                  <Form.Section title="Details">
                    <Form.Control>
                      <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                    </Form.Control>
                    <Form.Control>
                      <Field.TextArea
                        name="description"
                        placeholder="Description"
                        maxLength={5000}
                        style={{ height: '5.25rem' }}
                      />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Holidays"
                    subtitle="Changing these values may take a few minutes to be reflected within Ruddr.">
                    <FieldControl error={formik.touched.holidays && formik.errors.holidays}>
                      <Table>
                        <Table.BoxHeader>
                          <Table.Column>Holiday</Table.Column>
                          <Table.BoxActionsColumn />
                        </Table.BoxHeader>
                        <Table.Body>
                          {formik.values.holidays.map((holiday, index) => (
                            <HolidayScheduleHolidayRow
                              key={holiday.id}
                              holiday={holiday}
                              holidays={holidays}
                              selectedHolidays={formik.values.holidays}
                              isEditing={editIndex === index}
                              setEditForm={setEditForm}
                              onCancel={() => setEditIndex(null)}
                              onDelete={handleDeleteHoliday}
                              onEdit={() => setEditIndex(index)}
                              onSave={handleSaveHoliday}
                            />
                          ))}
                          <HolidayScheduleHolidayRow
                            holidays={holidays}
                            selectedHolidays={formik.values.holidays}
                            isEditing={editIndex === -1}
                            setEditForm={setEditForm}
                            onCancel={() => setEditIndex(null)}
                            onDelete={handleDeleteHoliday}
                            onEdit={() => setEditIndex(-1)}
                            onSave={handleSaveHoliday}
                          />
                        </Table.Body>
                      </Table>
                    </FieldControl>
                  </Form.Section>

                  <Drawer.Actions>
                    {holidaySchedule && !hasDependencies && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      {!holidayScheduleId && (
                        <ActionButton
                          isLoading={isSubmitting === 'new'}
                          ok={saved === 'new'}
                          type="submit"
                          onClick={() => submit('new')}>
                          Save &amp; New
                        </ActionButton>
                      )}

                      <ActionButton
                        isLoading={isSubmitting === 'close'}
                        ok={saved === 'close'}
                        type="submit"
                        onClick={() => submit('close')}>
                        Save &amp; Close
                      </ActionButton>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default HolidayScheduleDrawer;
