import { useWorkspace } from '~/contexts';
import _ from 'lodash';
import { useMemo } from 'react';

function useAuth() {
  const {
    workspace: {
      features,
      member,
      member: { securityRole },
    },
  } = useWorkspace();

  const permissions = useMemo(() => {
    return {
      workspace: { manage: securityRole.manageWorkspace },

      allocations: {
        projects: {
          view: !_.isEmpty(securityRole.viewProjectAllocations),
          manage: !_.isEmpty(securityRole.manageProjectAllocations),
        },

        members: {
          view: !_.isEmpty(securityRole.viewMemberAllocations),
          manage: !_.isEmpty(securityRole.manageMemberAllocations),
        },

        get view() {
          return this.projects.view || this.members.view;
        },

        get manage() {
          return this.projects.manage || this.members.manage;
        },
      },

      invoices: {
        manage: {
          client: !_.isEmpty(securityRole.manageDraftInvoices),
          project: !_.isEmpty(securityRole.manageProjectDraftInvoices),
        },
        view: {
          client: !_.isEmpty(securityRole.viewPublishedInvoices),
          project: !_.isEmpty(securityRole.viewProjectPublishedInvoices),
        },
      },

      payments: {
        view: !_.isEmpty(securityRole.viewPublishedInvoices),
        manage: !_.isEmpty(securityRole.managePublishedInvoices),
      },

      creditNotes: {
        view: !_.isEmpty(securityRole.viewPublishedInvoices),
        manage: !_.isEmpty(securityRole.managePublishedInvoices),
      },

      clients: {
        create: securityRole.createClients,
      },

      pipeline: {
        view: securityRole.viewPipeline,
        manage: securityRole.managePipeline,
      },

      projects: {
        create: securityRole.createProjects,

        viewRevenue: !_.isEmpty(securityRole.viewProjectRevenue),
        viewMargin: !_.isEmpty(securityRole.viewProjectMargin),

        viewBudgetHours: !_.isEmpty(securityRole.viewProjectTimeAndExpenses),
        viewBudgetRevenue: !_.isEmpty(securityRole.viewProjectRevenue) && !_.isEmpty(securityRole.viewProjectRates),

        viewHealth: !_.isEmpty(securityRole.editProjects),
      },

      time: {
        approve:
          !_.isEmpty(securityRole.manageMemberTimeAndExpenses) ||
          !_.isEmpty(securityRole.manageProjectTimeAndExpenses) ||
          member.isTimeOffApprover,
      },

      members: {
        viewTimeAndExpenses: !_.isEmpty(securityRole.viewMemberTimeAndExpenses),
        manageTimeAndExpenses: !_.isEmpty(securityRole.manageMemberTimeAndExpenses),
      },

      expenses: {
        view: !_.isEmpty(securityRole.viewMemberTimeAndExpenses) || !_.isEmpty(securityRole.viewProjectTimeAndExpenses),
        approve:
          !_.isEmpty(securityRole.manageMemberTimeAndExpenses) || !_.isEmpty(securityRole.manageProjectTimeAndExpenses),
      },

      reports: {
        performanceByProject:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByProjectAndMember:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByProjectAndTask:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByClient:
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always'),

        performanceByClientPractice:
          features.practices &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always'),

        performanceByProjectPractice:
          features.practices &&
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByMember:
          !_.isEmpty(securityRole.viewMemberTimeAndExpenses) &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always') &&
          securityRole.viewProjectMargin.includes('always'),

        performanceByMemberPractice:
          features.practices &&
          !_.isEmpty(securityRole.viewMemberTimeAndExpenses) &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always') &&
          securityRole.viewProjectMargin.includes('always'),

        hourlyFees:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        fixedFeeRevenue:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) && !_.isEmpty(securityRole.viewProjectRevenue),

        uninvoicedRevenue:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        get financial() {
          return (
            this.performanceByMember ||
            this.performanceByProject ||
            this.performanceByProjectAndMember ||
            this.performanceByProjectAndTask ||
            this.performanceByClientPractice ||
            this.performanceByProjectPractice ||
            this.hourlyFees ||
            this.uninvoicedRevenue ||
            this.fixedFeeRevenue ||
            !_.isEmpty(securityRole.viewProjectPublishedInvoices) ||
            !_.isEmpty(securityRole.viewProjectRevenue)
          );
        },

        hoursForecast: features.allocations && features.forecast,

        performanceForecastByProject:
          features.forecast && !_.isEmpty(securityRole.viewProjectRates) && !_.isEmpty(securityRole.viewProjectRevenue),

        revenueForecast:
          features.forecast && !_.isEmpty(securityRole.viewProjectRates) && !_.isEmpty(securityRole.viewProjectRevenue),

        utilizationForecastByMember:
          features.allocations && features.forecast && !_.isEmpty(securityRole.viewMemberAllocations),

        utilizationForecastByMemberPractice:
          features.practices &&
          features.allocations &&
          features.forecast &&
          !_.isEmpty(securityRole.viewMemberAllocations),

        get forecast() {
          return (
            this.hoursForecast ||
            this.performanceForecastByProject ||
            this.revenueForecast ||
            this.utilizationForecastByMember ||
            this.utilizationForecastByMemberPractice
          );
        },

        hoursVarianceByMemberAndProject: features.allocations && features.forecast,
        hoursVarianceByProjectAndResource:
          features.allocations &&
          features.forecast &&
          !_.isEmpty(securityRole.viewProjectAllocations) &&
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses),

        hoursVarianceByProjectAndRole:
          features.allocations &&
          features.forecast &&
          !_.isEmpty(securityRole.viewProjectAllocations) &&
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses),

        get variance() {
          return (
            this.hoursVarianceByMemberAndProject ||
            this.hoursVarianceByProjectAndResource ||
            this.hoursVarianceByProjectAndRole
          );
        },

        utilization: !_.isEmpty(securityRole.viewMemberTimeAndExpenses),

        projectHealthByProject: features.projectHealth && !_.isEmpty(securityRole.editProjects),

        pipeline: securityRole.viewPipeline && features.pipeline,
      },
    };
  }, [features, member.isTimeOffApprover, securityRole]);

  return permissions;
}

export default useAuth;
