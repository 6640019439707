import { Table, HelpMessage } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { PeriodsFooterRow, SettingsSection } from '../components';
import CostPeriodRow from './CostPeriodRow';

const initialState = { isReady: false, data: null };
const handlers = {
  ready: ({ data }) => ({ isReady: true, dialog: null, data, editIndex: null }),
  edit: (editIndex) => ({ editIndex }),
};

function CostPeriods({ member }) {
  useDocumentTitle(`${member.name} Cost`);

  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, data, editIndex }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).members(member.id).costPeriods().get();

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, member.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!isReady && !data) return <PageLoader />;
  if (!data) return <ErrorPage publicSite={false} />;
  return (
    <>
      <HelpMessage spaceTop spaceBottom>
        For employees, divide the annual salary by 2,080 hours to get the labor cost per hour and optionally provide
        overhead cost per hour.
        <br />
        Changing these values may take a few minutes to be reflected within Ruddr.
      </HelpMessage>
      <SettingsSection>
        <Table>
          <Table.BoxHeader>
            <Table.Column>Date Range</Table.Column>
            <Table.Column>Currency</Table.Column>
            <Table.Column width="12rem" align="right">
              Labor Cost/HR
            </Table.Column>
            <Table.Column width="12rem" align="right">
              Overhead Cost/HR
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Total Cost/HR
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Days in Period
            </Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {data.map((item, index) => (
              <CostPeriodRow
                key={item.id}
                period={item}
                periods={data}
                member={member}
                disableActions={editIndex !== null}
                isEditing={editIndex === index}
                onEdit={() => actions.edit(index)}
                onCancel={() => actions.edit(null)}
                onSaved={fetchData}
                onDeleted={fetchData}
              />
            ))}

            <CostPeriodRow
              period={{}}
              periods={data}
              member={member}
              isEditing={editIndex === -1}
              disableActions={editIndex !== null}
              onEdit={() => actions.edit(-1)}
              onCancel={() => actions.edit(null)}
              onSaved={fetchData}
            />
            <PeriodsFooterRow periods={data} member={member} label="cost" />
          </Table.Body>
        </Table>
      </SettingsSection>
    </>
  );
}

export default CostPeriods;
