import { useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import intervalOptions, { sortIntervals } from '~/utils/intervalOptions';
import intervalsByScope from '~/utils/intervalsByScope';
import { events, sources, entities } from '../lookups';

export default function useReportsSearchParamsConfig() {
  const searchParamsConfig = useSearchParamsConfig();

  const config = useMemo(
    () => ({
      period: {
        default: intervalOptions.this_month_to_date,
        keys: ['start', 'end'],
        serialize: (value) => ({ start: value.start ?? 'not_set', end: value.end ?? 'not_set' }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('start');
          if (!moment(start, true).isValid()) {
            if (start !== 'not_set') urlSearchParams.delete('start');
            start = null;
          }

          let end = urlSearchParams.get('end');
          if (!moment(end, true).isValid()) {
            if (end !== 'not_set') urlSearchParams.delete('end');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      completedPeriod: {
        default: intervalOptions.all_dates,
        keys: ['completedOnFrom', 'completedOnTo'],
        serialize: (value) => ({ completedOnFrom: value.start ?? null, completedOnTo: value.end ?? null }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('completedOnFrom');
          if (!moment(start, true).isValid()) {
            if (start !== null) urlSearchParams.delete('completedOnFrom');
            start = null;
          }

          let end = urlSearchParams.get('completedOnTo');
          if (!moment(end, true).isValid()) {
            if (end !== null) urlSearchParams.delete('completedOnTo');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      createdPeriod: {
        default: intervalOptions.all_dates,
        keys: ['createdStart', 'createdEnd'],
        serialize: (value) => ({ createdStart: value.start ?? null, createdEnd: value.end ?? null }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('createdStart');
          if (!moment(start, true).isValid()) {
            if (start !== null) urlSearchParams.delete('createdStart');
            start = null;
          }

          let end = urlSearchParams.get('createdEnd');
          if (!moment(end, true).isValid()) {
            if (end !== null) urlSearchParams.delete('createdEnd');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      closedPeriod: {
        default: intervalOptions.all_dates,
        keys: ['closedStart', 'closedEnd'],
        serialize: (value) => ({ closedStart: value.start ?? null, closedEnd: value.end ?? null }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('closedStart');
          if (!moment(start, true).isValid()) {
            if (start !== null) urlSearchParams.delete('closedStart');
            start = null;
          }

          let end = urlSearchParams.get('closedEnd');
          if (!moment(end, true).isValid()) {
            if (end !== null) urlSearchParams.delete('closedEnd');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      modifiedPeriod: {
        default: intervalOptions.all_dates,
        keys: ['modifiedStart', 'modifiedEnd'],
        serialize: (value) => ({ modifiedStart: value.start ?? null, modifiedEnd: value.end ?? null }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('modifiedStart');
          if (!moment(start, true).isValid()) {
            if (start !== null) urlSearchParams.delete('modifiedStart');
            start = null;
          }

          let end = urlSearchParams.get('modifiedEnd');
          if (!moment(end, true).isValid()) {
            if (end !== null) urlSearchParams.delete('modifiedEnd');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      memberActivePeriod: {
        default: intervalOptions.all_dates,
        keys: ['memberActiveStart', 'memberActiveEnd'],
        serialize: (value) => ({ memberActiveStart: value.start ?? null, memberActiveEnd: value.end ?? null }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('memberActiveStart');
          if (!moment(start, true).isValid()) {
            if (start !== null) urlSearchParams.delete('memberActiveStart');
            start = null;
          }

          let end = urlSearchParams.get('memberActiveEnd');
          if (!moment(end, true).isValid()) {
            if (end !== null) urlSearchParams.delete('memberActiveEnd');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      invoiceServicesThroughPeriod: {
        default: intervalOptions.all_dates,
        keys: ['invoiceServicesThroughStart', 'invoiceServicesThroughEnd'],
        serialize: (value) => ({
          invoiceServicesThroughStart: value.start ?? null,
          invoiceServicesThroughEnd: value.end ?? null,
        }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('invoiceServicesThroughStart');
          if (!moment(start, true).isValid()) {
            if (start !== null) urlSearchParams.delete('invoiceServicesThroughStart');
            start = null;
          }

          let end = urlSearchParams.get('invoiceServicesThroughEnd');
          if (!moment(end, true).isValid()) {
            if (end !== null) urlSearchParams.delete('invoiceServicesThroughEnd');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      startPeriod: {
        default: intervalOptions.all_dates,
        keys: ['startFrom', 'startTo'],
        serialize: (value) => ({ startFrom: value.start ?? null, startTo: value.end ?? null }),
        deserialize: (value, urlSearchParams) => {
          let start = urlSearchParams.get('startFrom');
          if (!moment(start, true).isValid()) {
            if (start !== null) urlSearchParams.delete('startFrom');
            start = null;
          }

          let end = urlSearchParams.get('startTo');
          if (!moment(end, true).isValid()) {
            if (end !== null) urlSearchParams.delete('startTo');
            end = null;
          }

          const option = _.find(sortIntervals(intervalOptions), (i) => i.start === start && i.end === end);
          return {
            start,
            end,
            key: option ? option.key : intervalOptions.custom.key,
            unit: option ? option.unit : intervalOptions.custom.unit,
          };
        },
      },

      utilization: {
        memberBillableType: {
          default: 'billable',
          valid: ['all', ...searchParamsConfig.memberBillableType.valid],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },

        byMonth: {
          period: {
            default: intervalOptions.this_month,
            keys: ['start', 'end'],
            serialize: (value) => ({ start: value.start, end: value.end }),
            deserialize: (value, urlSearchParams) => {
              let start = urlSearchParams.get('start');
              let end = urlSearchParams.get('end');

              if (!start || !moment(start, true).isValid() || !end || !moment(end, true).isValid()) {
                urlSearchParams.delete('start');
                urlSearchParams.delete('end');

                const option = intervalOptions.this_month;
                return { start: option.start, end: option.end, key: option.key, unit: option.unit };
              }

              const option = _.find(intervalsByScope.month, (i) => i.start === start && i.end === end);
              return {
                start,
                end,
                key: option ? option.key : intervalOptions.this_month.key,
                unit: option ? option.unit : intervalOptions.this_month.unit,
              };
            },
          },
        },
      },

      history: {
        entities: {
          multi: true,
          key: 'entities',
          valid: _.map(entities, (v) => v.id),
          serialize: (value) => value?.map((v) => v.id),
          deserialize: (ids) => ids.map((id) => entities[id]),
        },

        events: {
          multi: true,
          key: 'events',
          valid: _.map(events, (v) => v.id),
          serialize: (value) => value?.map((v) => v.id),
          deserialize: (ids) => ids.map((id) => events[id]),
        },

        sources: {
          multi: true,
          key: 'sources',
          valid: _.map(sources, (v) => v.id),
          serialize: (value) => value?.map((v) => v.id),
          deserialize: (ids) => ids.map((id) => sources[id]),
        },
      },
    }),
    [searchParamsConfig],
  );

  return config;
}
