import { Icon } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-self: center;
  height: 100%;
  flex: 1;
  padding: 0 1.25rem 1.75rem;
`;

const Bar = styled.div`
  align-self: center;
  display: flex;
  height: 2.5rem;
  background: ${colors.grey10};
  position: relative;
  border-radius: 1rem;
  padding: 0.25rem;
  width: 100%;
`;

const MeterValue = styled.div`
  flex-grow: ${({ flex }) => flex};
  flex-basis: 1rem;
  background-color: ${({ color }) => color};
  height: 2rem;
  transition: 200ms all ease-out;

  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 0;
`;

const Label = styled.div`
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

function Value({ value, ...props }) {
  if (!value) return null;
  return <MeterValue flex={value} {...props} />;
}

function Meter({ children }) {
  const dataPoints = React.Children.map(children, (child) => child.props);

  return (
    <Container>
      <Legend>
        {dataPoints.map(({ label, color }) => {
          return (
            <Label key={label}>
              <Icon icon="circle" color={color} spaceRight />
              {label}
            </Label>
          );
        })}
      </Legend>
      <Bar>{children}</Bar>
    </Container>
  );
}

Meter.Value = Value;

export default Meter;
