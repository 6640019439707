import {
  ActionButton,
  Buttons,
  CancelButton,
  Checkbox,
  Confirmation,
  Field,
  Form,
  FormMessage,
  InvoiceVariablesDialog,
  PromptNavigation,
  Radio,
  Stack,
} from '~/components';
import { ControlTooltip, SectionTitle } from '~/components/Form';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik, useFormikContext } from 'formik';
import { useDocumentTitle, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import { emptyStringToNull, getFileBytes, mergeValues } from '~/utils';
import * as Yup from 'yup';

const ContiguousInvoiceNumberControl = styled.div`
  ${ControlTooltip} {
    top: 0.25rem;
  }

  margin-bottom: 0.75rem;
`;

const Page = styled.div`
  ${SectionTitle} {
    min-width: 17rem;
    flex: 0.6;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ControlLabel = styled.p`
  display: flex;
  color: ${colors.grey75};

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.375rem -0.75rem;

  > label {
    margin: 0.375rem 0.75rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const SaveButton = styled(ActionButton)`
  width: 8.25rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContainerLabel = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ContainerControl = styled.span`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
`;

const VariablesContainer = styled.div`
  margin-top: ${({ marginTop }) => marginTop || '2rem'};
  display: flex;
  flex-direction: column;

  > a {
    margin-left: auto;
  }
`;

const StyledLink = styled.a`
  margin-top: -1rem;
  margin-bottom: -0.5rem;
`;

const expenseGroups = {
  project: 'Project',
  category: 'Category',
  category_and_person: 'Category and Person',
  person: 'Person',
};

const expenseFields = {
  date: 'Date',
  category: 'Category',
  vendor: 'Vendor',
  person: 'Person',
  notes: 'Notes',
};

const taxableItems = {
  services: 'Services',
  expenses: 'Expenses',
  other_items: 'Other Items to Bill',
};

function EnforceTimeReportRestrictions() {
  const { values, setFieldValue } = useFormikContext();
  const { invoiceGroupTimeReportBy, invoiceTimeReportFields } = values;

  useEffect(() => {
    const fields = [];
    if (
      ['person', 'role_and_person'].includes(invoiceGroupTimeReportBy) &&
      !invoiceTimeReportFields.includes('person')
    ) {
      fields.push('person');
    }
    if (['role', 'role_and_person'].includes(invoiceGroupTimeReportBy) && !invoiceTimeReportFields.includes('role')) {
      fields.push('role');
    }
    if (fields.length > 0) {
      setFieldValue('invoiceTimeReportFields', [...invoiceTimeReportFields, ...fields]);
    }
  }, [invoiceGroupTimeReportBy, invoiceTimeReportFields, setFieldValue]);

  return null;
}

function WorkspaceInvoicesForm() {
  useDocumentTitle('Invoice Settings');

  const api = useApi();
  const { workspace, updateWorkspace } = useWorkspace();
  const [{ isSubmitting, saved, status, message }, form] = useForm();
  const invoiceSubjectRef = useRef(null);
  const invoiceNotesRef = useRef(null);
  const invoiceEmailSubjectRef = useRef(null);
  const invoiceEmailBodyRef = useRef(null);

  const confirmation = useConfirmation();

  const initialValues = mergeValues(
    {
      creditNoteEmailBody: '',
      includeInvoiceLogo: false,
      invoiceEmailBody: '',
      invoiceEmailFromName: '',
      invoiceEmailSubject: '',
      invoiceEmailReplyTo: '',
      invoiceImage: null,
      invoiceImageUrl: '',
      invoiceNotes: '',
      invoiceSubject: '',
      invoiceIncludeTimeReport: false,
      invoiceGroupTimeReportBy: 'role',
      invoiceTimeReportFields: [],
      invoiceDisplayColumns: [],
      invoiceIncludePdfAttachment: false,
      invoiceIncludeTimeReportPdfAttachment: false,
      invoiceGroupTime: true,
      invoiceGroupTimeBy: 'role',
      invoiceTimeFields: [],
      invoiceIncludeTimeDateRange: false,
      invoiceIncludeReceipts: false,
      invoiceGroupExpenses: false,
      invoiceGroupExpensesBy: 'category',
      invoiceExpenseFields: [],
      invoiceTaxableItems: [],
      invoiceTaxRate: null,
      streetAddress: '',
      useContiguousInvoiceNumbers: false,
    },
    workspace,
  );

  if (!initialValues.invoiceImageUrl && workspace.imageUrl) {
    initialValues.invoiceImageUrl = workspace.imageUrl;
  }

  async function handleSubmit(values) {
    try {
      form.submit();

      if (values.invoiceImage || values.invoiceImageUrl !== initialValues.invoiceImageUrl) {
        let file = null;
        if (values.invoiceImage) {
          const bytes = await getFileBytes(values.invoiceImage);
          const type = values.invoiceImage.type;
          file = { bytes, type };
        }
        await api.www.workspaces(workspace.id).setInvoiceImage(file);
      }

      const body = emptyStringToNull({
        ..._.omit(values, ['invoiceImage', 'invoiceImageUrl', 'invoiceTaxRate']),
        invoiceTaxRateId: values.invoiceTaxRate?.id ?? null,
      });

      const { data } = await api.www.workspaces(workspace.id).patch(body);

      updateWorkspace(data);

      form.save();
    } catch (error) {
      form.error(error);
    }
  }

  return (
    <Page>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          creditNoteEmailBody: Yup.string().label('Credit Note Email Body').max(5000),
          includeInvoiceLogo: Yup.bool(),
          invoiceEmailBody: Yup.string().label('Invoice Email Body').max(5000),
          invoiceEmailFromName: Yup.string().label('From Name').max(255),
          invoiceEmailSubject: Yup.string().label('Invoice Email Subject').max(255),
          invoiceEmailReplyTo: Yup.string().label('Reply To').email().max(255).required(),
          invoiceImage: Yup.mixed()
            .label('Invoice Logo')
            .when('includeInvoiceLogo', (includeInvoiceLogo, schema) =>
              includeInvoiceLogo
                ? schema.test('invoiceImage', 'Invoice Logo is a required field.', function (value, context) {
                    return value || context.parent.invoiceImageUrl;
                  })
                : schema,
            ),
          invoiceNotes: Yup.string().label('Invoice Note').max(5000),
          invoiceSubject: Yup.string().label('Invoice Subject').max(255),
          streetAddress: Yup.string().label('Invoice Street Address').max(5000),
        })}>
        {({ values, errors, touched, dirty, setFieldValue, validateField, resetForm, submitForm }) => {
          const handleCancel = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <Confirmation resolve={resolve}>This will discard all changes. Are you sure?</Confirmation>
            ));
            if (!confirm) return;

            resetForm();
          };

          function handleTimeReportFieldsChange(name) {
            let fields;
            if (values.invoiceTimeReportFields.some((v) => v === name)) {
              fields = values.invoiceTimeReportFields.filter((v) => v !== name);
            } else {
              fields = [...values.invoiceTimeReportFields, name];
            }
            setFieldValue('invoiceTimeReportFields', fields);
          }

          function handleInvoiceDisplayColumnsChange(name) {
            let fields;
            if (values.invoiceDisplayColumns.some((v) => v === name)) {
              fields = values.invoiceDisplayColumns.filter((v) => v !== name);
            } else {
              fields = [...values.invoiceDisplayColumns, name];
            }
            setFieldValue('invoiceDisplayColumns', fields);
          }

          const handleTimeFieldChange = (name) => {
            let fields;
            if (values.invoiceTimeFields.some((v) => v === name)) {
              fields = values.invoiceTimeFields.filter((v) => v !== name);
            } else {
              fields = [...values.invoiceTimeFields, name];
            }
            setFieldValue('invoiceTimeFields', fields);
          };

          const handleExpenseFieldChange = (name) => {
            let fields;
            if (values.invoiceExpenseFields.some((v) => v === name)) {
              fields = values.invoiceExpenseFields.filter((v) => v !== name);
            } else {
              fields = [...values.invoiceExpenseFields, name];
            }
            setFieldValue('invoiceExpenseFields', fields);
          };

          const handleTaxableItemsChange = (name) => {
            let fields;
            if (values.invoiceTaxableItems.some((v) => v === name)) {
              fields = values.invoiceTaxableItems.filter((v) => v !== name);
            } else {
              fields = [...values.invoiceTaxableItems, name];
            }
            setFieldValue('invoiceTaxableItems', fields);
          };

          const handleImageChange = (file, imageUrl) => {
            setFieldValue('invoiceImage', file);

            if (!imageUrl && workspace.imageUrl) {
              setFieldValue('invoiceImageUrl', workspace.imageUrl);
            } else {
              setFieldValue('invoiceImageUrl', imageUrl);
            }

            const error = touched.invoiceImage && errors.invoiceImage;
            if (error) {
              validateField('invoiceImage');
            }
          };

          const getRefByName = (name) => {
            switch (name) {
              case 'invoiceSubject':
                return invoiceSubjectRef;
              case 'invoiceNotes':
                return invoiceNotesRef;
              case 'invoiceEmailSubject':
                return invoiceEmailSubjectRef;
              case 'invoiceEmailBody':
                return invoiceEmailBodyRef;
              default:
                return null;
            }
          };

          const handleOpenInvoiceVariablesDialog = async (name) => {
            await confirmation.prompt((resolve) => (
              <InvoiceVariablesDialog
                onClose={() => resolve(true)}
                onSaved={(variables) => {
                  const ref = getRefByName(name);

                  let selectionStart = ref.current.selectionStart;
                  if (!touched[name]) {
                    selectionStart = values[name].length;
                  }

                  const updatedValue =
                    values[name].slice(0, selectionStart) + variables.join(' ') + values[name].slice(selectionStart);

                  setFieldValue(name, updatedValue);
                  resolve(true);
                }}
              />
            ));
          };

          return (
            <Stack>
              <Form.Section
                title="Company Information"
                subtitle="These settings affect every invoice created by Ruddr. These settings can't be overridden on a per-invoice basis.">
                <Form.Control help="This is the street address of your company that you want to show on each invoice.">
                  <Field.TextArea name="streetAddress" placeholder="Invoice Street Address" maxLength={5000} rows={4} />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox name="includeInvoiceLogo" label="Include logo at the top of each invoice" />
                </Form.Control>

                {values.includeInvoiceLogo && (
                  <Form.Control>
                    <Field.ImageFileInput
                      name="invoiceImage"
                      urlFieldName="invoiceImageUrl"
                      label="Logo"
                      allowRemove={values.invoiceImage || values.invoiceImageUrl !== workspace.imageUrl}
                      onChange={handleImageChange}
                    />
                  </Form.Control>
                )}
              </Form.Section>

              <Form.Section
                title="Basic Invoice Defaults"
                subtitle="Several of these settings can be inherited or overwritten at both the client and project levels. Tax Rates are defined within the Custom Data area of workspace settings.">
                <ContiguousInvoiceNumberControl>
                  <Form.Control help="If an invoice is deleted, Ruddr will reuse the invoice number in order to maintain a contiguous set of invoice numbers.">
                    <Field.Checkbox name="useContiguousInvoiceNumbers" label="Use contiguous invoice numbers" />
                  </Form.Control>
                </ContiguousInvoiceNumberControl>

                <Form.Control>
                  <Field.TaxRateSelect name="invoiceTaxRate" placeholder="Tax Rate" />
                </Form.Control>

                <ControlLabel>The following items are taxable by default:</ControlLabel>

                <Form.Control>
                  <Checkboxes>
                    {_.map(taxableItems, (value, key) => (
                      <Checkbox
                        key={key}
                        label={value}
                        checked={values.invoiceTaxableItems.some((v) => v === key)}
                        onChange={() => handleTaxableItemsChange(key)}
                      />
                    ))}
                  </Checkboxes>
                </Form.Control>

                <VariablesContainer marginTop={'1.2rem'}>
                  <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceSubject')}>
                    Insert Variables
                  </StyledLink>
                  <Form.Control>
                    <Field.Text
                      ref={invoiceSubjectRef}
                      name="invoiceSubject"
                      placeholder="Invoice Subject"
                      maxLength={255}
                      onChange={(e) => setFieldValue('invoiceSubject', e.target.value)}
                    />
                  </Form.Control>
                </VariablesContainer>

                <VariablesContainer>
                  <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceNotes')}>
                    Insert Variables
                  </StyledLink>
                  <Form.Control>
                    <Field.TextArea
                      ref={invoiceNotesRef}
                      name="invoiceNotes"
                      placeholder="Invoice Note"
                      maxLength={5000}
                      onChange={(e) => setFieldValue('invoiceNotes', e.target.value)}
                    />
                  </Form.Control>
                </VariablesContainer>

                <Form.Control>
                  <Field.Checkbox
                    name="invoiceIncludeTimeReport"
                    label="Include a time detail report on the digital version of an invoice"
                  />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox
                    name="invoiceIncludeReceipts"
                    label="Include receipts for expenses on the digital version of an invoice"
                  />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox
                    name="invoiceIncludePdfAttachment"
                    label="Attach a PDF version of the invoice on the invoice email"
                  />
                </Form.Control>

                <Form.Control>
                  <Field.Checkbox
                    name="invoiceIncludeTimeReportPdfAttachment"
                    label="Attach a PDF of the detailed time report on the invoice email"
                  />
                </Form.Control>
                <Container style={{ marginTop: '1rem' }}>
                  <ContainerLabel>Include these columns on the published invoice:</ContainerLabel>

                  <ContainerControl style={{ marginTop: '0.5rem' }}>
                    <Form.Control>
                      <Checkboxes>
                        <Checkbox
                          label="Item"
                          checked={values.invoiceDisplayColumns.some((v) => v === 'item')}
                          onChange={() => handleInvoiceDisplayColumnsChange('item')}
                        />
                        <Checkbox
                          label="Details"
                          checked={values.invoiceDisplayColumns.some((v) => v === 'details')}
                          onChange={() => handleInvoiceDisplayColumnsChange('details')}
                        />
                        <Checkbox
                          label="Quantity"
                          checked={values.invoiceDisplayColumns.some((v) => v === 'quantity')}
                          onChange={() => handleInvoiceDisplayColumnsChange('quantity')}
                        />
                        <Checkbox
                          label="Rate"
                          checked={values.invoiceDisplayColumns.some((v) => v === 'rate')}
                          onChange={() => handleInvoiceDisplayColumnsChange('rate')}
                        />
                        <Checkbox label="Amount" checked={true} disabled={true} />
                        <Checkbox
                          label="Tax"
                          checked={values.invoiceDisplayColumns.some((v) => v === 'tax')}
                          onChange={() => handleInvoiceDisplayColumnsChange('tax')}
                        />
                      </Checkboxes>
                    </Form.Control>
                  </ContainerControl>
                </Container>
              </Form.Section>

              <Form.Section
                title="Time Presentation Defaults"
                subtitle="Select the default time entry formatting for invoices.">
                <Form.Control>
                  <Field.RadioGroup name="invoiceGroupTime">
                    <Radio value={true} label="Group time entries on invoices" />
                    <Radio value={false} label="Show all time entries on invoices" />
                  </Field.RadioGroup>
                </Form.Control>
                {values.invoiceGroupTime ? (
                  <>
                    <ControlLabel>Group by:</ControlLabel>
                    <Form.Control>
                      <Field.RadioGroup name="invoiceGroupTimeBy">
                        <Radio value="project" label="Project" />
                        <Radio value="role" label="Project Role" />
                        <Radio value="role_and_person" label="Project Role and Person" />
                        <Radio value="task" label="Project Task" />
                        <Radio value="task_and_person" label="Project Task and Person" />
                        <Radio value="person" label="Person" />
                      </Field.RadioGroup>
                    </Form.Control>
                    <Form.Control>
                      <Field.Checkbox
                        name="invoiceIncludeTimeDateRange"
                        label="Include the time entry date range on grouped invoice line items"
                      />
                    </Form.Control>
                  </>
                ) : (
                  <>
                    <ControlLabel>Include these fields:</ControlLabel>
                    <Form.Control>
                      <Checkboxes>
                        <Checkbox
                          label="Date"
                          checked={values.invoiceTimeFields.some((v) => v === 'date')}
                          onChange={() => handleTimeFieldChange('date')}
                        />
                        <Checkbox
                          label="Role"
                          checked={values.invoiceTimeFields.some((v) => v === 'role')}
                          onChange={() => handleTimeFieldChange('role')}
                        />
                        <Checkbox
                          label="Task"
                          checked={values.invoiceTimeFields.some((v) => v === 'task')}
                          onChange={() => handleTimeFieldChange('task')}
                        />
                        <Checkbox
                          label="Person"
                          checked={values.invoiceTimeFields.some((v) => v === 'person')}
                          onChange={() => handleTimeFieldChange('person')}
                        />
                        <Checkbox
                          label="Notes"
                          checked={values.invoiceTimeFields.some((v) => v === 'notes')}
                          onChange={() => handleTimeFieldChange('notes')}
                        />
                      </Checkboxes>
                    </Form.Control>
                  </>
                )}
              </Form.Section>

              <Form.Section
                title="Expense Presentation Defaults"
                subtitle="Select the default expense item formatting for invoices.">
                <Form.Control>
                  <Field.RadioGroup name="invoiceGroupExpenses">
                    <Radio value={true} label="Group expense items on invoices" />
                    <Radio value={false} label="Show all expense items on invoices" />
                  </Field.RadioGroup>
                </Form.Control>

                {values.invoiceGroupExpenses ? (
                  <>
                    <ControlLabel>Group by:</ControlLabel>
                    <Form.Control>
                      <Field.RadioGroup name="invoiceGroupExpensesBy">
                        {_.map(expenseGroups, (value, key) => (
                          <Radio key={key} value={key} label={value} />
                        ))}
                      </Field.RadioGroup>
                    </Form.Control>
                  </>
                ) : (
                  <>
                    <ControlLabel>Include these fields:</ControlLabel>
                    <Form.Control>
                      <Checkboxes>
                        {_.map(expenseFields, (value, key) => (
                          <Checkbox
                            key={key}
                            label={value}
                            checked={values.invoiceExpenseFields.some((v) => v === key)}
                            onChange={() => handleExpenseFieldChange(key)}
                          />
                        ))}
                      </Checkboxes>
                    </Form.Control>
                  </>
                )}
              </Form.Section>

              <Form.Section
                title="Time Detail Report Defaults"
                subtitle="The defaults used for the time detail report that can optionally be included with the digital version of an invoice.">
                <EnforceTimeReportRestrictions />
                <ControlLabel>Group by:</ControlLabel>
                <Form.Control>
                  <Field.RadioGroup name="invoiceGroupTimeReportBy">
                    <Radio value="role" label="Project Role" />
                    <Radio value="role_and_person" label="Project Role and Person" />
                    <Radio value="person" label="Person" />
                  </Field.RadioGroup>
                </Form.Control>
                <ControlLabel>Include these columns:</ControlLabel>
                <Form.Control>
                  <Checkboxes>
                    <Checkbox
                      label="Billable Flag"
                      checked={values.invoiceTimeReportFields.some((v) => v === 'billable')}
                      onChange={() => handleTimeReportFieldsChange('billable')}
                    />
                    <Checkbox
                      label="Date"
                      checked={values.invoiceTimeReportFields.some((v) => v === 'date')}
                      onChange={() => handleTimeReportFieldsChange('date')}
                    />
                    <Checkbox
                      label="Person"
                      disabled={['person', 'role_and_person'].includes(values.invoiceGroupTimeReportBy)}
                      checked={values.invoiceTimeReportFields.some((v) => v === 'person')}
                      onChange={() => handleTimeReportFieldsChange('person')}
                    />
                    <Checkbox
                      label="Project"
                      checked={values.invoiceTimeReportFields.some((v) => v === 'project')}
                      onChange={() => handleTimeReportFieldsChange('project')}
                    />
                    <Checkbox
                      label="Role"
                      disabled={['role', 'role_and_person'].includes(values.invoiceGroupTimeReportBy)}
                      checked={values.invoiceTimeReportFields.some((v) => v === 'role')}
                      onChange={() => handleTimeReportFieldsChange('role')}
                    />
                    <Checkbox
                      label="Task"
                      checked={values.invoiceTimeReportFields.some((v) => v === 'task')}
                      onChange={() => handleTimeReportFieldsChange('task')}
                    />
                    <Checkbox
                      label="Notes"
                      checked={values.invoiceTimeReportFields.some((v) => v === 'notes')}
                      onChange={() => handleTimeReportFieldsChange('notes')}
                    />
                  </Checkboxes>
                </Form.Control>
              </Form.Section>

              <Form.Section
                title="Invoice Email Defaults"
                subtitle="Provide the default reply-to email address and text for email invoices.">
                <Form.Control help='The name to display in the "from" part of the email. If not provided, "Ruddr" will be used.'>
                  <Field.Text name="invoiceEmailFromName" placeholder="From Name" maxLength={255} />
                </Form.Control>

                <Form.Control help='In order to ensure deliverability, all emails will come from invoices@ruddr.io, but this will be used as the "Reply-To" email adddress.'>
                  <Field.Text name="invoiceEmailReplyTo" placeholder="Reply To" maxLength={255} />
                </Form.Control>

                <VariablesContainer marginTop={'1.2rem'}>
                  <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceEmailSubject')}>
                    Insert Variables
                  </StyledLink>
                  <Form.Control>
                    <Field.Text
                      ref={invoiceEmailSubjectRef}
                      name="invoiceEmailSubject"
                      placeholder="Invoice Email Subject"
                      maxLength={255}
                      onChange={(e) => setFieldValue('invoiceEmailSubject', e.target.value)}
                    />
                  </Form.Control>
                </VariablesContainer>

                <VariablesContainer>
                  <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceEmailBody')}>
                    Insert Variables
                  </StyledLink>
                  <Form.Control>
                    <Field.TextArea
                      ref={invoiceEmailBodyRef}
                      name="invoiceEmailBody"
                      placeholder="Invoice Email Body"
                      maxLength={5000}
                      onChange={(e) => setFieldValue('invoiceEmailBody', e.target.value)}
                    />
                  </Form.Control>
                </VariablesContainer>

                <Form.Control>
                  <Field.TextArea name="creditNoteEmailBody" placeholder="Credit Note Email Body" maxLength={5000} />
                </Form.Control>
              </Form.Section>

              <Form.Actions>
                <div />
                <ActionsContainer>
                  <div>{status && <FormMessage.Error>{message}</FormMessage.Error>}</div>
                  <Buttons style={{ marginLeft: '1rem' }}>
                    <CancelButton disabled={!dirty} onClick={handleCancel}>
                      Cancel
                    </CancelButton>
                    <SaveButton isLoading={isSubmitting} ok={saved} onClick={submitForm}>
                      Save
                    </SaveButton>
                  </Buttons>
                </ActionsContainer>
              </Form.Actions>
              <PromptNavigation when={dirty} />
            </Stack>
          );
        }}
      </Formik>
    </Page>
  );
}

export default WorkspaceInvoicesForm;
