import { rgba } from 'polished';
import React, { useMemo } from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from '~/components';
import { useChat, useWorkspace } from '~/contexts';
import { useAuth, useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import AppVersion from './AppVersion';
import Maintenance from './Maintenance';
import SidebarScrollbar from './SidebarScrollbar';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: -0.25rem; /* Scrollbar offset */
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-right: 0.25rem; /* Scrollbar offset */
`;

const AreaLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.875rem 0;
  padding-left: 1.25rem;
  user-select: none;
`;

const AreaIcon = styled.div`
  display: flex;
  width: 1.5rem;
  color: ${({ isActive }) => (isActive ? colors.primary : colors.grey40)};
  font-size: 0.875rem;

  > svg {
    width: 1rem;
  }
`;

const Area = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px ${colors.grey75};
  background-color: ${({ isActive }) => (isActive ? rgba(colors.white, 0.08) : 'transparent')};

  &:hover {
    ${AreaIcon} {
      color: ${({ isActive }) => (isActive ? colors.primary : colors.white)};
    }
  }
`;

const AreaName = styled.div`
  color: ${colors.white};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  line-height: 1;
  text-transform: uppercase;
`;

const AreaBadge = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  margin-left: auto;
  margin-right: 0.5rem;
  background-color: ${colors.danger};
  border-radius: 50%;
`;

const Menu = styled.div`
  margin-top: -0.375rem;
  padding-bottom: 0.375rem;
  color: ${colors.white};
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: 1;
  text-transform: uppercase;
`;

const MenuLink = styled(NavLink)`
  flex-shrink: 0;
  display: flex;
  padding: 0.4375rem 0;
  padding-left: 2.75rem;
  padding-right: 0.5rem;
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
    font-weight: ${weights.bold};
  }

  &.is-active {
    font-weight: ${weights.bold};
    background-color: ${rgba(colors.white, 0.08)};
  }
`;

const MenuLinkText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const MenuLinkBadge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1rem;
  height: 1rem;
  margin: -0.1875rem 0;
  margin-left: auto;
  padding: 0 0.25rem;
  color: ${colors.white};
  font-size: 0.5625rem;
  font-weight: ${weights.bold};
  background-color: ${colors.danger};
  border-radius: 999rem;
`;

const NavFooter = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-right: 0.25rem; /* Scrollbar offset */
`;

const HelpLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.25rem;
  color: ${colors.grey25};
  font-size: 0.75rem;
  font-weight: ${weights.bold};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

function NavArea({ name, icon, path, areaId, navAreaId, badgeCount, onClick, children }) {
  const { workspace } = useWorkspace();
  const basePath = useMemo(() => `/app/${workspace?.key}`, [workspace]);
  const isActive = useMemo(() => areaId === navAreaId, [areaId, navAreaId]);
  const isOpen = useMemo(() => areaId === navAreaId, [areaId, navAreaId]);
  return (
    <Area isActive={isActive} onClick={onClick} data-area={areaId}>
      <AreaLink to={path ? `${basePath}${path}` : basePath}>
        <AreaIcon isActive={isActive}>
          <Icon icon={icon} />
        </AreaIcon>
        <AreaName className="area-name">{name}</AreaName>
        {badgeCount > 0 && <AreaBadge />}
      </AreaLink>
      {children && isOpen && <Menu>{children}</Menu>}
    </Area>
  );
}

function NavItem({ name, path, badgeCount, ...props }) {
  const match = useRouteMatch();
  const stopProp = (event) => event.stopPropagation();
  return (
    <MenuLink
      to={`${match.url}${path}`}
      className="item-link"
      activeClassName="is-active"
      onClick={stopProp}
      {...props}>
      <MenuLinkText>{name}</MenuLinkText>
      {badgeCount > 0 && <MenuLinkBadge>{badgeCount < 100 ? badgeCount : '99+'}</MenuLinkBadge>}
    </MenuLink>
  );
}

export default function ExpandedMenu({ navAreaId, timeApprovalsCount, expenseApprovalsCount }) {
  const auth = useAuth();
  const chat = useChat();
  const features = useFeatures();
  const { workspace } = useWorkspace();
  const isBillingLocked = !workspace.billingStatus?.isValid;
  const isDisabled = workspace.statusId === 'disabled';

  const isTimeActive = (_, location) =>
    ['month', 'week', 'day', 'list'].some((v) => location.pathname.includes(`/time/${v}`));

  if (isBillingLocked || isDisabled) {
    return (
      <Container>
        <Nav>
          <NavArea name="Home" icon="home" areaId="home" navAreaId={navAreaId} />
          {!isDisabled && auth.workspace.manage && (
            <NavArea name="Settings" icon="cog" areaId="settings" navAreaId={navAreaId} path="/settings/billing" />
          )}
        </Nav>
        <NavFooter>
          <AppVersion />
          <HelpLink onClick={() => chat.open()}>Need Help?</HelpLink>
        </NavFooter>
      </Container>
    );
  }

  return (
    <Container>
      <SidebarScrollbar hideTracksWhenNotNeeded={true}>
        <Nav>
          <NavArea name="Home" icon="home" areaId="home" navAreaId={navAreaId}>
            <NavItem name="Dashboard" path="/dashboard" />
            <NavItem name="My Projects" path="/my-projects" />
            <NavItem name="My Tasks" path="/my-tasks" />
            {features.allocations && <NavItem name="My Allocations" path="/my-allocations" />}
            <NavItem name="My Exports" path="/my-exports" />
            <NavItem name="My Profile" path="/my-profile" />
          </NavArea>
          {features.pipeline && auth.pipeline.view && (
            <NavArea
              name="Pipeline"
              icon="filter-circle-dollar"
              path="/pipeline/opportunities"
              areaId="pipeline"
              navAreaId={navAreaId}>
              <NavItem name="Opportunities" path="/pipeline/opportunities" />
              <NavItem name="Companies" path="/pipeline/companies" />
              <NavItem name="Contacts" path="/pipeline/contacts" />
            </NavArea>
          )}
          <NavArea name="Projects" icon="tasks" path="/projects" areaId="projects" navAreaId={navAreaId}>
            <NavItem name="Projects" path="/projects" />
            <NavItem name="Clients" path="/clients" />
          </NavArea>
          <NavArea
            name="Time"
            icon="clock"
            path="/time"
            areaId="time"
            navAreaId={navAreaId}
            badgeCount={timeApprovalsCount}>
            <NavItem name="Time Entries" path="/time" isActive={isTimeActive} />
            {auth.time.approve && <NavItem name="Approvals" path="/time/approvals" badgeCount={timeApprovalsCount} />}
            <NavItem name="Auditing" path="/time/auditing" />
            {features.timesheets && <NavItem name="Timesheets" path="/time/timesheets" />}
          </NavArea>
          {features.expenseReporting && (
            <NavArea
              name="Expenses"
              icon="receipt"
              path="/expenses"
              areaId="expenses"
              navAreaId={navAreaId}
              badgeCount={expenseApprovalsCount}>
              <NavItem name="Expense Reports" path="/expenses/reports" />
              {auth.expenses.approve && (
                <NavItem name="Approvals" path="/expenses/approvals" badgeCount={expenseApprovalsCount} />
              )}
              <NavItem name="Auditing" path="/expenses/auditing" />
            </NavArea>
          )}
          {auth.invoices.view.project && (
            <NavArea name="Billing" icon="file-invoice" path="/billing" areaId="billing" navAreaId={navAreaId}>
              {auth.invoices.manage.project && <NavItem name="Ready to Bill" path="/billing/ready-to-bill" />}
              <NavItem name="Invoices" path="/billing/invoices" />
              {auth.payments.view && <NavItem name="Payments" path="/billing/payments" />}
              {auth.creditNotes.view && <NavItem name="Credit Notes" path="/billing/credit-notes" />}
            </NavArea>
          )}
          {features.allocations && auth.allocations.view && (
            <NavArea name="Resources" icon="users" path="/resources" areaId="resources" navAreaId={navAreaId}>
              <NavItem name="Allocations" path="/resources/allocations" />
            </NavArea>
          )}
          <NavArea name="Reports" icon="chart-line" path="/reports" areaId="reports" navAreaId={navAreaId}>
            <NavItem name="Time" path="/reports/time" />
            <NavItem name="Expenses" path="/reports/expenses" />
            {features.utilization && <NavItem name="Utilization" path="/reports/utilization" />}
            {auth.reports.forecast && <NavItem name="Forecast" path="/reports/forecast" />}
            {auth.reports.variance && <NavItem name="Variance" path="/reports/variance" />}
            {auth.reports.financial && <NavItem name="Financial" path="/reports/financial" />}
            {features.pipeline && auth.pipeline.view && <NavItem name="Pipeline" path="/reports/pipeline" />}
            <NavItem name="Workspace" path="/reports/workspace" />
          </NavArea>
          {auth.workspace.manage && (
            <NavArea name="Settings" icon="cog" path="/settings" areaId="settings" navAreaId={navAreaId}>
              <NavItem name="Workspace" path="/settings" exact />
              <NavItem name="Invoices" path="/settings/invoices" />
              <NavItem name="Members" path="/settings/members" />
              <NavItem name="Security Roles" path="/settings/security-roles" />
              <NavItem name="Custom Data" path="/settings/custom-data" />
              <NavItem name="Features" path="/settings/features" />
              <NavItem name="Integrations" path="/settings/integrations" />
              <NavItem name="API Keys" path="/settings/api-keys" />
              <NavItem name="Authentication" path="/settings/authentication" />
              <NavItem name="Billing" path="/settings/billing" />
            </NavArea>
          )}
        </Nav>
        <NavFooter>
          <AppVersion />
          <Maintenance />
          <HelpLink onClick={() => chat.open()}>Need Help?</HelpLink>
        </NavFooter>
      </SidebarScrollbar>
    </Container>
  );
}
