import salesforceIcon from '~/assets/public/salesforce-logo.png';
import { InlineTooltip } from '~/components';
import { useConfirmation, useIntegrations, useSubscription, useWorkspace } from '~/contexts';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import RemoveSalesforceConfirmation from './RemoveSalesforceConfirmation';
import SalesforceSetup from './SalesforceSetup';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 10.25rem;
  height: 9.375rem;
  margin: 1.25rem 0.625rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border: solid 0.125rem ${colors.white};
  border-radius: 0.3125rem;
  overflow: hidden;
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: 2.625rem;
`;

const Name = styled.p`
  margin-top: 0.75rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
`;

const Actions = styled.div`
  display: flex;
  height: 2rem;
  background-color: ${colors.grey10};
`;

const ActionStyle = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0;
  color: ${colors.black};
  font-size: 0.75rem;
  font-weight: ${weights.normal};
  background-color: ${colors.grey5};
  border-radius: 0;

  :not(:first-child) {
    margin-left: 1px;
  }

  :hover {
    color: ${colors.black};
    background-color: ${colors.grey10};
  }
`;

// const ActionAnchor = styled.a`
//   ${ActionStyle}

//   &:disabled {
//     box-shadow: none;
//     opacity: 0.5;

//     :hover {
//       cursor: not-allowed;
//     }
//   }
// `;

const ActionButton = styled.button`
  ${ActionStyle}

  position: relative;

  &:disabled {
    box-shadow: none;
    opacity: 0.5;

    :hover {
      cursor: not-allowed;
    }
  }
`;

export default function SalesforceIntegration({ integration, onChange }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const confirmation = useConfirmation();
  const { notify } = useSubscription();
  const integrations = useIntegrations();
  const { workspace } = useWorkspace();
  const [setupIntegration, setSetupIntegration] = useState(false);

  const handleRemove = () => {
    confirmation.prompt((resolve) => (
      <RemoveSalesforceConfirmation
        onClose={resolve}
        onRemove={() => {
          if (typeof onChange === 'function') {
            onChange();
          }
          notify(useSubscription.keys.integrations_changed);
          resolve(true);
        }}
      />
    ));
  };

  const handleFinish = () => {
    if (typeof onChange === 'function') {
      onChange();
    }
    history.push(`/app/${workspace.key}/settings/integrations/salesforce`);
  };

  return (
    <Item>
      <Description>
        <Image src={salesforceIcon} />
        <Name>Salesforce</Name>
      </Description>
      {integration.isConnected ? (
        <Actions>
          <ActionButton onClick={() => history.push(`${url}/salesforce`)}>Settings</ActionButton>
          <ActionButton onClick={handleRemove}>Remove</ActionButton>
        </Actions>
      ) : (
        <Actions>
          {/* <ActionAnchor
            // TODO: update link to Salesforce article
            href="https://help.ruddr.io/hc/en-us/articles/1500000607101-Salesforce-integration-overview"
            target="_blank"
            rel="noopener noreferrer">
            Details
          </ActionAnchor> */}
          {integrations?.salesforce ? (
            <ActionButton>
              <InlineTooltip
                message={
                  <>Only one CRM integration can be active. Remove other CRM integrations to install Salesforce.</>
                }
              />
              Install
            </ActionButton>
          ) : (
            <ActionButton onClick={() => setSetupIntegration(true)}>Install</ActionButton>
          )}
        </Actions>
      )}
      {setupIntegration && <SalesforceSetup onClose={() => setSetupIntegration(false)} onFinish={handleFinish} />}
    </Item>
  );
}
