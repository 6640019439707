import {
  Avatar,
  Button,
  Currency,
  DateTime,
  Duration,
  Grid,
  Icon,
  Level,
  MemberContactPopover,
  MultilineText,
  Page,
  PipelineActivityTypeFilter,
  RouteLink,
  SearchInput,
  Stack,
  Tooltip,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useNumberFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import PipelineActivityForm from '../activity/PipelineActivityForm';
import { ActivityItem } from '../components/ActivityItem';
import OpportunityBoxes from './OpportunitySummaryBoxes';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: ${weights.light};
  }
`;

const Separator = styled.div`
  margin: 2rem -2rem;
  position: relative;
  border-top: 1px solid ${colors.grey25};

  + ${Section} {
    margin-top: 0;
  }
`;

const Panel = styled.div`
  position: absolute;
  width: calc(20% + 2.5rem);
  background: ${colors.grey5};
  right: -2rem;
  top: calc(-2.5rem - 1px);
  min-height: calc(100% + 4.5rem + 1px);
  padding: 2.75rem 2rem;
`;

const Info = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.375rem;
  font-size: 0.875rem;
  flex: 1;
`;

const NewButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

const StatusSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const NextSteps = styled.div`
  display: flex; // Changed to flex for full width
  align-items: center;
  justify-content: flex-start; // Updated for standard CSS value
  padding: 0.5em 1.5em;
  border-radius: 0.25rem;
  background-color: ${colors.primary10};
  color: ${colors.accent};
  font-weight: ${weights.bold};
  font-size: 0.75rem;
  text-transform: uppercase;
  flex-grow: 1; // This will make the div grow to available space
  width: 100%; // Full width of the parent
  white-space: nowrap; // Keeps the text on a single line
  overflow: hidden; // Hides overflow

  .stepsContent {
    text-transform: none;
    color: ${colors.grey100};
    font-weight: ${weights.normal};
    overflow: hidden; // Ensures the overflow text is hidden
    white-space: nowrap; // Keeps the text on a single line
    text-overflow: ellipsis; // Adds an ellipsis to text that overflows
    display: block; // Converts span from inline to block-level for width control
    width: 100%; // Ensures the span takes the full width of its parent
  }
`;

const PriorityIndicator = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1.5em;
  border-radius: 0.25rem;
  background-color: ${colors.grey10};
  color: ${colors.grey75};
  font-weight: bold;
  font-size: 0.75rem;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 0.5rem;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.div`
  font-size: 0.875rem;
  margin-left: 0.5rem;

  .icon {
    margin-right: 0.5rem;
  }
`;

function InfoBlock({ title, children, ...props }) {
  return (
    <Info {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </Info>
  );
}

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey25};
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
`;

function OpportunityDetailsPage({ opportunity, onChange }) {
  const documentTitle = useDocumentTitle(opportunity.name);

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const [company, setCompany] = useState(null);
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [showActivities, setShowActivities] = useState({ upcoming: true, last30Days: true, olderThan30Days: true });
  const [status, setStatus] = useState('loading');
  const [dialog, setDialog] = useState(null);
  const [params, setParams] = useState({ q: '', typeIds: [] });

  const fetchData = useCallback(async () => {
    try {
      const { data: activities } = await api.www
        .workspaces(workspace.id)
        .pipelineActivities()
        .get({
          opportunityId: opportunity.id,
          q: params.q,
          typeId: params.typeIds.length ? params.typeIds.map((v) => v.id) : [],
        });

      const groups =
        activities &&
        activities.reduce((a, v) => {
          let activityDate = moment(v.date);
          let now = moment();
          let thirtyDaysAgo = moment().subtract(30, 'days');
          let key;

          if (activityDate.isAfter(now)) {
            key = 'upcoming';
          } else if (activityDate.isAfter(thirtyDaysAgo)) {
            key = 'last30Days';
          } else {
            key = 'olderThan30Days';
          }

          a[key] = a[key] || { key, activities: [] };
          a[key].activities.push(v);
          return a;
        }, {});

      const groupOrder = ['upcoming', 'last30Days', 'olderThan30Days'];
      const orderedGroups = _.orderBy(groups, (group) => groupOrder.indexOf(group.key));

      const options = [];
      for (const group of orderedGroups) {
        options.push({ type: 'group', group, count: group.activities.length });
        group.activities.forEach((activity) => {
          options.push({ type: 'activity', activity, group });
        });
      }

      setGroupedOptions(options);
      setStatus('ready');
    } catch (error) {
      setGroupedOptions(null);
    }
  }, [api, opportunity, params.q, params.typeIds, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).companies(opportunity.company.id).get();
        setCompany(data);
      } catch (err) {
        // Do nothing
      }
    })();
  }, [api, workspace.id, opportunity.company.id]);

  const handleChange = ({ target: { name, value } }) => {
    setStatus('filtering');
    setParams((params) => ({ ...params, [name]: value }));
  };

  function handleOpenActivityForm({ activity }) {
    setDialog(
      <PipelineActivityForm
        opportunity={opportunity}
        activity={activity}
        onClose={() => {
          setDialog(null);
          documentTitle.set(opportunity.name);
        }}
        onSaved={() => {
          setDialog(null);
          documentTitle.set(opportunity.name);
          fetchData();
          onChange();
        }}
      />,
    );
  }

  const toggleShowActivities = (key) => {
    setShowActivities((current) => ({
      ...current,
      [key]: !current[key],
    }));
  };

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if (!opportunity) return <ErrorPage.NotFound />;

  return (
    <Page.Section style={{ position: 'relative', flex: 1 }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="75%">
            <Section>
              {opportunity && <OpportunityBoxes data={opportunity} />}
              <StatusSection>
                {opportunity.priorityId && (
                  <PriorityIndicator>
                    {{ high: 'High Priority', medium: 'Medium Priority', low: 'Low Priority' }[opportunity.priorityId]}
                  </PriorityIndicator>
                )}
                {opportunity.nextSteps && (
                  <NextSteps>
                    Next Steps &nbsp;<span className="stepsContent">{opportunity.nextSteps}</span>
                  </NextSteps>
                )}
              </StatusSection>
              <Level>
                <Level.Item>
                  <h3>Activities</h3>
                </Level.Item>
                <Level.Item right>
                  <SearchInput
                    value={params.q}
                    placeholder="Search"
                    materialPlaceholder="Subject or Content"
                    materialAlwaysVisible
                    onChange={handleChange}
                  />
                </Level.Item>
                <Level.Item>
                  <PipelineActivityTypeFilter
                    name="typeIds"
                    value={params.typeIds}
                    entity="opportunity"
                    onChange={handleChange}
                  />
                </Level.Item>
                {auth.pipeline.manage && (
                  <Level.Item right narrow>
                    <Tooltip message="Add Activity">
                      <NewButton onClick={() => handleOpenActivityForm({})}>
                        <Icon color={colors.primary} icon="plus" />
                      </NewButton>
                    </Tooltip>
                  </Level.Item>
                )}
              </Level>
              <Stack style={{ marginTop: '3rem', opacity: `${status === 'filtering' ? 0.2 : 1}` }}>
                {status === 'loading' && <PageLoader />}

                {groupedOptions?.length > 0 &&
                  groupedOptions.map((o) => {
                    switch (o.type) {
                      case 'group':
                        return (
                          <GroupHeader key={o.group.key} onClick={() => toggleShowActivities(o.group.key)}>
                            <div style={{ textTransform: 'uppercase' }}>
                              <strong>
                                {
                                  {
                                    upcoming: 'Upcoming',
                                    last30Days: 'Last 30 Days',
                                    olderThan30Days: ' Older Than 30 Days',
                                  }[o.group.key]
                                }
                              </strong>
                              <Icon icon={showActivities[o.group.key] ? 'angle-up' : 'angle-down'} spaceLeft />
                            </div>

                            <div>{pluralize('Activity', parseInt(numberFormat.format(o.count), 10), true)}</div>
                          </GroupHeader>
                        );
                      case 'activity': {
                        const item = o.activity;
                        const participants = [
                          ...item.members.map((activityMember) => activityMember.member.name),
                          ...item.contacts.map((activityContact) => activityContact.contact.name),
                        ];

                        if (!showActivities[o.group.key]) return null;

                        return (
                          <ActivityItem
                            key={item.id}
                            item={item}
                            handleOpenActivityForm={handleOpenActivityForm}
                            participants={participants}
                            entity="opportunity"
                          />
                        );
                      }
                      default:
                        return null;
                    }
                  })}
                {status === 'ready' && groupedOptions?.length === 0 && <NoResults>No Results</NoResults>}
              </Stack>
            </Section>
          </Grid.Column>
          <Grid.Column width="2rem"></Grid.Column>
        </Grid.Row>
      </Grid>

      <Panel>
        <Section>
          <h3>Opportunity Details</h3>
          {opportunity.timeSpent > 0 && (
            <InfoBlock title="Time Spent">
              <Duration minutes={opportunity.timeSpent} useDecimal={false} /> hs
            </InfoBlock>
          )}

          {opportunity.owner && (
            <InfoBlock title="Owner">
              <Owner>
                <MemberContactPopover member={opportunity.owner}>
                  <Avatar value={opportunity.owner} isCircle hasBackground initialsFontSize=".9rem" />
                </MemberContactPopover>

                <OwnerInfo>{opportunity.owner.name}</OwnerInfo>
              </Owner>
            </InfoBlock>
          )}

          {opportunity.practice && <InfoBlock title="Practice">{opportunity.practice.name}</InfoBlock>}

          {opportunity.description && (
            <InfoBlock title="Description">
              <MultilineText
                value={opportunity.description}
                chars={280}
                align="left"
                buttonProps={{ isAnchor: true }}
              />
            </InfoBlock>
          )}

          <InfoBlock title="Currency">
            {opportunity.currencyName} ({opportunity.currency})
          </InfoBlock>

          {opportunity.project?.name && (
            <InfoBlock title="Project">
              <RouteLink
                to={`/app/${workspace.key}/projects/${opportunity.project.client.key}/${opportunity.project.key}`}>
                {opportunity.project.name}
              </RouteLink>
            </InfoBlock>
          )}

          {opportunity.opportunityType?.name && <InfoBlock title="Type">{opportunity.opportunityType?.name}</InfoBlock>}

          {opportunity.opportunityLeadSource?.name && (
            <InfoBlock title="Lead Source">{opportunity.opportunityLeadSource?.name}</InfoBlock>
          )}

          {opportunity.closeDate && (
            <InfoBlock title="Expected Close">
              <DateTime value={opportunity.closeDate} />
            </InfoBlock>
          )}
        </Section>

        <Separator />

        <Section>
          <h3>Company Details</h3>
          <InfoBlock title="Company Name">
            <RouteLink to={`/app/${workspace.key}/pipeline/companies/${opportunity.company.id}/overview`}>
              {opportunity.company.name}
            </RouteLink>
          </InfoBlock>

          {company && (
            <>
              {company.owner && (
                <InfoBlock title="Relationship Owner">
                  <Owner>
                    <MemberContactPopover member={company.owner}>
                      <Avatar value={company.owner} isCircle hasBackground initialsFontSize=".9rem" />
                    </MemberContactPopover>

                    <OwnerInfo>{company.owner.name}</OwnerInfo>
                  </Owner>
                </InfoBlock>
              )}

              {company.client && (
                <InfoBlock title="Client">
                  <RouteLink to={`/app/${workspace.key}/clients/${company.client.key}`}>
                    {company.client.name}
                  </RouteLink>
                </InfoBlock>
              )}

              {company.industry && <InfoBlock title="Company Industry">{company.industry.name}</InfoBlock>}

              {company.practice && <InfoBlock title="Practice">{company.practice.name}</InfoBlock>}

              <InfoBlock title="Currency">
                {company.currencyName} ({company.currency})
              </InfoBlock>

              {company.revenue && (
                <InfoBlock title="Revenue">
                  <Currency value={company.revenue} currency={company.currency} />
                </InfoBlock>
              )}
            </>
          )}
        </Section>
      </Panel>
      {dialog}
    </Page.Section>
  );
}

export default OpportunityDetailsPage;
