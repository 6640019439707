import {
  BillableIcon,
  Button,
  ButtonBadge,
  ClientLink,
  Duration,
  Icon,
  InlineTooltip,
  TimeApprovalPopover,
  TimeLockIcon,
  Tooltip,
  TooltipButton,
} from '~/components';
import { useApi, useSubscription, useTimeEntries, useToast, useWorkspace } from '~/contexts';
import { useDateTimeFormat, useDocumentTitle, useTimeEntryTimer } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import CalendarPage from './CalendarPage';
import EditTimeEntry from './edit-time-entry';
import { CalendarStats } from './stats';
import ResubmitRejectedTimeButton from './timesheets/ResubmitRejectedTimeButton';
import SubmitTimesheetButton from './timesheets/SubmitTimesheetButton';
import { useTimesheets } from './timesheets/TimesheetContext';
import TimesheetSubmittedTooltip from './timesheets/TimesheetSubmittedTooltip';
import UnsubmitTimesheetButton from './timesheets/UnsubmitTimesheetButton';
import ViewTimeEntry from './view-time-entry';

// isActive is only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledButton = ({ isActive, ...props }) => <Button {...props} />;

const Week = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: flex-end;
`;

const WeekDaysContainer = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
`;

const WeekDays = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(6, 1fr) 0fr;
  margin-bottom: auto;
  background-color: ${colors.grey10};

  > * {
    background-color: ${colors.white};
  }
`;

const WeekDay = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1.5rem;

  &:first-child {
    padding-left: 0;
  }
`;

const WeekBoxLabel = styled.span`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const WeekBoxValue = styled.span`
  margin: 0.1875rem 0;
  color: ${colors.black};
  font-weight: ${weights.normal};

  ${({ isTimesheetSubmitted }) =>
    isTimesheetSubmitted &&
    css`
      color: ${colors.grey25};
    `}
`;

const WeekBoxFooter = styled.span`
  margin-top: 0.25rem;
  color: ${({ isToday }) => (isToday ? colors.primary : colors.grey40)};
  font-size: 0.625rem;
  font-weight: ${weights.medium};
  letter-spacing: 0.0625rem;
`;

const RejectedEntriesBadge = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${colors.danger};
  border-radius: 50%;
`;

const WeekDayButton = styled.button`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: ${({ isActive }) => (isActive ? '0.25rem' : '0.375rem')};
  background-color: ${({ isActive }) => (isActive ? colors.white : colors.grey5)};
  border-color: ${({ isActive }) => (isActive ? colors.primary : 'transparent')};
  border-width: ${({ isActive }) => (isActive ? '0.125rem' : '0')};
  border-radius: 0.3125rem;

  ${({ isHoliday }) =>
    isHoliday &&
    css`
      background-image: repeating-linear-gradient(
        -45deg,
        ${colors.primary10},
        ${colors.primary10} 5px,
        ${({ isActive }) => (isActive ? colors.white : colors.primary5)} 5px,
        ${({ isActive }) => (isActive ? colors.white : colors.primary5)} 10px
      );
    `}

  ${WeekBoxLabel} {
    color: ${({ isToday }) => (isToday ? colors.primary : colors.grey40)};
  }

  &:hover {
    background-color: ${({ isActive }) => (isActive ? colors.white : colors.grey10)};
    border-color: ${({ isActive }) => (isActive ? colors.primary : 'transparent')};

    ${({ isHoliday }) =>
      isHoliday &&
      css`
        background-image: repeating-linear-gradient(
          -45deg,
          ${({ isActive }) => (isActive ? colors.primary10 : colors.primary25)},
          ${({ isActive }) => (isActive ? colors.primary10 : colors.primary25)} 5px,
          ${({ isActive }) => (isActive ? colors.white : colors.primary5)} 5px,
          ${({ isActive }) => (isActive ? colors.white : colors.primary5)} 10px
        );
      `}
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${RejectedEntriesBadge} {
        top: 0.375rem;
        left: 0.375rem;
      }
    `}
`;

const WeekTotal = styled.div`
  flex: 2;
  padding-left: 0rem;
  padding-bottom: 1.125rem;
`;

const WeekTotalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.25rem;
  background-color: ${colors.grey5};
  border: solid 0.125rem transparent;
  border-radius: 0.3125rem;
`;

const Results = styled.div`
  margin-top: 0.75rem;
  margin-bottom: auto;
`;

const TimeEntryGroup = styled.div`
  flex: 1;
  margin-top: 1.625rem;
`;

const TimeEntryGroupHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: ${weights.bold};
`;

const AddTimeEntryButton = styled(TooltipButton)`
  width: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};
  }

  &:disabled,
  &:disabled:hover {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }
`;

const TimeEntries = styled.div`
  display: grid;
  grid-row-gap: 1px;
  margin-top: 0.5rem;
  background-color: ${colors.grey10};
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
`;

const TimeEntry = styled.div`
  display: flex;
  align-items: center;
  padding: 0.875rem 1.5rem;
  background-color: ${colors.white};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }

  &:hover {
    margin: -0.1875rem;
    padding: 1.0625rem 1.6875rem;
    border-radius: 0.3125rem;
    box-shadow: 0 0.1875rem 1rem ${colors.grey10};
    z-index: 1;
  }
`;

const TimeEntryInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  max-width: calc(100% - 10.625rem);
  margin: -0.5rem 0;
`;

const TimeEntryControls = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const TimeEntryData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding-right: 1.5rem;
  font-size: 0.875rem;
`;

const TimeEntryContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TimeEntrySubTitle = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TimeEntryNotes = styled.div`
  display: flex;
`;

const TimeEntryHours = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 1.875rem;
  color: ${({ status }) =>
    ({
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    })[status] || colors.primary};
  font-weight: ${weights.medium};
  background-color: ${({ status }) =>
    ({
      not_submitted: colors.grey10,
      pending_approval: colors.warning10,
      rejected: colors.danger10,
    })[status] || colors.primary10};
  border-radius: 999rem;

  .icon {
    margin-right: 0.5rem;
    font-size: 0.625rem;
    opacity: 0.5;
  }
`;

const PlayPauseButton = styled(StyledButton)`
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;
  padding-left: ${({ isActive }) => (isActive ? '0' : '0.125rem')};
  margin-left: 0.625rem;
  color: ${({ isActive }) => (isActive ? colors.white : colors.primary)};
  font-size: 0.75rem;
  background-color: ${({ isActive }) => (isActive ? colors.primary : colors.grey5)};
  position: relative;

  &&:disabled {
    color: ${colors.grey25};
    background-color: ${colors.grey5};
  }

  &:hover {
    color: ${colors.white};
    background-color: ${({ isActive }) => (isActive ? colors.accent : colors.primary)};
  }
`;

const TimeEntryLocked = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  margin-left: 0.625rem;
`;

const ResultFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
`;

const NoResults = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 3rem;
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;
`;

const NoResultsMessage = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  color: ${colors.grey40};
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const NoResultsButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 0.5rem 0;
  }
`;

const TimesheetStatusBarGutter = styled.div`
  margin-bottom: -1px;
`;

const TimesheetStatusBar = styled.div`
  position: relative;
  grid-column: ${({ $start, length }) => `${$start} / span ${length}`};
  background: ${colors.white};
  padding-bottom: 0.6rem;
  padding-right: 1.5rem;
  margin-bottom: -1px;

  > div {
    font-size: 0.75rem;
    padding: 0.1rem 0.25rem;
    text-align: center;
    border-radius: 0.3125rem;
    background-color: ${({ submitted }) => (submitted ? colors.grey40 : colors.grey5)};
    color: ${({ submitted }) => (submitted ? colors.white : colors.black)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

function TimesheetStatus({ week }) {
  const timesheetContext = useTimesheets();

  const periods = useMemo(() => {
    const days = week.map((day) => ({
      date: day.date,
      timesheet: timesheetContext.timesheets?.find((ts) => moment(day.date).isBetween(ts.start, ts.end, 'day', '[]')),
    }));

    const periods = _(days)
      .groupBy((day) => day.timesheet?.id)
      .map((group) => {
        const day = group[0];
        const date = day.date;
        const length = group.length;
        const submitted = !!day.timesheet;
        const submittedOn = day.timesheet?.submittedOn;

        return { date, length, submitted, submittedOn };
      })
      .value();

    return periods;
  }, [timesheetContext, week]);

  const dateTimeFormat = useDateTimeFormat();

  return (
    <>
      <TimesheetStatusBarGutter />

      {timesheetContext.isReady &&
        periods.map((period, index) => {
          const start = (periods[index - 1]?.length ?? 0) + 1;
          return (
            <TimesheetStatusBar
              key={period.date}
              submitted={period.submitted}
              $start={start}
              length={period.length}
              data-testid="timesheet_status_bar">
              <div>
                {period.submitted ? 'Submitted' : 'Not Submitted'}
                {period.submitted && (
                  <InlineTooltip
                    placement="top"
                    message={`Submitted on ${dateTimeFormat.format(period.submittedOn)}.`}
                  />
                )}
              </div>
            </TimesheetStatusBar>
          );
        })}

      <TimesheetStatusBarGutter />
    </>
  );
}

function WeekView({ date, setDate, week, holidays }) {
  const weekTotal = _.sumBy(week, 'minutes');
  const weekTimerStartedAt = _.find(week, (day) => !!day.timerStartedAt)?.timerStartedAt;

  const timesheetContext = useTimesheets();

  return (
    <Week>
      <WeekDaysContainer>
        <WeekDays>
          {timesheetContext.useTimesheets && <TimesheetStatus week={week} />}

          {week.map((day) => {
            const dayDate = moment(day.date);
            const dayHolidays = _.filter(holidays, (holiday) => dayDate.isSame(holiday.date, 'day'));
            const isHoliday = dayHolidays.length > 0;
            const holidayMessage = _(dayHolidays)
              .sortBy(['name'])
              .map((holiday) => <div key={holiday.id}>{holiday.name}</div>)
              .value();

            const isTimesheetSubmitted = timesheetContext.isTimesheetSubmitted({ start: day.date, end: day.date });

            const hasRejectedEntries = day.entries.some((e) => e.statusId === 'rejected');

            return (
              <Tooltip
                key={day.date}
                Container={WeekDay}
                message={
                  isHoliday ? holidayMessage : isTimesheetSubmitted ? 'Timesheet has been submitted.' : undefined
                }>
                <WeekDayButton
                  isActive={day.date === date}
                  isHoliday={isHoliday}
                  isToday={moment().isSame(day.date, 'day')}
                  onClick={() => setDate(day.date)}>
                  <WeekBoxLabel>{moment(day.date).format('ddd')}</WeekBoxLabel>
                  <WeekBoxValue isTimesheetSubmitted={isTimesheetSubmitted}>
                    <Duration minutes={day.minutes} timerStartedAt={day.timerStartedAt} />
                  </WeekBoxValue>

                  {hasRejectedEntries && <RejectedEntriesBadge />}
                </WeekDayButton>
                <WeekBoxFooter isToday={moment().isSame(day.date, 'day')}>
                  {moment(day.date).format('M/D')}
                </WeekBoxFooter>
              </Tooltip>
            );
          })}
        </WeekDays>
      </WeekDaysContainer>

      <WeekTotal>
        <WeekTotalBox>
          <WeekBoxLabel>Total</WeekBoxLabel>
          <WeekBoxValue>
            <Duration minutes={weekTotal} timerStartedAt={weekTimerStartedAt} />
          </WeekBoxValue>
        </WeekTotalBox>
      </WeekTotal>
    </Week>
  );
}

function EntriesView({ date, member, memberTargets, entries, onOpenForm, onPlayPause, onSubmit }) {
  const groupedEntries = useMemo(() => {
    const entryGroups = _.reduce(
      entries,
      (acc, entry) => {
        const { type, project } = entry;

        let obj = _.find(acc, (e) => {
          if (type.id !== e.type.id) return false;
          if (project?.client?.id !== e.client?.id) return false;
          return true;
        });

        if (!obj) {
          obj = { type, client: project?.client, entries: [] };
          acc.push(obj);
        }

        obj.entries.push(entry);
        obj.entries = _.sortBy(obj.entries, ['project.name', 'timeOffType.name', 'role.name', 'task.name', 'notes']);

        return acc;
      },
      [],
    );
    return _.sortBy(entryGroups, ['client.name']);
  }, [entries]);

  const timesheetContext = useTimesheets();
  const isTimesheetSubmitted = useMemo(
    () => timesheetContext.isTimesheetSubmitted({ start: date, end: date }),
    [timesheetContext, date],
  );

  if (!entries?.length) {
    return (
      <NoResults>
        <NoResultsMessage>No time entries available for {moment(date).format('dddd, MMMM D, YYYY')}</NoResultsMessage>
        <NoResultsButtons>
          <Button
            disabled={isTimesheetSubmitted}
            style={{ position: 'relative' }}
            onClick={() => onOpenForm({ mode: 'edit', memberId: member?.id, initialValues: { date } })}>
            New Time Entry
            {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
          </Button>

          {timesheetContext.useTimesheets && (
            <>
              <SubmitTimesheetButton date={date} member={member} onSubmit={onSubmit} />
              <UnsubmitTimesheetButton date={date} member={member} onUnsubmitTimesheet={onSubmit} />
            </>
          )}
        </NoResultsButtons>
      </NoResults>
    );
  }

  return (
    <>
      <Results>
        {groupedEntries.map((entryGroup) => (
          <TimeEntryGroup key={_.compact([entryGroup.type.id, entryGroup.client?.id]).join('_')}>
            <TimeEntryGroupHeader>
              {entryGroup.type.id === 'project_time' ? <ClientLink client={entryGroup.client} /> : <>Time Off</>}
            </TimeEntryGroupHeader>
            <TimeEntries>
              {entryGroup.entries.map((entry) => {
                let disableTimerReason;
                if (!entry.timerStartedAt) {
                  if (member) {
                    disableTimerReason = 'You can only start timers on your own time entries.';
                  } else if (
                    entry.statusId === 'pending_approval' ||
                    (entry.statusId === 'approved' && entry.statusModeId === 'manual')
                  ) {
                    disableTimerReason =
                      'You can only start timers on time entries that are either "Not Submitted", "Rejected" or "Automatically Approved".';
                  } else if (entry.invoiceId) {
                    disableTimerReason =
                      'This time entry is associated with an invoice and its timer cannot be started.';
                  } else if (entry.clientApprovalId) {
                    disableTimerReason =
                      'This time entry is associated with a client approval and its timer cannot be started.';
                  } else if (entry.typeId === 'project_time' && entry.project.recordStatusId !== 'active') {
                    disableTimerReason = 'You can only start a timer on an active project.';
                  } else if (timesheetContext.useTimesheets && entry.timesheetId) {
                    disableTimerReason =
                      'This time entry is associated with a timesheet and its timer cannot be started.';
                  }
                }
                return (
                  <TimeEntry
                    key={entry.id}
                    onClick={() =>
                      onOpenForm({
                        mode: entry.isLocked ? 'view' : 'edit',
                        id: entry.id,
                        memberId: member?.id,
                      })
                    }>
                    <TimeEntryInfo>
                      <TimeEntryData style={{ flexBasis: '25%' }}>
                        <TimeEntryContent>
                          {entry.type.id === 'project_time' ? entry.project.name : entry.timeOffType?.name}
                          {entry.role ? <TimeEntrySubTitle>{entry.role.name}</TimeEntrySubTitle> : null}
                        </TimeEntryContent>
                      </TimeEntryData>
                      <TimeEntryData style={{ flexBasis: '25%' }}>
                        {entry.task && <TimeEntryContent>{entry.task.name}</TimeEntryContent>}
                      </TimeEntryData>
                      <TimeEntryData style={{ flexBasis: '50%' }}>
                        {entry.notes ? (
                          <TimeEntryNotes>
                            "<TimeEntryContent>{entry.notes}</TimeEntryContent>"
                          </TimeEntryNotes>
                        ) : null}
                      </TimeEntryData>
                    </TimeEntryInfo>
                    <TimeEntryControls>
                      <BillableIcon value={entry.isActuallyBillable} />

                      <TimeApprovalPopover timeEntryId={entry.id}>
                        <TimeEntryHours status={entry.status?.id}>
                          <Duration
                            minutes={entry.minutes}
                            timerStartedAt={entry.timerStartedAt}
                            showSeconds={!!entry.timerStartedAt}
                            trim={!entry.timerStartedAt}
                          />
                        </TimeEntryHours>
                      </TimeApprovalPopover>

                      {entry.isLocked ? (
                        <TimeEntryLocked>
                          <TimeLockIcon value={entry.lockStatusId} />
                        </TimeEntryLocked>
                      ) : (
                        <PlayPauseButton
                          isAnchor
                          disabled={!!disableTimerReason}
                          isActive={!!entry.timerStartedAt}
                          onClick={onPlayPause.bind(this, entry)}>
                          <Icon icon={entry.timerStartedAt ? 'pause' : 'play'} />
                          {disableTimerReason && <InlineTooltip message={disableTimerReason} />}
                        </PlayPauseButton>
                      )}
                    </TimeEntryControls>
                  </TimeEntry>
                );
              })}
            </TimeEntries>
          </TimeEntryGroup>
        ))}
        <ResultFooter>
          <AddTimeEntryButton
            disabled={isTimesheetSubmitted}
            style={{ position: 'relative' }}
            onClick={() => onOpenForm({ mode: 'edit', memberId: member?.id, initialValues: { date } })}>
            <Icon icon="plus" />
            {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
          </AddTimeEntryButton>
        </ResultFooter>
      </Results>
      <CalendarStats entries={entries} startDate={date} endDate={date} member={member} memberTargets={memberTargets} />
    </>
  );
}

function DayCalendar({ view, date, setDate, member, memberTargets, setMember, getHolidays }) {
  const documentTitle = useDocumentTitle('Day Calendar');
  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const { entries, updateEntry, updateEntries, removeEntry } = useTimeEntries();
  const { startStopTimer } = useTimeEntryTimer();
  const [isReady, setIsReady] = useState(false);
  const [formProps, setFormProps] = useState(null);
  const [submittingEntries, setSubmittingEntries] = useState(false);
  const { notify } = useSubscription();

  const fetchData = useCallback(async () => {
    const query = { date };
    if (member) {
      query.memberId = member.id;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).timeEntries().getWeek(query);

      updateEntries(data);
    } finally {
      setIsReady(true);
    }
  }, [api, workspace, member, date, updateEntries]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const memberId = useMemo(() => (member ? member.id : workspace.member.id), [member, workspace]);

  const holidays = useMemo(() => {
    const start = moment(date).startOf('isoWeek');
    const end = start.clone().add(6, 'days');
    return getHolidays(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
  }, [getHolidays, date]);

  const week = useMemo(() => {
    const day = moment(date).startOf('isoWeek').subtract(1, 'days');
    return _.times(7, () => {
      const date = day.add(1, 'days').format('YYYY-MM-DD');
      const dateEntries = _.filter(entries, { memberId, date });
      const minutes = _.sumBy(dateEntries, 'minutes');
      const timerStartedAt = _.find(dateEntries, (entry) => !!entry.timerStartedAt)?.timerStartedAt;
      return { date, entries: dateEntries, minutes, timerStartedAt };
    });
  }, [memberId, date, entries]);

  const viewEntries = useMemo(() => _.filter(entries, { memberId, date }), [memberId, date, entries]);

  const unsubmittedEntryCount = useMemo(() => {
    return _.filter(
      viewEntries,
      ({ statusId, timerStartedAt }) => !timerStartedAt && (statusId === 'not_submitted' || statusId === 'rejected'),
    ).length;
  }, [viewEntries]);

  const handleCloseForm = () => {
    setFormProps(null);
    documentTitle.set('Day Calendar');
  };

  const handleOpenForm = (props) => {
    setFormProps(props);
  };

  const handleSave = async (entry) => {
    updateEntry(entry);

    if (entry.date !== date) {
      setDate(entry.date);
    }

    if (entry.timerStartedAt) {
      const previousTimer = entries.find((e) => e.id !== entry.id && e.timerStartedAt !== null);
      if (previousTimer) {
        const { data: previousEntry } = await api.www.workspaces(workspace.id).timeEntries(previousTimer.id).get();
        updateEntry(previousEntry);
      }
    }
  };

  const handleDelete = (entry) => {
    removeEntry(entry);
  };

  const handleSubmitEntries = async () => {
    setSubmittingEntries(true);

    await api.www.workspaces(workspace.id).timeEntries().submitDay({ date, memberId: member?.id });

    await fetchData();

    setSubmittingEntries(false);

    notify(useSubscription.keys.refresh_time_approval_count);

    toast.success('Time entries have been submitted for approval.');
  };

  const handlePlayPause = async (entry, event) => {
    event.stopPropagation();
    await startStopTimer(entry);
  };

  const timesheetContext = useTimesheets();
  const isTimesheetSubmitted = useMemo(
    () => timesheetContext.isTimesheetSubmitted({ start: date, end: date }),
    [timesheetContext, date],
  );

  const pageActions = viewEntries.length > 0 && (
    <>
      <Button
        isOutline
        disabled={isTimesheetSubmitted}
        style={{ position: 'relative' }}
        onClick={() => handleOpenForm({ mode: 'edit', memberId: member?.id, initialValues: { date } })}>
        New Time Entry
        {isTimesheetSubmitted && <TimesheetSubmittedTooltip />}
      </Button>

      {timesheetContext.useTimesheets ? (
        <>
          <ResubmitRejectedTimeButton date={date} period="day" member={member} onSubmit={fetchData} />
          <SubmitTimesheetButton date={date} member={member} onSubmit={fetchData} />
          <UnsubmitTimesheetButton date={date} member={member} onUnsubmitTimesheet={fetchData} />
        </>
      ) : (
        <>
          {unsubmittedEntryCount > 0 && (
            <Button isLoading={submittingEntries} onClick={handleSubmitEntries}>
              Submit for Approval <ButtonBadge visible={!submittingEntries}>{unsubmittedEntryCount}</ButtonBadge>
            </Button>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <CalendarPage
        actions={pageActions}
        view={view}
        date={date}
        setDate={setDate}
        member={member}
        setMember={setMember}>
        {isReady ? (
          <>
            <WeekView date={date} setDate={setDate} week={week} holidays={holidays} />
            <EntriesView
              date={date}
              member={member}
              memberTargets={memberTargets}
              entries={viewEntries}
              onOpenForm={handleOpenForm}
              onPlayPause={handlePlayPause}
              onSubmit={fetchData}
            />
          </>
        ) : (
          <PageLoader />
        )}
      </CalendarPage>
      {formProps &&
        {
          edit: (
            <EditTimeEntry
              member={member}
              onClose={handleCloseForm}
              onSaved={handleSave}
              onDeleted={handleDelete}
              {...formProps}
            />
          ),
          view: <ViewTimeEntry member={member} onClose={handleCloseForm} {...formProps} />,
        }[formProps.mode]}
    </>
  );
}

export default DayCalendar;
