import {
  BillableIcon,
  Checkbox,
  DateTime,
  Duration,
  Hours,
  Icon,
  InlineTooltip,
  Level,
  Spinner,
  Table,
  TimeApprovalPopover,
  Tooltip,
} from '~/components';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import {
  ApprovalAction,
  ApprovalActions,
  Approve,
  Cell,
  Details,
  List,
  ListGroup,
  ListGroupContent,
  ListGroupHeader,
  ListHeader,
  NoResultsCell,
  Notes,
  Reject,
  Row,
  SelectAll,
  ToggleSwitch,
} from './StyledComponents';
import { useFeatures } from '~/hooks';

const Byline = styled.small`
  display: block;
`;

const Time = styled.div`
  position: relative;
  padding: 0.2rem 1.5rem;
  font-weight: ${weights.medium};
  border-radius: 999rem;
  width: 6.25rem;
  text-align: center;

  &,
  &.icon {
    ${({ status }) =>
      ({
        not_submitted: css`
          background: ${colors.grey10};
          color: ${colors.black};
        `,
        pending_approval: css`
          background: ${colors.warning10};
          color: ${colors.warning};
        `,
        rejected: css`
          background: ${colors.danger10};
          color: ${colors.danger};
        `,
        approved: css`
          background: ${colors.primary10};
          color: ${colors.primary};
        `,
      })[status]}
  }
`;

const PaperClipIcon = styled(Icon)`
  color: ${colors.grey55};

  &:hover {
    color: ${colors.grey75};
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TimeEntryInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
`;

const ApproverQueueWarning = styled.div`
  position: relative;
  font-size: 1rem;
`;

function TimeApprovalResults({
  results,
  selection,
  isSubmitting,
  isApproverQueue,
  isAdminQueue,
  onSelectionChange,
  onStatusChange,
  onGroupAction,
  onResultClick,
  onChange,
  action,
}) {
  const [showNotes, setShowNotes] = useState(false);
  const features = useFeatures();

  const groups = useMemo(() => {
    return results.reduce((a, v) => {
      a[v.member.id] = a[v.member.id] || { member: v.member, entries: [] };
      a[v.member.id].entries.push(v);
      return a;
    }, {});
  }, [results]);

  const handleSelectAllChange = () => onSelectionChange(selection.length > 0 ? [] : results.map((entry) => entry.id));

  const isQueue = isApproverQueue || isAdminQueue;

  return (
    <>
      <Level>
        <Level.Item>
          <div style={{ marginBottom: '1rem', display: 'flex', position: 'relative', fontSize: '.875rem' }}>
            <Checkbox
              label="Show notes"
              checked={showNotes}
              onChange={(e) => {
                setShowNotes(e.target.checked);
              }}
            />
          </div>
        </Level.Item>

        <Level.Item>
          <Table.Status>
            {!!action && <Spinner />}
            <Table.Total value={results.length} label="Time Entry" />
          </Table.Status>
        </Level.Item>
      </Level>

      <List>
        <ListHeader>
          <Cell $width="3rem" padding="0.7rem 2rem 0.7rem 1.125rem">
            <SelectAll>
              <Checkbox
                data-testid="select-all"
                checked={selection.length > 0}
                partial={selection.length < results.length}
                disabled={isSubmitting || results.length === 0 || action === 'filter' || !isQueue}
                onChange={handleSelectAllChange}
              />
              {!isQueue && <ApproverQueueTooltip />}
            </SelectAll>
          </Cell>

          <Cell padding="0.875rem 1rem">Member</Cell>

          <Cell right $width="5rem" padding="0.875rem 1rem">
            # of Items
          </Cell>

          <Cell right $width="7rem" padding="0.875rem 1rem">
            Client Billable
          </Cell>

          <Cell right $width="8.25rem" padding="0.875rem 1rem">
            Client
            <br />
            Non-billable
          </Cell>

          <Cell right $width="7rem" padding="0.875rem 1rem">
            Internal
          </Cell>

          <Cell right $width="7rem" padding="0.875rem 1rem">
            Time Off
          </Cell>

          <Cell right $width="7rem" padding="0.875rem 1rem">
            Total
          </Cell>

          <Cell $width="8rem" style={{ justifyContent: 'flex-end' }} padding="0.875rem 1rem">
            {!isQueue && (
              <ApproverQueueWarning>
                <Icon icon="warning" color={colors.warning} />
                <ApproverQueueTooltip />
              </ApproverQueueWarning>
            )}
          </Cell>
        </ListHeader>

        {results.length === 0 && (
          <Table.Row>
            <NoResultsCell style={{ fontSize: '0.875rem' }} fade={action === 'filter'}>
              No results
            </NoResultsCell>
          </Table.Row>
        )}

        {_.map(groups, (group, key) => {
          return (
            <Group
              key={key}
              group={group}
              selection={selection}
              isSubmitting={isSubmitting}
              isApproverQueue={isApproverQueue}
              isQueue={isQueue}
              onSelectionChange={onSelectionChange}
              onAction={onGroupAction}
              action={action}>
              {group.entries.map((entry) => {
                const checked = !!selection?.includes(entry.id);

                const submitting =
                  (isSubmitting === 'batch' && selection?.includes(entry.id)) ||
                  isSubmitting?.group?.entries.some((timeEntry) => timeEntry.id === entry.id) ||
                  isSubmitting === entry.id;

                const disableCheckbox = submitting || !isQueue;
                const disableActions = submitting || !isApproverQueue;

                const handleRowClick = () => {
                  onResultClick(entry, 'edit');
                };

                const handleViewAttachments = () => {
                  onResultClick(entry, 'viewAttachments');
                };

                const handleSelectionChange = () =>
                  onSelectionChange(checked ? selection.filter((id) => id !== entry.id) : [...selection, entry.id]);

                const handleActionClick = (statusId) => {
                  onStatusChange(entry, statusId);
                };

                return (
                  <Row key={entry.id} clickable={!!handleRowClick} data-testid="row" onClick={handleRowClick}>
                    <Cell
                      flex="0"
                      $width="2.5rem"
                      padding="0.5rem 0rem 0.5rem 3.5rem"
                      style={{ position: 'relative' }}
                      onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        data-testid="select-entry"
                        checked={checked}
                        disabled={disableCheckbox}
                        onChange={handleSelectionChange}
                      />
                      {!isQueue && <ApproverQueueTooltip />}
                    </Cell>

                    <Cell $width="7.5rem">
                      <DateTime value={entry.date} />
                    </Cell>

                    <Cell>
                      <Details>
                        <div>
                          {entry.typeId === 'project_time' ? (
                            entry.project && (
                              <p>
                                {entry.project.name}
                                <Byline>{entry.project.client.name}</Byline>
                              </p>
                            )
                          ) : (
                            <p>{entry.timeOffType?.name}</p>
                          )}
                        </div>
                        <div>
                          {entry.task && <p>{entry.task.name}</p>}
                          {entry.project?.useRoles && entry.role && <p>{entry.role.name}</p>}
                        </div>
                      </Details>

                      {showNotes && entry.notes && (
                        <Notes>
                          <em>{entry.notes}</em>
                        </Notes>
                      )}
                    </Cell>

                    <Cell right $width="8rem">
                      <Container>
                        <TimeApprovalPopover timeEntryId={entry.id} showActions onChange={onChange}>
                          <TimeEntryInfo>
                            <BillableIcon value={entry.isActuallyBillable} />
                            <Time status={entry.statusId}>
                              <Duration minutes={entry.minutes} timerStartedAt={entry.timerStartedAt} trim />
                            </Time>
                          </TimeEntryInfo>
                        </TimeApprovalPopover>
                        {features.timeAttachments && entry.hasWeekFile && (
                          <Tooltip message={'Has attachments'} onClick={(e) => e.stopPropagation()}>
                            <PaperClipIcon
                              icon="paperclip-vertical"
                              type="far"
                              onClick={handleViewAttachments}
                              data-testid="paper-clip"
                            />
                          </Tooltip>
                        )}
                      </Container>
                    </Cell>

                    <Cell right $width="8rem" style={{ position: 'relative' }}>
                      <ApprovalActions onClick={(e) => e.stopPropagation()}>
                        <ApprovalAction>
                          <Approve
                            data-testid="approve"
                            disabled={disableActions}
                            status={entry.statusId}
                            onClick={() => handleActionClick('approved')}>
                            <Icon icon="thumbs-up" />
                            {!isApproverQueue && <ApproverQueueTooltip />}
                          </Approve>
                        </ApprovalAction>
                        <ApprovalAction>
                          <Reject
                            data-testid="reject"
                            disabled={disableActions}
                            status={entry.statusId}
                            onClick={() => handleActionClick('rejected')}>
                            <Icon icon="thumbs-down" />
                            {!isApproverQueue && <ApproverQueueTooltip />}
                          </Reject>
                        </ApprovalAction>
                      </ApprovalActions>
                    </Cell>
                  </Row>
                );
              })}
            </Group>
          );
        })}
      </List>
    </>
  );
}

const sum = (entry) => _.round(entry.minutes / 60, 2);

function Group({
  group,
  selection,
  isSubmitting,
  isApproverQueue,
  isQueue,
  children,
  onSelectionChange,
  onAction,
  action,
}) {
  const [collapsed, setCollapsed] = useState(true);

  const hours = useMemo(() => {
    return {
      clientBillable: _.round(
        _(group.entries)
          .filter((entry) => entry.project?.client?.isInternal === false && entry.isActuallyBillable === true)
          .sumBy(sum),
        2,
      ),
      clientNonbillable: _.round(
        _(group.entries)
          .filter((entry) => entry.project?.client?.isInternal === false && entry.isActuallyBillable === false)
          .sumBy(sum),
        2,
      ),
      internal: _.round(
        _(group.entries)
          .filter((entry) => entry.project?.client?.isInternal === true)
          .sumBy(sum),
        2,
      ),
      timeOff: _.round(
        _(group.entries)
          .filter((entry) => entry.typeId === 'time_off')
          .sumBy(sum),
        2,
      ),
      total: _.round(_(group.entries).sumBy(sum), 2),
    };
  }, [group]);

  const groupSelection = useMemo(() => {
    return selection?.filter((id) => group.entries.some((entry) => entry.id === id));
  }, [group, selection]);

  const groupSelected = useMemo(() => {
    return group.entries.some((e) => groupSelection.includes(e.id));
  }, [group, groupSelection]);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const handleGroupSelectionChange = () => {
    onSelectionChange(
      groupSelected
        ? selection.filter((id) => !group.entries.map(({ id }) => id).includes(id))
        : [...selection, ...group.entries.map(({ id }) => id)],
    );
  };

  const handleActionClick = (statusId) => {
    onAction({ ...group, entries: group.entries.filter((e) => e.statusId === 'pending_approval') }, statusId);
  };

  const submitting = isSubmitting?.group?.member.id === group.member.id || (isSubmitting === 'batch' && groupSelected);

  const disableCheckbox = submitting || !isQueue;
  const disableActions = submitting || !isApproverQueue;

  return (
    <ListGroup fade={action === 'filter'}>
      <ListGroupHeader data-testid="group" onClick={handleToggleClick}>
        <Cell flex="0" padding="0.5rem 0.25rem 0.5rem 0.75rem">
          <ToggleSwitch data-testid="group-toggle">
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Cell>

        <Cell flex="0" padding="0" style={{ position: 'relative' }} onClick={(event) => event.stopPropagation()}>
          <Checkbox
            data-testid="select-member"
            disabled={disableCheckbox}
            checked={groupSelected}
            partial={groupSelection.length < group.entries.length}
            onChange={handleGroupSelectionChange}
          />
          {!isQueue && <ApproverQueueTooltip />}
        </Cell>

        <Cell>{group.member.name}</Cell>

        <Cell right $width="5rem">
          {group.entries.length}
        </Cell>

        <Cell right color={colors.success} $width="7rem">
          <Hours value={hours.clientBillable} />
        </Cell>

        <Cell right color={colors.danger} $width="8.25rem">
          <Hours value={hours.clientNonbillable} />
        </Cell>

        <Cell right color={colors.warning} $width="7rem">
          <Hours value={hours.internal} />
        </Cell>

        <Cell right color={colors.primary50} $width="7rem">
          <Hours value={hours.timeOff} />
        </Cell>

        <Cell right $width="7rem">
          <Hours value={hours.total} />
        </Cell>

        <Cell right $width="8rem">
          <ApprovalActions onClick={(e) => e.stopPropagation()}>
            <ApprovalAction>
              <Approve data-testid="approve" disabled={disableActions} onClick={() => handleActionClick('approved')}>
                <Icon icon="thumbs-up" />
                {!isApproverQueue && <ApproverQueueTooltip />}
              </Approve>
            </ApprovalAction>
            <ApprovalAction>
              <Reject data-testid="reject" disabled={disableActions} onClick={() => handleActionClick('rejected')}>
                <Icon icon="thumbs-down" />
                {!isApproverQueue && <ApproverQueueTooltip />}
              </Reject>
            </ApprovalAction>
          </ApprovalActions>
        </Cell>
      </ListGroupHeader>

      {!collapsed && <ListGroupContent>{children}</ListGroupContent>}
    </ListGroup>
  );
}

function ApproverQueueTooltip() {
  return <InlineTooltip message="Select an Approver to enable approval actions." />;
}

export default TimeApprovalResults;
