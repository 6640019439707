import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useApi, useSession, useWorkspace } from '~/contexts';

const ImpersonationContext = React.createContext();

const latestMemberIdsKey = 'admin.latest-impersonations';

function getSavedLatestMemberIds() {
  const savedData = localStorage.getItem(latestMemberIdsKey);
  if (savedData) {
    return JSON.parse(savedData);
  }
  return [];
}

function setSavedLatestMemberIds(memberIds) {
  localStorage.setItem(latestMemberIdsKey, JSON.stringify(memberIds));
}

function ImpersonationProvider({ children }) {
  const api = useApi();
  const { identify, session, impersonatedMemberId } = useSession();
  const { selectWorkspace, clearWorkspace } = useWorkspace();

  const [impersonatedMember, setImpersonatedMember] = useState();
  const [latestMembers, setLatestMembers] = useState([]);

  const [latestMemberIds, setLatestMemberIds] = useState(() => {
    return getSavedLatestMemberIds();
  });

  const removeFromLatest = useCallback(
    (memberId) => {
      setLatestMemberIds(latestMemberIds.filter((id) => id !== memberId));
    },
    [latestMemberIds],
  );

  useEffect(() => {
    setSavedLatestMemberIds(latestMemberIds);
  }, [latestMemberIds]);

  useEffect(() => {
    if (!impersonatedMemberId) {
      setImpersonatedMember();
      return;
    }
    const getMember = async () => {
      const { data } = await api.admin.members.getById(impersonatedMemberId);
      setImpersonatedMember(data);
    };
    getMember();
    return getMember.cancel;
  }, [api, impersonatedMemberId]);

  useEffect(() => {
    const fetchMembers = async () => {
      const { data } = await api.admin.members.getByIds(latestMemberIds);
      setLatestMembers(_.sortBy(data, (member) => _.indexOf(latestMemberIds, member.id)));

      for (const latestMemberId of latestMemberIds) {
        if (!_.find(data, { id: latestMemberId })) {
          removeFromLatest(latestMemberId);
        }
      }
    };
    if (latestMemberIds && latestMemberIds.length > 0) {
      fetchMembers();
    } else {
      setLatestMembers([]);
    }
    return fetchMembers.cancel;
  }, [api, latestMemberIds, removeFromLatest]);

  const updateLatestImpersonated = (memberId) => {
    // If the ID is already in the array, push it to the front
    if (latestMemberIds.includes(memberId)) {
      const newMemberIds = latestMemberIds.filter((id) => id !== memberId);
      newMemberIds.unshift(memberId);
      setLatestMemberIds(newMemberIds);
    } else {
      const newMemberIds = [memberId, ...latestMemberIds];
      if (newMemberIds.length > 20) {
        setLatestMemberIds(newMemberIds.slice(0, 20));
      } else {
        setLatestMemberIds(newMemberIds);
      }
    }
  };

  const impersonate = async (memberId) => {
    const { data } = await api.admin.members.impersonate(memberId);
    if (data) {
      window.scrollTo({ top: 0 });
      updateLatestImpersonated(memberId);
      await identify();
      await selectWorkspace(data.workspaceId);
    }
  };

  const stopImpersonation = async () => {
    await api.admin.members.stopImpersonation();
    await identify();
    if (session.memberSessions) {
      await selectWorkspace();
    } else {
      await clearWorkspace();
    }
  };

  return (
    <ImpersonationContext.Provider
      value={{
        impersonatedMemberId,
        impersonatedMember,
        latestMemberIds,
        latestMembers,
        impersonate,
        stopImpersonation,
        removeFromLatest,
      }}>
      {children}
    </ImpersonationContext.Provider>
  );
}

function useImpersonation() {
  return useContext(ImpersonationContext);
}

export { ImpersonationContext, useImpersonation, ImpersonationProvider };
