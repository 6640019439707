import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  Currency,
  Hours,
  Icon,
  InternalClientTooltip,
  Level,
  Percent,
  ProjectLink,
  RouteLink,
  Stack,
  Table,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import projectBillingTypes from '~/lookups/project-billing-types.js';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientTagsGroup from '../components/ClientTagsGroup';
import CurrencyDropdown from '../components/CurrencyDropdown';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import List from '../components/List.jsx';
import ListTooltip from '../components/ListTooltip.jsx';
import MultiCurrency from '../components/MultiCurrency.jsx';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavTag from '../components/PeriodNavTag';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectTagsGroup from '../components/ProjectTagsGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import TableBody from '../components/TableBody';
import Tags from '../components/Tags';
import TotalResults from '../components/TotalResults';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

export default function PerformanceByProject() {
  useDocumentTitle('Performance by Project');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectBillingTypes: {
      ...searchParamsConfig.projectBillingTypes,
      default: [projectBillingTypes.fixed, projectBillingTypes.fixed_recurring, projectBillingTypes.tm],
    },
  }));

  const [params, setParams] = useState({
    period: null,
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().performanceByProject(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .performanceByProject(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Stack>
          <h1>Performance by Project</h1>

          <Tags>
            <PeriodNavTag value={params.period} onChange={(period) => handleApplyFilters({ period })} />

            <ClientTagsGroup filters={params} onChange={handleApplyFilters} />

            <ProjectTagsGroup filters={params} onChange={handleApplyFilters} />
          </Tags>
        </Stack>

        <Level>
          <Level.Item narrow right>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow right>
            <FilterButton isOutline onClick={showFilters} />
          </Level.Item>
        </Level>
      </Header>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort, onCurrencyChange }) {
  const report = query.data;
  const features = useFeatures();
  const { workspace } = useWorkspace();
  const auth = useAuth();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          billableType: ['billable', 'non_billable', 'internal'],
          currency: params.currency ?? undefined,
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters],
  );

  return (
    <Report.Table style={{ marginTop: '1rem' }}>
      <Level>
        {features.multicurrency && (
          <Level.Item>
            <CurrencyDropdown value={params.currency} onChange={onCurrencyChange} />
          </Level.Item>
        )}

        <Level.Item right>
          <TotalResults>
            {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
            <Table.Total label="Project" value={report.records.length} />
            {report.partialResults && <PartialResultsTooltip />}
          </TotalResults>
        </Level.Item>
      </Level>

      <Table>
        <Table.Header sticky>
          <Table.Column name="name" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="6rem" align="right" name="hours" onSort={onSort} sort={params.sort}>
            Hours
          </Table.Column>
          <Table.Column width="7.5rem" align="right" name="realizationRate" onSort={onSort} sort={params.sort}>
            Realization Rate
          </Table.Column>
          <Table.Column width="10rem" align="right" name="revenue" onSort={onSort} sort={params.sort}>
            Earned Revenue
          </Table.Column>
          <Table.Column width="7rem" align="right" name="effectiveRate" onSort={onSort} sort={params.sort}>
            Effective Bill Rate
          </Table.Column>
          <Table.Column
            width="10rem"
            align="right"
            name="cost"
            isVisible={auth.projects.viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Cost
          </Table.Column>
          <Table.Column
            width="10rem"
            align="right"
            name="profit"
            isVisible={auth.projects.viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Profit
          </Table.Column>
          <Table.Column
            width="6rem"
            align="right"
            name="margin"
            isVisible={auth.projects.viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Margin
          </Table.Column>
          <Table.Column width="10rem" align="right" name="budgetRevenue" onSort={onSort} sort={params.sort}>
            Budget Revenue
          </Table.Column>
          <Table.Column width="10rem" align="right" name="budgetRevenueLeft" onSort={onSort} sort={params.sort}>
            Budget Revenue Left
          </Table.Column>
        </Table.Header>

        <TableBody fade={query.loading.table}>
          {report.records.map((project) => {
            return (
              <Table.Row key={project.id}>
                <Table.Cell>
                  <ProjectInfo>
                    <p>
                      <ProjectLink project={project} />
                    </p>
                    <small>
                      <ClientLink client={project.client} />
                      {project.client.isInternal && <InternalClientTooltip />}
                    </small>
                  </ProjectInfo>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Billable">
                          <Hours value={project.billableHours} />
                        </List.Item>
                        <List.Item label="Non-Billable">
                          <Hours value={project.nonBillableHours} />
                        </List.Item>
                        <List.Item label="Internal">
                          <Hours value={project.internalHours} />
                        </List.Item>
                      </List>
                    }>
                    <RouteLink to={timeDetail({ project: project.id })}>
                      <Hours value={project.hours} />
                    </RouteLink>
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <Percent value={project.realizationRate} />
                </Table.Cell>
                <Table.Cell style={{ position: 'relative' }}>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedServicesRevenue, currency: report.currency },
                              { value: project.servicesRevenue, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Other Items Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedOtherItemsRevenue, currency: report.currency },
                              { value: project.otherItemsRevenue, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Expenses Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedExpensesRevenue, currency: report.currency },
                              { value: project.expensesRevenue, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <MultiCurrency
                      value={[
                        { value: project.convertedRevenue, currency: report.currency },
                        { value: project.revenue, currency: project.currency },
                      ]}
                    />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <MultiCurrency
                    value={[
                      { value: project.convertedEffectiveRate, currency: report.currency },
                      { value: project.effectiveRate, currency: project.currency },
                    ]}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Cost">
                          <MultiCurrency
                            value={[
                              { value: project.convertedServicesCost, currency: report.currency },
                              { value: project.servicesCost, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Expenses Cost">
                          <MultiCurrency
                            value={[
                              { value: project.convertedExpensesCost, currency: report.currency },
                              { value: project.expensesCost, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <MultiCurrency
                      value={[
                        { value: project.convertedCost, currency: report.currency },
                        { value: project.cost, currency: project.currency },
                      ]}
                    />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Profit">
                          <MultiCurrency
                            value={[
                              { value: project.convertedServicesProfit, currency: report.currency },
                              { value: project.servicesProfit, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <MultiCurrency
                      value={[
                        { value: project.convertedProfit, currency: report.currency },
                        { value: project.profit, currency: project.currency },
                      ]}
                    />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Margin">
                          <Percent value={project.servicesMargin} />
                        </List.Item>
                      </List>
                    }>
                    <Percent value={project.margin} />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <MultiCurrency
                    value={[
                      { value: project.convertedBudgetRevenue, currency: report.currency },
                      { value: project.budgetRevenue, currency: project.currency },
                    ]}
                  />
                </Table.Cell>
                <Table.Cell>
                  <MultiCurrency
                    value={[
                      { value: project.convertedBudgetRevenueLeft, currency: report.currency },
                      { value: project.budgetRevenueLeft, currency: project.currency },
                    ]}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Billable">
                      <Hours value={report.totals.billableHours} />
                    </List.Item>
                    <List.Item label="Non-Billable">
                      <Hours value={report.totals.nonBillableHours} />
                    </List.Item>
                    <List.Item label="Internal">
                      <Hours value={report.totals.internalHours} />
                    </List.Item>
                  </List>
                }>
                <RouteLink to={timeDetail()}>
                  <Hours value={report.totals.hours} />
                </RouteLink>
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <Percent value={report.totals.realizationRate} />
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Revenue">
                      <Currency value={report.totals.servicesRevenue} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Other Items Revenue">
                      <Currency value={report.totals.otherItemsRevenue} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Expenses Revenue">
                      <Currency value={report.totals.expensesRevenue} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.revenue} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.effectiveRate} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Cost">
                      <Currency value={report.totals.servicesCost} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Expenses Cost">
                      <Currency value={report.totals.expensesCost} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.cost} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Profit">
                      <Currency value={report.totals.servicesProfit} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.profit} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Margin">
                      <Percent value={report.totals.servicesMargin} />
                    </List.Item>
                  </List>
                }>
                <Percent value={report.totals.margin} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.budgetRevenue} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.budgetRevenueLeft} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </TableBody>
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.Filters isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          clearable={false}
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.Filters>
  );
}
