import { DateTime, Duration, Icon, MultilineText, Tooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Item = styled.div`
  position: relative;
  margin-left: 1.25rem;
  padding-left: 2.75rem;
  padding-bottom: 2rem;
  border-left: solid 2px ${colors.grey10};

  &:last-child {
    padding-bottom: 0;
    border-color: transparent;
  }
`;

const HistoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: -1.3125rem;
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  background-color: ${colors.grey5};
  border-radius: 999rem;
`;

const SubjectHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPencil = styled(Icon)`
  margin-left: auto;
  font-size: 0.85rem;
  cursor: pointer;
  color: ${colors.primary};

  &:hover {
    color: ${colors.accent};
  }
`;

const ActivityDetails = styled.small`
  color: ${colors.grey55};
  font-size: 0.75rem;
`;

const ContentBox = styled.div`
  margin-top: 0.75rem;
  padding: 0.75rem;
  min-height: 2rem;
  max-width: 100%;
  background-color: ${colors.grey5};
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 0.75rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;

  div {
    margin-right: 2rem;
  }
`;

const ParticipantsContainer = styled.div`
  font-size: 0.75rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
`;

const OpenFile = styled.div`
  span {
    color: ${colors.primary};
    cursor: pointer;
    &:hover {
      color: ${colors.accent};
    }
  }
`;

const RemovedFile = styled.div`
  span {
    color: ${colors.grey40};
  }
`;

function ActivityIcon({ tip = '', color = colors.grey55, size = '1rem', ...props }) {
  return (
    <HistoryIcon color={color} size={size}>
      <Tooltip message={tip}>
        <Icon {...props} />
      </Tooltip>
    </HistoryIcon>
  );
}

function FileComponent({ item, entity, content }) {
  const files = entity === 'company' ? item.companies[0].company.files : item.opportunities[0].opportunity.files;
  const fileExists = entity === 'company' ? item.companyFileId : item.opportunityFileId;

  return (
    <div>
      <ActivityIcon tip="File" icon="paperclip-vertical" type="far" />
      {files.length ? (
        <OpenFile>
          Attached{' '}
          {fileExists ? (
            <a href={files[0].url} target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          ) : (
            <div style={{ color: colors.grey40, display: 'inline' }}>{content}</div>
          )}{' '}
          to this {entity}
        </OpenFile>
      ) : (
        <RemovedFile>
          Attached <span>{content}</span> to this {entity}
        </RemovedFile>
      )}
      <ActivityDetails>
        <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
      </ActivityDetails>
    </div>
  );
}

export function ActivityItem({ item, handleOpenActivityForm, participants, entity }) {
  const allowEditActivities = entity !== 'contact' && !item.salesforceId;

  return (
    <Item>
      {{
        amount_change: () => (
          <div>
            <ActivityIcon icon="dollar-sign" tip="Amount Change" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        call: () => (
          <div>
            <ActivityIcon tip="Call" icon="phone" />
            <SubjectHeader>
              <strong>{item.pipelineActivityType.name}</strong>
              <div style={{ marginLeft: '0.5rem' }}>{item.subject}</div>
              {allowEditActivities && (
                <StyledPencil icon="pencil-alt" onClick={() => handleOpenActivityForm({ activity: item })} />
              )}
            </SubjectHeader>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
            <ContentBox>
              {(item.duration || item.direction) && (
                <ContentHeader>
                  {item.duration && (
                    <div>
                      <strong>Duration: </strong>
                      <Duration minutes={item.duration} useDecimal={false} /> hs
                    </div>
                  )}
                  {item.direction && (
                    <div>
                      <strong>Direction: </strong>
                      {
                        {
                          inbound: 'Inbound',
                          outbound: 'Outbound',
                        }[item.direction]
                      }
                    </div>
                  )}
                </ContentHeader>
              )}
              {participants?.length > 0 && (
                <ParticipantsContainer>
                  <>
                    <strong>Participants:</strong> {participants.join(', ')}
                  </>
                </ParticipantsContainer>
              )}
              <MultilineText value={item.content} chars={280} buttonProps={{ isAnchor: true }} fontSize={'0.75rem'} />
            </ContentBox>
          </div>
        ),
        edit: () => (
          <div>
            <ActivityIcon icon="pencil" tip="Edit" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        email: () => (
          <div>
            <ActivityIcon tip="Email" icon="envelope" />
            <SubjectHeader>
              <strong>{item.pipelineActivityType.name}</strong>
              <div style={{ marginLeft: '0.5rem' }}>{item.subject}</div>
              {allowEditActivities && (
                <StyledPencil icon="pencil-alt" onClick={() => handleOpenActivityForm({ activity: item })} />
              )}
            </SubjectHeader>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
            <ContentBox>
              {item.direction && (
                <ContentHeader>
                  <div>
                    <strong>Direction: </strong>
                    {
                      {
                        inbound: 'Inbound',
                        outbound: 'Outbound',
                      }[item.direction]
                    }
                  </div>
                </ContentHeader>
              )}
              {participants?.length > 0 && (
                <ParticipantsContainer>
                  <>
                    <strong>To:</strong> {participants.join(', ')}
                  </>
                </ParticipantsContainer>
              )}
              <MultilineText value={item.content} chars={280} buttonProps={{ isAnchor: true }} fontSize={'0.75rem'} />
            </ContentBox>
          </div>
        ),
        expected_close_date_change: () => (
          <div>
            <ActivityIcon icon="calendar-alt" type="far" tip="Expected Close Date Change" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        file: () => <FileComponent entity={entity} item={item} content={item.content} />,
        meeting: () => (
          <div>
            <ActivityIcon tip="Meeting" icon="handshake" />
            <SubjectHeader>
              <strong>{item.pipelineActivityType.name}</strong>
              <div style={{ marginLeft: '0.5rem' }}>{item.subject}</div>
              {allowEditActivities && (
                <StyledPencil icon="pencil-alt" onClick={() => handleOpenActivityForm({ activity: item })} />
              )}
            </SubjectHeader>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
            <ContentBox>
              {item.duration && (
                <ContentHeader>
                  <div>
                    <strong>Duration: </strong>
                    <Duration minutes={item.duration} useDecimal={false} /> hs
                  </div>
                </ContentHeader>
              )}
              {participants?.length > 0 && (
                <ParticipantsContainer>
                  <>
                    <strong>Participants:</strong> {participants.join(', ')}
                  </>
                </ParticipantsContainer>
              )}
              <MultilineText value={item.content} chars={280} buttonProps={{ isAnchor: true }} fontSize={'0.75rem'} />
            </ContentBox>
          </div>
        ),
        next_steps_change: () => (
          <div>
            <ActivityIcon icon="shoe-prints" tip="Next Steps Change" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        note: () => (
          <div>
            <ActivityIcon tip="Note" icon="note" />
            <SubjectHeader>
              <strong>{item.pipelineActivityType.name}</strong>
              <div style={{ marginLeft: '0.5rem' }}>{item.subject}</div>
              {allowEditActivities && (
                <StyledPencil icon="pencil-alt" onClick={() => handleOpenActivityForm({ activity: item })} />
              )}
            </SubjectHeader>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
            <ContentBox>
              <MultilineText value={item.content} chars={280} buttonProps={{ isAnchor: true }} fontSize={'0.75rem'} />
            </ContentBox>
          </div>
        ),
        other: () => (
          <div>
            <ActivityIcon tip="Other" icon="file" />
            <SubjectHeader>
              <strong>{item.pipelineActivityType.name}</strong>
              <div style={{ marginLeft: '0.5rem' }}>{item.subject}</div>
              {allowEditActivities && (
                <StyledPencil icon="pencil-alt" onClick={() => handleOpenActivityForm({ activity: item })} />
              )}
            </SubjectHeader>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
            <ContentBox>
              {item.duration && (
                <ContentHeader>
                  <div>
                    <strong>Duration: </strong>
                    <Duration minutes={item.duration} useDecimal={false} /> hs
                  </div>
                </ContentHeader>
              )}
              <MultilineText value={item.content} chars={280} buttonProps={{ isAnchor: true }} fontSize={'0.75rem'} />
            </ContentBox>
          </div>
        ),
        owner_change: () => (
          <div>
            <ActivityIcon icon="user" tip="Owner Change" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        probability_change: () => (
          <div>
            <ActivityIcon icon="percent" tip="Probability Change" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        project_assignment: () => (
          <div>
            <ActivityIcon icon="pencil" tip="Project Assignment" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        revenue_change: () => (
          <div>
            <ActivityIcon icon="dollar-sign" tip="Revenue Change" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
        stage_change: () => (
          <div>
            <ActivityIcon icon="bars-progress" tip="Stage Change" type="far" />
            <div>{item.subject}</div>
            <ActivityDetails>
              <DateTime value={item.date} format="l" /> by {item.createdByMember ? item.createdByMember.name : 'Ruddr'}
            </ActivityDetails>
          </div>
        ),
      }[item.pipelineActivityType.typeId]()}
    </Item>
  );
}
