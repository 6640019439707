import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '~/styles';

const StyledTooltip = styled.span`
  position: absolute;
  display: block;
  /* Make the component slightly taller / wider than the container to capture events
  which are not triggered if the element is disabled (onMouseEnter / onMouseLeave) */
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  left: -2px;
  top: -2px;
`;

const Message = styled.span`
  max-width: 16rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background-color: ${colors.white};
  border: solid 1px ${colors.grey10};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  z-index: 300;
`;

/**
 * This inline tooltip is intended to be used inside a button or other inline element.
 * The standard Tooltip component requires wrapping an element / component which is disruptive
 * under certain circumstances.
 */
function InlineTooltip({ message, placement = 'auto', ...props }) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    placement,
    strategy: 'fixed',
  });

  return (
    <>
      <StyledTooltip
        ref={setReferenceElement}
        {...props}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      />
      {visible &&
        message &&
        ReactDOM.createPortal(
          <Message ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {message}
          </Message>,
          document.body,
        )}
    </>
  );
}

export default InlineTooltip;
