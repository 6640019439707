import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSession, useWorkspace, useApi } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import { ErrorPage, PageLoader } from '../pages';

const initialState = {
  isReady: false,
  error: null,
  isConnected: null,
  token: null,
};

const handlers = {
  ready: ({ isConnected, token, workspaceId }) => ({ isReady: true, isConnected, token, workspaceId }),
  error: (error) => ({ isReady: true, error }),
};

export default function () {
  useDocumentTitle('Slack Authorization');

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const workspaceId = params.get('state');

  const api = useApi();
  const { isReady: isSessionReady, session } = useSession();
  const { selectWorkspace } = useWorkspace();
  const [{ isReady, error, isConnected, token, workspaceId: connectedWorkspaceId }, actions] = useActions(
    handlers,
    initialState,
  );

  const history = useHistory();

  const authorizeSlack = useCallback(async () => {
    try {
      const payload = workspaceId ? { code, workspaceId } : { code };
      const { data } = await api.slack.authorize(payload);
      actions.ready(data);
    } catch (error) {
      actions.error(error);
    }
  }, [actions, code, workspaceId, api]);

  // Handle actions need to make an authorize call
  useEffect(() => {
    if (!isSessionReady) {
      return;
    }

    // If the user clicks "cancel" on the auth request,
    // redirect them back to the settings page
    if (!code) {
      if (session && workspaceId) {
        selectWorkspace(workspaceId, true, 'settings/integrations');
      } else {
        history.push('/');
      }
      return;
    }

    authorizeSlack();
  }, [isSessionReady, session, code, workspaceId, selectWorkspace, authorizeSlack, history]);

  // Handle actions needed to process the authorize call
  useEffect(() => {
    if (!isReady || error) {
      return;
    }

    if (session && isConnected) {
      if (workspaceId && workspaceId !== connectedWorkspaceId) {
        selectWorkspace(workspaceId, true, 'settings/integrations?error=slack-in-use');
      } else {
        selectWorkspace(workspaceId, true, 'settings/integrations/slack');
      }
    } else if (workspaceId) {
      history.push(`/integrations/slack/registration/${token}?workspace=${workspaceId}`);
    } else {
      history.push(`/integrations/slack/registration/${token}`);
    }
  }, [isReady, error, isConnected, token, workspaceId, selectWorkspace, history, connectedWorkspaceId, session]);

  if (error) return <ErrorPage />;
  return <PageLoader />;
}
