import { Form, ActionButton, FormMessage } from '~/components';
import React, { useState } from 'react';
import checkCSV from './helpers/check-csv';
import validationRules from './helpers/validation-rules';
import HeaderCheckIndicator from './components/HeaderCheckIndicator';
import RowsCheckIndicator from './components/RowsCheckIndicator';
import styled from 'styled-components';
import { ImportResults } from './components/ImportResults';
import { colors, weights } from '~/styles';
import ReferenceSection from './components/ReferenceSection';

const SaveButton = styled(ActionButton)`
  width: 8.25rem;
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
  padding: 1rem;
`;

export default function AllocationDataImportSection(props) {
  const { isSubmitting, saved, formik, result, onFileChanged, status, message } = props;
  const [validationPassed, setValidationPassed] = useState(false);
  const [progressHeaderCheck, setProgressHeaderCheck] = useState(null);
  const [progressRowsCheck, setProgressRowsCheck] = useState(null);
  const handleFileChange = (event) => {
    setValidationPassed(false);

    const file = event.target.files[0];
    if (file) {
      onFileChanged(event);
      setProgressHeaderCheck(null);
      setProgressRowsCheck(null);
      checkCSV({
        file,
        rules: validationRules().allocations,
        onProgress: (progress) => {
          if (progress.stepsPassed === 1) setProgressHeaderCheck(progress);
          if (progress.stepsPassed === 2) {
            setProgressRowsCheck(progress);
            setValidationPassed(true);
          }
        },
      });
    }
  };
  return (
    <>
      <Form.Section
        title="Step 2: Provide a CSV file for allocations"
        subtitle="A comma-separated values (CSV) file is a delimited text file that uses a comma to separate values, and is commonly edited in Microsoft Excel. Each line of the file is a data record. Each record consists of one or more fields, separated by commas. Utilize the sample file to insure your data is accurately read.">
        <>
          <DropzoneContainer>
            <DropzoneInterior>
              <input name="csvFile" type="file" accept=".csv, text/csv" onChange={handleFileChange}></input>
            </DropzoneInterior>
          </DropzoneContainer>

          <ReferenceSection validationRules={validationRules().allocations} sampleFileKey="allocations" />

          {progressHeaderCheck && <HeaderCheckIndicator progress={progressHeaderCheck} />}
          {progressRowsCheck && <RowsCheckIndicator progress={progressRowsCheck} />}

          {validationPassed && (
            <Form.Control>
              <SaveButton isLoading={isSubmitting} ok={saved} onClick={formik.submitForm}>
                Submit for Import
              </SaveButton>
            </Form.Control>
          )}
        </>
        {status && <FormMessage.Error>{message} The file you provided could not be imported.</FormMessage.Error>}
        {result && <ImportResults result={result}></ImportResults>}
      </Form.Section>
    </>
  );
}
