import { Checkbox, ClientLink, Currency, Icon, Level, ProjectLink, RouteLink, Tooltip } from '~/components';
import { useWorkspace } from '~/contexts';
import { useHoursFormat } from '~/hooks';
import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import { QueryString } from '~/utils';
import getWarningMessage from './getWarningMessage';

const Results = styled.div`
  margin-top: 2rem;
`;

const ClientGroup = styled.div`
  &:not(:first-child) {
    margin-top: 1.25rem;
  }
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const ClientName = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${weights.black};
  font-size: 0.875rem;
`;

const GroupHeader = styled(Level.Item)`
  flex: 0 0 13rem;

  &&:last-child {
    padding-right: 1.25rem;
  }
`;

const Legend = styled.div`
  font-size: 0.75rem;
  text-align: center;

  &:not(:first-child) {
    border-left: 1px solid transparent;
  }
`;

const Selector = styled.div`
  width: 1.5rem;
  text-align: center;

  div {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  border: 1px solid ${colors.grey10};
  border-top: none;

  &:nth-child(2) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    border-top: 1px solid ${colors.grey10};
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    border-bottom: 1px solid ${colors.grey10};
  }
`;

const Cell = styled.div`
  flex: ${({ $flex }) => $flex || '1'};
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;

  &:not(:last-child) {
    border-right: 1px solid ${colors.grey10};
  }

  &:first-child {
    border-right: none;
  }

  ${({ $width }) =>
    $width &&
    css`
      flex: 0 ${$width};
    `}
`;

const NoResultsCell = styled(Cell)`
  flex: 1;
  color: ${colors.grey40};
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const AmountCell = styled(Cell)`
  position: relative;
  flex: 0 0 13rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 0.875rem;
`;

const Project = styled.div`
  font-size: 0.875rem;
  color: ${colors.black};
`;

const Warning = styled(Tooltip)`
  position: absolute;
  right: 0.75rem;
`;

const BillingType = styled.small`
  font-size: 0.75rem;
  color: ${colors.grey55};
`;

const WarningList = styled.ul`
  margin-top: 0.5rem;
  list-style: disc inside;
`;

function ReadyToBillResults({
  data,
  selection,
  onSelectionChange,
  status,
  includePriorUnbilledItems,
  approvedItemsOnly,
  projectTaskStatuses,
  start,
  end,
}) {
  const { workspace } = useWorkspace();

  const { clients, projectsById } = useMemo(() => {
    const clients = Object.values(
      data.projects.reduce((a, p) => {
        a[p.client.id] = a[p.client.id] || { ...p.client, projects: [] };
        a[p.client.id].projects.push(p);
        return a;
      }, {}),
    );

    const projectsById = _.keyBy(data.projects, 'id');

    return { clients, projectsById };
  }, [data.projects]);

  const expenseDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/expenses/expense-items?${new QueryString({
        start: includePriorUnbilledItems ? 'not_set' : start ?? 'not_set',
        end: end ?? 'not_set',
        ...query,
      }).toString()}`,
    [includePriorUnbilledItems, end, start, workspace.key],
  );

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: includePriorUnbilledItems ? 'not_set' : start ?? 'not_set',
          end: end ?? 'not_set',
          invoiced: 'no',
          billableType: 'non_billable',
          status: approvedItemsOnly ? ['approved'] : undefined,
          projectTaskStatus: projectTaskStatuses?.map((v) => v.id),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [includePriorUnbilledItems, projectTaskStatuses, approvedItemsOnly, end, start, workspace.key],
  );

  const uninvoicedRevenue = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/financial/uninvoiced-revenue?${new QueryString(
        {
          start: includePriorUnbilledItems ? 'not_set' : start ?? 'not_set',
          end: end ?? 'not_set',
          projectRecordStatusId: 'all',
          projectTaskStatus: projectTaskStatuses?.map((v) => v.id),
          timeStatus: approvedItemsOnly ? ['approved'] : undefined,
          expenseStatus: approvedItemsOnly ? ['approved'] : undefined,
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [includePriorUnbilledItems, projectTaskStatuses, end, start, approvedItemsOnly, workspace.key],
  );

  const hoursFormat = useHoursFormat({ minimumFractionDigits: 0 });

  return (
    <Results>
      {clients.length > 0 ? (
        clients.map((client) => {
          const clientSelected = client.projects.some((p) => selection.includes(p.id));

          // Enable the client checkbox if it's selected,
          // or if there's no selection and every project uses the same currency
          const clientEnabled =
            clientSelected ||
            (selection.length === 0 &&
              client.projects.every((project, _index, [{ currency }]) => project.currency === currency));

          const handleGroupSelectionChange = () => {
            onSelectionChange(
              clientSelected
                ? selection.filter((id) => !client.projects.map(({ id }) => id).includes(id))
                : [...selection, ...client.projects.map(({ id }) => id)],
            );
          };

          return (
            <ClientGroup key={client.id} fade={status === 'filtering'}>
              <Level margin="0 0 .5rem" gap="1.7rem">
                <Level.Item>
                  <ClientName>
                    <Checkbox
                      checked={clientSelected}
                      partial={selection.length < client.projects.length}
                      disabled={!clientEnabled}
                      onChange={handleGroupSelectionChange}
                    />

                    <ClientLink client={client} />
                  </ClientName>
                </Level.Item>

                <GroupHeader>
                  <Legend>Services</Legend>
                </GroupHeader>

                <GroupHeader>
                  <Legend>Expenses</Legend>
                </GroupHeader>

                <GroupHeader>
                  <Legend>Other Items</Legend>
                </GroupHeader>

                <GroupHeader>
                  <Legend>Total</Legend>
                </GroupHeader>
              </Level>

              {client.projects.map((project) => {
                const handleSelectionChange = () => {
                  onSelectionChange(
                    selection.includes(project.id)
                      ? selection.filter((id) => id !== project.id)
                      : [...selection, project.id],
                  );
                };

                const { nonBillableHoursCount, nonBillableHours, nonBillableExpensesCount, nonBillableExpenses } =
                  project.totals;

                // Enable the project checkbox if there's no selection,
                // if the selection includeds the project,
                // or if the selection includes a project from the same client and currency
                const projectEnabled =
                  selection.length === 0 ||
                  selection.includes(project.id) ||
                  (projectsById[selection[0]].client.id === project.client.id &&
                    projectsById[selection[0]].currency === project.currency);

                const currency = project.currency;

                return (
                  <Row key={project.id}>
                    <Cell $width="2rem">
                      <Selector>
                        <Checkbox
                          checked={selection.includes(project.id)}
                          disabled={!projectEnabled}
                          onChange={handleSelectionChange}
                          data-testid="select_project"
                        />
                      </Selector>
                    </Cell>
                    <Cell>
                      <Project>
                        <ProjectLink project={project} />
                        <BillingType>
                          {' / '}
                          {project.billingType.name}
                        </BillingType>
                      </Project>
                    </Cell>

                    <AmountCell>
                      <RouteLink
                        to={uninvoicedRevenue({
                          itemType: {
                            tm: 'time_entry',
                            fixed: 'fixed_fee_milestone',
                            fixed_recurring: 'fixed_fee_milestone',
                          }[project.billingTypeId],
                          project: project.id,
                          currency,
                        })}>
                        <Currency value={project.totals.services} currency={currency} />
                      </RouteLink>
                      {project.totals.nonBillableHours && (
                        <Warning
                          maxWidth="30rem"
                          message={`There ${pluralize(
                            'is',
                            nonBillableHoursCount,
                          )} ${nonBillableHoursCount} non-billable time ${pluralize('entry', nonBillableHoursCount)} ${
                            nonBillableHoursCount > 1 ? 'totaling' : 'for'
                          } ${hoursFormat.format(nonBillableHours)} ${pluralize(
                            'hour',
                            nonBillableHours,
                          )} in the date range.`}>
                          <RouteLink to={timeDetail({ project: project.id, start })}>
                            <Icon icon="fa-circle-info" color={colors.grey25} />
                          </RouteLink>
                        </Warning>
                      )}
                    </AmountCell>

                    <AmountCell>
                      <RouteLink
                        to={uninvoicedRevenue({
                          itemType: ['expense_item', 'project_expense_item'],
                          project: project.id,
                          currency,
                        })}>
                        <Currency value={project.totals.expenses} currency={currency} />
                      </RouteLink>
                      {project.totals.nonBillableExpenses && (
                        <Warning
                          maxWidth="30rem"
                          message={
                            <>
                              There {pluralize('is', nonBillableExpensesCount)} {nonBillableExpensesCount} non-billable{' '}
                              {pluralize('expense', nonBillableExpensesCount)}{' '}
                              {nonBillableExpensesCount > 1 ? 'totaling' : 'for'}{' '}
                              <Currency value={nonBillableExpenses} currency={currency} /> in the date range.
                            </>
                          }>
                          <RouteLink to={expenseDetail({ billableType: 'non_billable', project: project.id, start })}>
                            <Icon icon="fa-circle-info" color={colors.grey25} />
                          </RouteLink>
                        </Warning>
                      )}
                    </AmountCell>

                    <AmountCell>
                      <RouteLink
                        to={uninvoicedRevenue({
                          itemType: 'other_item',
                          project: project.id,
                          currency,
                        })}>
                        <Currency value={project.totals.otherItems} currency={currency} />
                      </RouteLink>
                    </AmountCell>

                    <AmountCell>
                      <RouteLink to={uninvoicedRevenue({ project: project.id, currency })}>
                        <Currency value={project.total} currency={currency} />
                      </RouteLink>

                      {project.warnings.length > 0 && (
                        <Warning
                          maxWidth="30rem"
                          message={
                            <>
                              <p>Please review the following items:</p>
                              <WarningList>
                                {project.warnings.map((warning) => (
                                  <li key={warning}>{getWarningMessage(warning)}</li>
                                ))}
                              </WarningList>
                            </>
                          }>
                          <Icon icon="exclamation-triangle" color={colors.warning} />
                        </Warning>
                      )}
                    </AmountCell>
                  </Row>
                );
              })}
            </ClientGroup>
          );
        })
      ) : (
        <NoResultsCell fade={status === 'filtering'}>
          There are no projects ready to bill for the selected period.
        </NoResultsCell>
      )}
    </Results>
  );
}

export default ReadyToBillResults;
