import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Avatar,
  CompanyLink,
  Confirmation,
  Dropdown,
  ExportDialog,
  Icon,
  InfiniteScrollingObserver,
  Level,
  MemberContactPopover,
  MemberSelect,
  Page,
  SearchInput,
  Spinner,
  Tag,
  Tooltip,
} from '~/components';
import { Table, TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ActiveStatusSelect } from '~/routes/app/settings/custom-data/components';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import CompanyCloneForm from './CompanyCloneForm';
import CompanyDeleteConfirmation from './CompanyDeleteConfirmation';
import CompanyForm from './CompanyForm';
import ExportDropdown from '../../settings/ExportDropdown.jsx';
import SalesforceIndicator from '../components/SalesforceIndicator';

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CompanyInfo = styled.div`
  line-height: 1;
  align-items: center;
  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const CompanyName = styled.div`
  display: flex;
  align-items: center;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 0.5rem;
  }
`;

const AddLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

const AssignmentTitle = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function TagComponent({ entity, type, style, children }) {
  const backgroundColor = type === 'contact' ? colors.grey5 : colors.primary10;
  const name = type === 'contact' ? entity.contact.name : entity.name;

  return <Tag style={{ ...style, backgroundColor }}>{children || <p>{name}</p>}</Tag>;
}

function FirstAssignment({ entities, type }) {
  const firstDependency = entities[0];
  return firstDependency ? <TagComponent entity={firstDependency} type={type} style={{ color: colors.black }} /> : null;
}

function Assignments({ entities, type }) {
  const count = entities.length - 1;
  if (count <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <AssignmentTitle>{type === 'contact' ? 'Primary Contacts' : 'Clients'}</AssignmentTitle>
          {entities.map((entity) => (
            <TagComponent entity={entity} type={type} key={type === 'contact' ? entity.contactId : entity.id}>
              <small>{type === 'contact' ? entity.contact.name : entity.name}</small>
            </TagComponent>
          ))}
        </div>
      }>
      <TagComponent entity={entities[0]} type={type} style={{ color: colors.grey40 }}>
        <p style={{ margin: '0.1rem' }}>+{count}</p>
      </TagComponent>
    </Tooltip>
  );
}

export default function CompaniesListPage() {
  const documentTitle = useDocumentTitle('Companies');

  const { workspace } = useWorkspace();
  const api = useApi();
  const history = useHistory();
  const { companyId } = useParams();
  const { url } = useRouteMatch();
  const toast = useToast();

  const [cloneTarget, setCloneTarget] = useState(null);

  const [query, setQuery] = useState({
    data: null,
    params: {
      q: '',
      isActive: 'true',
      owner: null,
      page: 0,
      size: 25,
      sort: new QuerySort('name', 'asc'),
    },
    action: 'load',
    status: 'loading',
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .companies()
        .get({
          ..._.omit(params, ['owner']),
          ownerId: params.owner?.id,
        });

      setQuery((state) => ({
        ...state,
        action: null,
        status: 'ready',
        error: null,
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
        },
      }));
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [workspace.id, api, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    setQuery((state) => ({ ...state, status: 'load' }));
    setParams({ ...params, page: 0 });
  }

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, sort: new QuerySort(column, direction) });
  };

  const handleDelete = async (company) => {
    confirmation.prompt((resolve) => (
      <CompanyDeleteConfirmation
        company={company}
        onClose={resolve}
        onDelete={() => {
          history.push(`/app/${workspace.key}/pipeline/companies`);
          setQuery((state) => ({ ...state, status: 'load' }));
          setParams({ ...params, page: 0 });
          resolve(true);
        }}
      />
    ));
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/edit/${id}`);
  };

  const handleOverview = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/${id}/overview`);
  };

  async function handleCloned(company) {
    try {
      toast.success('Company cloned successfully.');
      history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/overview/edit`);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleClose() {
    history.push(`/app/${workspace.key}/pipeline/companies`);
    documentTitle.set('Companies');
  }

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .companies()
          .export(
            {
              ..._.omit(params, ['owner']),
              ownerId: params.owner?.id,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Section>
          <Level padding="0.5rem 0 0 0">
            <Level.Item width="20rem">
              <SearchInput
                value={params.q}
                placeholder="Search"
                materialPlaceholder="Company Name"
                materialAlwaysVisible
                onChange={handleChange}
              />
            </Level.Item>

            <Level.Item width="20rem">
              <MemberSelect
                value={params.owner}
                name="owner"
                placeholder="All"
                materialPlaceholder="Company Relationship Owner"
                materialAlwaysVisible
                onChange={handleChange}
              />
            </Level.Item>

            <Level.Item width="20rem">
              <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
            </Level.Item>

            <Level right>
              <Level.Item>
                <ExportDropdown>
                  {({ setIsOpen }) => (
                    <>
                      <ExportDropdown.Item
                        onClick={async () => {
                          await handleExport(`companies.csv`, mimeTypes.csv);
                          setIsOpen(false);
                        }}>
                        Export to CSV
                      </ExportDropdown.Item>

                      <ExportDropdown.Item
                        onClick={async () => {
                          await handleExport(`companies.xlsx`, mimeTypes.xlsx);
                          setIsOpen(false);
                        }}>
                        Export to Excel
                      </ExportDropdown.Item>
                    </>
                  )}
                </ExportDropdown>
              </Level.Item>

              <Level.Item right narrow>
                <Tooltip message="Create Company">
                  <AddLink to={`${url}/new`} className="button">
                    <Icon icon="plus" />
                  </AddLink>
                </Tooltip>
              </Level.Item>
            </Level>
          </Level>
        </Page.Section>

        <Page.Section>
          <Table.Status>
            {query.status !== 'ready' && <Spinner />}
            <Table.Total value={query.data.total} label="Company" />
          </Table.Status>
          <Table>
            <Table.BoxHeader sticky>
              <Table.Column name="name" onSort={handleSort} sort={params.sort}>
                Company Name
              </Table.Column>
              <Table.Column>Client</Table.Column>
              <Table.Column>Relationship Owner</Table.Column>
              <Table.Column>Primary Contacts</Table.Column>
              <Table.Column width="4rem" align="left"></Table.Column>
              <Table.BoxActionsColumn />
            </Table.BoxHeader>

            <Table.Body fade={query.status === 'filtering'}>
              {query.data.results.map((company) => {
                const { id, owner, isActive, industry, companyContacts, client, permissions, salesforceId } = company;

                async function handleRowClick(company) {
                  handleOverview(company);
                }

                return (
                  <Table.BoxRow key={id} isDisabled={!isActive} onClick={() => handleRowClick(company)}>
                    <Table.Cell>
                      <CompanyInfo>
                        <CompanyName>
                          <CompanyLink company={company} onClick={(e) => e.stopPropagation()} />
                        </CompanyName>
                        {industry && <small>{industry.name}</small>}
                      </CompanyInfo>
                    </Table.Cell>

                    <Table.Cell>{client?.name}</Table.Cell>

                    <Table.Cell>
                      {owner && (
                        <Owner>
                          <MemberContactPopover member={owner} placement="left">
                            <Avatar value={owner} isCircle hasBackground initialsFontSize=".9rem" />
                          </MemberContactPopover>
                          <CompanyInfo>
                            <span>{owner.name}</span>
                            {owner.jobTitle && <small>{owner.jobTitle.name}</small>}
                          </CompanyInfo>
                        </Owner>
                      )}
                    </Table.Cell>

                    <Table.Cell>
                      <Tags>
                        <FirstAssignment entities={companyContacts} type="contact" />
                        <Assignments entities={companyContacts} type="contact" />
                      </Tags>
                    </Table.Cell>

                    <Table.Cell>{salesforceId && <SalesforceIndicator />}</Table.Cell>

                    <TableBoxRowActions>
                      <TableBoxRowActions.Edit onClick={() => handleEdit(company)} />

                      <hr />

                      <TableBoxRowActions.Dropdown>
                        {({ setIsOpen }) => {
                          const handleAction = (action) => setIsOpen(false) || action;

                          async function handleActiveStatusChange(company, flag) {
                            try {
                              setIsOpen(false);

                              const confirm = await confirmation.prompt((resolve) =>
                                flag ? (
                                  <Confirmation resolve={resolve}>
                                    This action will activate the company. Note that this will not activate any
                                    opportunities within the company. Opportunities must be activated separately.
                                  </Confirmation>
                                ) : (
                                  <Confirmation resolve={resolve}>
                                    Are you sure that you want to deactivate this company? This will also deactivate all
                                    opportunities within the company.
                                  </Confirmation>
                                ),
                              );

                              if (!confirm) return;

                              await api.www.workspaces(workspace.id).companies(company.id).setActiveStatus(flag);

                              await handleSaved();
                            } catch (error) {
                              toast.error(error.message || 'There was a problem updating the company.');
                            }
                          }

                          return (
                            <>
                              <Dropdown.Item onClick={() => handleAction(handleOverview(company))}>View</Dropdown.Item>

                              <Dropdown.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage ? 'Insufficient permissions to edit this company.' : undefined
                                }
                                onClick={() => handleAction(handleEdit(company))}>
                                Edit
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage ? 'Insufficient permissions to delete this company.' : undefined
                                }
                                onClick={() => handleAction(handleDelete(company))}>
                                Delete
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage ? 'Insufficient permissions to clone this company.' : undefined
                                }
                                onClick={() => handleAction(setCloneTarget(company))}>
                                Clone
                              </Dropdown.Item>

                              <Dropdown.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage
                                    ? `Insufficient permissions to ${
                                        company.isActive ? 'deactivate' : 'activate'
                                      } this company.`
                                    : undefined
                                }
                                onClick={() => handleAction(handleActiveStatusChange(company, !isActive))}>
                                {isActive ? 'Deactivate' : 'Activate'}
                              </Dropdown.Item>
                            </>
                          );
                        }}
                      </TableBoxRowActions.Dropdown>
                    </TableBoxRowActions>
                  </Table.BoxRow>
                );
              })}
            </Table.Body>
          </Table>

          {query.data.total > query.data.results.length && (
            <InfiniteScrollingObserver key={query.data.results.length} onIntersecting={loadMore} />
          )}
        </Page.Section>
      </Page>
      {companyId && <CompanyForm onSaved={handleSaved} onClose={() => handleClose()} />}
      {cloneTarget && (
        <CompanyCloneForm target={cloneTarget} onClose={() => setCloneTarget(null)} onSaved={handleCloned} />
      )}
    </>
  );
}
