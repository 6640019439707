import { Buttons, CancelButton, DateTime, Drawer, Form } from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage } from '~/routes/public/pages';
import ProjectDataList from './ProjectDataList';

export default function ProjectHealthReportView({ id, projectId, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null });
  const fetchData = useCallback(async () => {
    if (!id) {
      setQuery({ isReady: true, data: {} });
      return;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).projects(projectId).healthReports(id).get();
      setQuery({ isReady: true, data });
    } catch (error) {
      toast.error(error.message || 'There was a problem reading this project health report. Please try again.');
    }
  }, [workspace.id, id, projectId, api, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const title = 'View Project Health Report';
  useDocumentTitle(title);

  if (!query.isReady) return null;
  if (!query.data) return <ErrorPage.NotFound publicSite={false} />;

  const report = query.data;

  return (
    <Drawer isOpen title={title} onClose={onClose}>
      <ReadForm>
        <ReadForm.Section title="Project Health">
          <Form.Control>
            <ReadTextbox label="Date" value={<DateTime value={report.date} />} />
          </Form.Control>

          <Form.Control>
            <ReadTextbox label="Budget Health" value={report.budgetHealth.name} />
          </Form.Control>

          {report.budgetHealthNotes && (
            <Form.Control>
              <ReadTextbox label="Budget Health Commentary" value={report.budgetHealthNotes} textarea />
            </Form.Control>
          )}

          <Form.Control>
            <ReadTextbox label="Schedule Health" value={report.scheduleHealth.name} />
          </Form.Control>

          {report.scheduleHealthNotes && (
            <Form.Control>
              <ReadTextbox label="Schedule Health Commentary" value={report.scheduleHealthNotes} textarea />
            </Form.Control>
          )}

          <Form.Control>
            <ReadTextbox label="Client Satisfaction" value={report.clientSatisfaction.name} />
          </Form.Control>

          {report.clientSatisfactionNotes && (
            <Form.Control>
              <ReadTextbox label="Client Satisfaction Commentary" value={report.clientSatisfactionNotes} textarea />
            </Form.Control>
          )}

          <Form.Control>
            <ReadTextbox label="Team Satisfaction" value={report.teamSatisfaction.name} />
          </Form.Control>

          {report.teamSatisfactionNotes && (
            <Form.Control>
              <ReadTextbox label="Team Satisfaction Commentary" value={report.teamSatisfactionNotes} textarea />
            </Form.Control>
          )}
        </ReadForm.Section>

        <ReadForm.Section
          title="Project Metrics"
          help="The project's metrics at the time that this Project Health Report was created.">
          <ProjectDataList data={query.data} />
        </ReadForm.Section>

        <Drawer.Actions>
          <Buttons align="right">
            <CancelButton onClick={onClose}>Close</CancelButton>
          </Buttons>
        </Drawer.Actions>
      </ReadForm>
    </Drawer>
  );
}
