import { Button, Buttons, CancelButton, Confirmation, Drawer, Tab, Tabs } from '~/components';
import { useConfirmation } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';
import LineSettingsExpensesTab from './LineSettingsExpensesTab';
import LineSettingsTimeTab from './LineSettingsTimeTab';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const ApplyButton = styled(Button)`
  width: 10.5rem;
`;

function LineSettingsDrawer({ values, onApply, onClose }) {
  const [tabIndex, setTabIndex] = useState(0);
  const [{ isSubmitting }, form] = useForm();
  const formRef = useRef();
  const confirmation = useConfirmation();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  function handleClose() {
    onClose();
  }

  const initialValues = mergeValues(
    {
      // Time
      groupTime: false,
      groupTimeBy: 'role',
      timeFields: [],
      includeTimeDateRange: false,

      // Expenses
      groupExpenses: false,
      groupExpensesBy: 'role',
      expenseFields: [],
    },
    values,
  );

  // Each control restricts the options, so additional validation seems superfluous
  const schema = Yup.object().shape({});

  return (
    <Drawer
      isOpen
      title="Line Settings"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          const confirm = await confirmation.prompt((resolve) => (
            <Confirmation resolve={resolve}>
              <p style={{ marginBottom: '1rem' }}>Applying these changes will rebuild the invoice.</p>
              <p>Are you sure you want to continue?</p>
            </Confirmation>
          ));
          if (!confirm) return;

          form.submit();
          await onApply(values);
          closeDrawer();
        }

        return (
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            {(formik) => {
              return (
                <>
                  <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <Tab>Services</Tab>
                    <Tab>Expenses</Tab>
                  </Tabs>

                  <Content>
                    {
                      [
                        <LineSettingsTimeTab key="time" formik={formik} />,
                        <LineSettingsExpensesTab key="expenses" formik={formik} />,
                      ][tabIndex]
                    }
                  </Content>

                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <ApplyButton onClick={formik.submitForm} isLoading={isSubmitting}>
                        Apply
                      </ApplyButton>
                    </Buttons>
                  </Drawer.Actions>
                </>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default LineSettingsDrawer;
