import _ from 'lodash';
import { QueryString } from '~/utils';
import { get } from './agent';

const headers = (behavior) =>
  behavior
    ? {
        headers: {
          'x-cache-behavior': behavior,
        },
      }
    : undefined;

const executiveDashboard = (workspacesUrl) => () => {
  const url = _([workspacesUrl, 'executive-dashboard']).compact().join('/');

  return {
    servicesRevenue: (query = {}, behavior) =>
      get(url.concat('/services-revenue').concat(new QueryString(query).toString(true)), headers(behavior)),
    servicesGrossProfit: (query = {}, behavior) =>
      get(url.concat('/services-gross-profit').concat(new QueryString(query).toString(true)), headers(behavior)),
    servicesEffectiveBillRate: (query = {}, behavior) =>
      get(url.concat('/services-effective-bill-rate').concat(new QueryString(query).toString(true)), headers(behavior)),
    billableUtilization: (query = {}, behavior) =>
      get(url.concat('/billable-utilization').concat(new QueryString(query).toString(true)), headers(behavior)),
    servicesRealizationRate: (query = {}, behavior) =>
      get(url.concat('/services-realization-rate').concat(new QueryString(query).toString(true)), headers(behavior)),
    clientBillableHours: (query = {}, behavior) =>
      get(url.concat('/client-billable-hours').concat(new QueryString(query).toString(true)), headers(behavior)),
    clientNonBillableHours: (query = {}, behavior) =>
      get(url.concat('/client-non-billable-hours').concat(new QueryString(query).toString(true)), headers(behavior)),
    grossPipeline: (query = {}, behavior) =>
      get(url.concat('/gross-pipeline').concat(new QueryString(query).toString(true)), headers(behavior)),
    weightedPipeline: (query = {}, behavior) =>
      get(url.concat('/weighted-pipeline').concat(new QueryString(query).toString(true)), headers(behavior)),
    topClientsByServicesRevenue: (query = {}, behavior) =>
      get(
        url.concat('/top-clients-by-services-revenue').concat(new QueryString(query).toString(true)),
        headers(behavior),
      ),
    topClientsByBillableHours: (query = {}, behavior) =>
      get(
        url.concat('/top-clients-by-billable-hours').concat(new QueryString(query).toString(true)),
        headers(behavior),
      ),
    mostHealthyProjects: (query = {}, behavior) =>
      get(url.concat('/most-healthy-projects').concat(new QueryString(query).toString(true)), headers(behavior)),
    leastHealthyProjects: (query = {}, behavior) =>
      get(url.concat('/least-healthy-projects').concat(new QueryString(query).toString(true)), headers(behavior)),
  };
};

export default executiveDashboard;
