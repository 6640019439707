import React, { useMemo } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Duration, Tooltip } from '~/components';
import { useWorkspace } from '~/contexts';
import { useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import { QueryString } from '~/utils';
import { formatPercent } from './utils';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 6.125rem;
  margin: 0.25rem;
  padding: 1.25rem 1.5rem;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const Title = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.bold};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const Progress = styled.div`
  position: relative;
  width: 100%;
  margin: auto 0;
`;

const ProgressLines = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const ProgressLine = styled.div`
  display: block;
  width: ${({ width }) => width ?? '100%'};
  height: 0.375rem;
  background-color: ${({ color }) => color ?? colors.grey5};
  border-radius: 999rem;
  overflow: hidden;
  transition: width 0.5s ease-out;
`;

const ProgressTooltips = styled.div`
  position: relative;
  display: flex;
  z-index: 1;
`;

const ProgressTooltip = styled(Tooltip)`
  width: ${({ width }) => width ?? '100%'};
  height: 100%;
  min-width: min-content;
  margin-top: 0.5rem;
  padding: 0 0.125rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const ProgressLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 1.125rem;
  color: ${colors.black};
  font-size: 0.625rem;
  font-weight: ${weights.medium};

  &:hover {
    color: ${colors.grey55};
  }
`;

const NoHours = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto 0;
  color: ${colors.grey10};
`;

export default function ApprovalStatus({ entries, startDate, endDate, member: selectedMember }) {
  const features = useFeatures();
  const { workspace } = useWorkspace();

  const member = useMemo(() => {
    return selectedMember ?? workspace.member;
  }, [selectedMember, workspace]);

  const { approved, rejected, pending, notCreated } = useMemo(() => {
    const clientApprovalEntries = _.filter(
      entries,
      (entry) => entry.project?.enableClientApprovals === true && entry.isActuallyBillable === true,
    );

    return {
      approved: _(clientApprovalEntries)
        .filter((entry) => entry.clientStatusId === 'approved')
        .sumBy('minutes'),
      rejected: _(clientApprovalEntries)
        .filter((entry) => entry.clientStatusId === 'rejected')
        .sumBy('minutes'),
      pending: _(clientApprovalEntries)
        .filter((entry) => entry.clientApprovalId && !entry.clientStatusId)
        .sumBy('minutes'),
      notCreated: _(clientApprovalEntries)
        .filter((entry) => !entry.clientApprovalId && !entry.clientStatusId)
        .sumBy('minutes'),
    };
  }, [entries]);

  const total = notCreated + pending + rejected + approved;

  const rejectLinePercent = formatPercent((rejected + pending + approved) / total);
  const pendingLinePercent = formatPercent((pending + approved) / (total - notCreated));
  const approvedLinePercent = formatPercent(approved / (total - notCreated - rejected));

  const approvedAmountPercent = formatPercent(approved / total);
  const pendingAmountPercent = formatPercent(pending / total);
  const rejectedAmountPercent = formatPercent(rejected / total);
  const notCreatedAmountPercent = formatPercent(notCreated / total);

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time/time-entries${new QueryString(
      {
        start: startDate,
        end: endDate,
        member: member?.id,
        ...query,
      },
      { multi: true },
    ).toString(true)}`;

  if (!features.clientApprovals) {
    return null;
  }
  return (
    <Container>
      <Title>Client Approval Status</Title>
      {total > 0 ? (
        <Progress>
          <ProgressLines>
            <ProgressLine color={colors.black}>
              <ProgressLine width={rejectLinePercent} color={colors.danger}>
                <ProgressLine width={pendingLinePercent} color={colors.warning}>
                  <ProgressLine width={approvedLinePercent} color={colors.primary} />
                </ProgressLine>
              </ProgressLine>
            </ProgressLine>
          </ProgressLines>
          <ProgressTooltips>
            {approved > 0 && (
              <ProgressTooltip width={approvedAmountPercent} message="Approved" placement="top">
                <ProgressLink to={timeDetail({ clientStatus: 'approved' })}>
                  <Duration useDecimal minutes={approved} />
                </ProgressLink>
              </ProgressTooltip>
            )}
            {pending > 0 && (
              <ProgressTooltip width={pendingAmountPercent} message="Pending Approval" placement="top">
                <ProgressLink to={timeDetail({ clientStatus: 'pending_approval' })}>
                  <Duration useDecimal minutes={pending} />
                </ProgressLink>
              </ProgressTooltip>
            )}
            {rejected > 0 && (
              <ProgressTooltip width={rejectedAmountPercent} message="Rejected" placement="top">
                <ProgressLink to={timeDetail({ clientStatus: 'rejected' })}>
                  <Duration useDecimal minutes={rejected} />
                </ProgressLink>
              </ProgressTooltip>
            )}
            {notCreated > 0 && (
              <ProgressTooltip width={notCreatedAmountPercent} message="Not Created" placement="top">
                <ProgressLink to={timeDetail({ clientStatus: 'not_created' })}>
                  <Duration useDecimal minutes={notCreated} />
                </ProgressLink>
              </ProgressTooltip>
            )}
          </ProgressTooltips>
        </Progress>
      ) : (
        <NoHours>No Hours Available</NoHours>
      )}
    </Container>
  );
}
