import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useWorkspace } from '~/contexts';
import { useAuth, useFeatures } from '~/hooks';
import { ErrorPage } from '~/routes/public/pages';
import { colors } from '~/styles';
import BillingArea from '../billing/BillingArea';
import { ClientsArea } from '../clients';
import { ExpensesArea } from '../expenses';
import HomeArea from '../home/HomeArea';
import PipelineArea from '../pipeline/PipelineArea';
import { ProjectsArea } from '../projects';
import { ReportsArea } from '../reports';
import ResourcesArea from '../resources/ResourcesArea';
import { WorkspaceSettingsArea } from '../settings';
import QBOSettingsArea from '../settings/integrations/qbo/QBOSettingsArea';
import XeroSettingsArea from '../settings/integrations/xero/XeroSettingsArea';
import { MemberDetailsArea } from '../settings/members';
import { TimeArea } from '../time';
import WorkspaceBillingLocked from './WorkspaceBillingLocked';
import WorkspaceDisabled from './WorkspaceDisabled';

const Main = styled.main`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${({ hasMessage }) => (hasMessage ? '6.25rem' : '3.75rem')};
  margin-left: ${({ sidebarExpanded }) => (sidebarExpanded ? '11.5rem' : '4.75rem')};
  background-color: ${colors.white};
`;

function WorkspaceMain({ sidebarExpanded }) {
  const features = useFeatures();
  const auth = useAuth();
  const { workspace, hasMessage } = useWorkspace();
  const { path, url } = useRouteMatch();

  if (workspace.statusId === 'disabled') {
    return (
      <Main hasMessage={hasMessage} sidebarExpanded={sidebarExpanded}>
        <Switch>
          <Route path={path} exact>
            <WorkspaceDisabled />
          </Route>
          <Route>
            <Redirect to={url} />
          </Route>
        </Switch>
      </Main>
    );
  }

  if (!workspace.billingStatus?.isValid) {
    return (
      <Main hasMessage={hasMessage} sidebarExpanded={sidebarExpanded}>
        <Switch>
          <Route path={path} exact>
            <WorkspaceBillingLocked />
          </Route>

          <Route path={`${path}/settings`}>
            {auth.workspace.manage ? <WorkspaceSettingsArea /> : <ErrorPage.Forbidden publicSite={false} />}
          </Route>

          <Route>
            <Redirect to={url} />
          </Route>
        </Switch>
      </Main>
    );
  }

  return (
    <Main hasMessage={hasMessage} sidebarExpanded={sidebarExpanded}>
      <Switch>
        <Route
          path={[
            '/dashboard',
            '/dashboard/executive',
            '/dashboard/personal',
            '/my-projects',
            '/my-tasks',
            '/my-allocations',
            '/my-allocations/view/:allocationId',
            '/my-exports',
            '/my-profile',
            '/my-profile/change-password',
            '/my-profile/two-factor-auth',
            '/my-profile/two-factor-auth/setup',
          ].map((p) => `${path}${p}`)}
          exact>
          <HomeArea routeMatch={{ path, url }} />
        </Route>

        <Route path={`${path}/time`}>
          <TimeArea />
        </Route>

        <Route path={`${path}/clients`}>
          <ClientsArea />
        </Route>

        <Route path={`${path}/projects`}>
          <ProjectsArea />
        </Route>

        {features.expenseReporting && (
          <Route path={`${path}/expenses`}>
            <ExpensesArea />
          </Route>
        )}

        <Route path={`${path}/billing`}>
          <BillingArea />
        </Route>

        {/* Legacy /invoices routes */}
        <CustomRedirect path={`${path}/invoices`} to={`${url}/billing`} exact />
        <CustomRedirect path={`${path}/invoices/ready-to-bill`} to={`${url}/billing/ready-to-bill`} exact />
        <CustomRedirect path={`${path}/invoices/list`} to={`${url}/billing/invoices`} exact />
        <CustomRedirect path={`${path}/invoices/list/:invoiceId`} to={`${url}/billing/invoices/:invoiceId`} exact />
        <CustomRedirect path={`${path}/invoices/payments`} to={`${url}/billing/payments`} exact />
        <CustomRedirect
          path={`${path}/invoices/payments/:paymentId/view`}
          to={`${url}/billing/payments/:paymentId/view`}
          exact
        />
        <CustomRedirect
          path={`${path}/invoices/payments/:paymentId/edit`}
          to={`${url}/billing/payments/:paymentId/edit`}
          exact
        />

        {features.pipeline && (
          <Route path={`${path}/pipeline`}>
            {auth.pipeline.view ? <PipelineArea /> : <ErrorPage.Forbidden publicSite={false} />}
          </Route>
        )}

        {features.allocations && (
          <Route path={`${path}/resources`}>
            {auth.allocations.view ? <ResourcesArea /> : <ErrorPage.Forbidden publicSite={false} />}
          </Route>
        )}

        <Route path={`${path}/reports`}>
          <ReportsArea />
        </Route>

        <Route path={`${path}/settings/members/details/:memberId`}>
          {auth.workspace.manage ? <MemberDetailsArea /> : <ErrorPage.Forbidden publicSite={false} />}
        </Route>

        <Route path={`${path}/settings/integrations/qbo`}>
          {auth.workspace.manage ? <QBOSettingsArea /> : <ErrorPage.Forbidden publicSite={false} />}
        </Route>

        <Route path={`${path}/settings/integrations/xero`}>
          {auth.workspace.manage ? <XeroSettingsArea /> : <ErrorPage.Forbidden publicSite={false} />}
        </Route>

        <Route path={`${path}/settings`}>
          {auth.workspace.manage ? <WorkspaceSettingsArea /> : <ErrorPage.Forbidden publicSite={false} />}
        </Route>

        <Route>
          <Redirect to={`${url}/dashboard`} />
        </Route>
      </Switch>
    </Main>
  );
}

function CustomRedirect(props) {
  const location = useLocation();
  return <Redirect to={{ ...location, pathname: props.to }} {...props} />;
}

export default WorkspaceMain;
