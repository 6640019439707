import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { HelpTooltip, Level, Page } from '~/components';
import { useRating, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import { dateFormats } from '~/utils';
import DashboardDropdown from '../DashboardDropdown';
import BillableUtilization from './BillableUtilization';
import ClientBillableHours from './ClientBillableHours';
import ClientNonBillableHours from './ClientNonBillableHours';
import GrossPipeline from './GrossPipeline.jsx';
import LeastHealthyProjects from './LeastHealthyProjects';
import MostHealthyProjects from './MostHealthyProjects';
import ServicesEffectiveBillRate from './ServicesEffectiveBillRate';
import ServicesGrossMargin from './ServicesGrossMargin';
import ServicesGrossProfit from './ServicesGrossProfit';
import ServicesRealizationRate from './ServicesRealizationRate';
import ServicesRevenue from './ServicesRevenue';
import TopClientsByBillableHours from './TopClientsByBillableHours';
import TopClientsByServicesRevenue from './TopClientsByServicesRevenue';
import WeightedPipeline from './WeightedPipeline.jsx';
import WelcomeDialog from './WelcomeDialog';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
`;

const WorkspaceName = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const Section = styled.section`
  margin-top: 1.5rem;
`;

export default function ExecutiveDashboard() {
  useDocumentTitle('Executive Dashboard');

  const { workspace } = useWorkspace();
  const features = useFeatures();
  const { autoOpenRatingModal } = useRating();

  useEffect(() => {
    autoOpenRatingModal();
  }, [autoOpenRatingModal]);

  return (
    <>
      <Page>
        <Page.Header>
          <Level width="100%">
            <Level.Item>
              <WorkspaceName>{workspace.name}</WorkspaceName>
              <DashboardDropdown value="executive" />
            </Level.Item>
            <Level.Item right narrow>
              <HelpTooltip message="This executive dashboard is only available to Workspace Admins. The data in this dashboard is refreshed once per day." />
            </Level.Item>
          </Level>
        </Page.Header>

        <Section>
          <Grid>
            <ServicesRevenue
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            <ServicesGrossProfit
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            <ServicesGrossMargin
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            <BillableUtilization
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            <TopClientsByServicesRevenue
              start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
              end={moment().format(dateFormats.isoDate)}
            />

            <TopClientsByBillableHours
              start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
              end={moment().format(dateFormats.isoDate)}
            />

            <ServicesEffectiveBillRate
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            <ServicesRealizationRate
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            <ClientBillableHours
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            <ClientNonBillableHours
              actual={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().format(dateFormats.isoDate),
              }}
              forecast={{
                start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
                end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
              }}
            />

            {features.projectHealth && (
              <MostHealthyProjects
                start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
                end={moment().format(dateFormats.isoDate)}
              />
            )}

            {features.projectHealth && (
              <LeastHealthyProjects
                start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
                end={moment().format(dateFormats.isoDate)}
              />
            )}

            {features.pipeline && (
              <GrossPipeline
                start={moment().startOf('month').format(dateFormats.isoDate)}
                end={moment().add(11, 'months').endOf('month').format(dateFormats.isoDate)}
              />
            )}

            {features.pipeline && (
              <WeightedPipeline
                start={moment().startOf('month').format(dateFormats.isoDate)}
                end={moment().add(11, 'months').endOf('month').format(dateFormats.isoDate)}
              />
            )}
          </Grid>
        </Section>
      </Page>

      <WelcomeDialog />
    </>
  );
}
